import classes from "./Form.module.css";

// THIRD PARTY IMPORT

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Translations from "../Translations";
import { Input } from "../Common/Input/Input";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../Common/Validation/Validator";
import Button from "../Common/Button/Button";
import { Link } from "react-router-dom";

const LoginForm = ({
	state,
	InputHandler,
	formSubmitHandler,
	loading,
	hideImage,
	extraClassName,
	backgroundImage,
}) => {
	return (
		<div className={`${classes.authContainer} ${extraClassName && extraClassName}`}>
			{!hideImage && (
				<div
					className={classes.backgroundImage}
					style={{
						backgroundImage: `linear-gradient(190deg, rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.29) 67%,rgba(0, 0, 0, 0.5) 100%),
    url(${backgroundImage})`,
					}}
				/>
			)}
			<form
				className={`${classes.authFormContainer} ${extraClassName && extraClassName} `}
				onSubmit={formSubmitHandler}
			>
				<h2 className={`${classes.formHeader} font-700  `}>
					<Translations text="shared.login" />
				</h2>

				<Input
					id="EMAIL"
					label={<Translations text="form.password.email" />}
					placeholder={<Translations text="form.password.enterEmail" />}
					type="text"
					element="input"
					validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
					errorText={<Translations text="form.password.enterValidEmail" />}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faEnvelope} />}
				/>

				<Input
					id="PASSWORD"
					label={<Translations text="form.password.password" />}
					placeholder={<Translations text="form.password.enterPassword" />}
					errorText={<Translations text="form.password.passwordRequired" />}
					type="Password"
					element="input"
					validators={[VALIDATOR_REQUIRE()]}
					onInput={InputHandler}
					iconName={<FontAwesomeIcon icon={faLock} />}
				/>

				<Button
					className={`${classes.loginFormButton} width-100`}
					disabled={!state.isValid || loading}
					formButton
				>
					{loading ? <Translations text="shared.loading" /> : <Translations text="shared.login" />}
				</Button>

				{
					<div className={`${classes.linkContainer} font-200 `}>
						<div>
							<Translations text="form.password.newUserSite" />
							<Link to={`/auth/signup`} className={classes.linkText}>
								<Translations text="shared.signUp" />
							</Link>
						</div>
						<div>
							<Translations text="form.password.forgotPassword" />
							<Link to={`/auth/forget-password`} className={classes.linkText}>
								<Translations text="shared.clickHere" />
							</Link>
						</div>
					</div>
				}
			</form>
		</div>
	);
};

export default LoginForm;
