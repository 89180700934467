import settings from "../../../configs/config_settings.json";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import AssetsCustomCarousel, {
	AssetsCustomCarouselSlickSettings,
} from "../../Common/CustomCarousel/AssetsCustomCarousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getAllCategoriesByGroupType } from "../../../scripts/dataHandlerReactquery";
import Header from "../../Common/Header/Header";
import AssetsItem from "../../Assets/AssetsItem/AssetsItem";
import { GROUPTYPE } from "../../../constants/groupType";
import Translations from "../../Translations";
import { ROUTES } from "../../../constants/route";

const Serie = () => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;
	let user = {};

	const fetchAllSerie = () => {
		// NEED TO FETCH CATEGORYID OF ALL SERIES
		return getAllCategoriesByGroupType(organizationId, key, language, GROUPTYPE.SERIES, user);
	};

	const { data: allSerie, isLoading } = useQuery(`allSerie`, fetchAllSerie, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
		select: (res) => res?.data?.groupItems?.reverse(),
	});
	// console.log(allSerie);
	const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];

	if (isLoading) {
		return (
			<>
				<Header title={<Translations text="series.allSeries" />} viewAll={false} />
				<AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
					{skeletonItem.map((el2) => (
						<SkeletonTheme key={el2}>
							<p>
								<Skeleton
									key={el2}
									width={"100%"}
									height={"325px"}
									style={{
										borderRadius: "10px",
									}}
								/>
							</p>
						</SkeletonTheme>
					))}
				</AssetsCustomCarousel>
			</>
		);
	}
	if (!isLoading && allSerie && allSerie?.length > 0) {
		return (
			<div>
				<Header title={<Translations text="series.allSeries" />} viewAllLink={`${ROUTES.SERIES}`} />
				<AssetsCustomCarousel>
					{allSerie?.map((el) => (
						<AssetsItem
							title={el?.name || el?.title}
							asset={el}
							id={el?.id}
							key={el?.id}
							pushRoute={`${ROUTES.SERIES_CATEGORIES}/${organizationId}/${el?.id}`}
						/>
					))}
				</AssetsCustomCarousel>
			</div>
		);
	}
};

export default Serie;
