// REACT AND NEXT IMPORT
import React, { useState, useEffect } from "react";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { useForm } from "../hooks/form-hook";
import { useMyContext } from "../contexts/StateHolder";
import { updateUser } from "../scripts/dataHandlers";
import { reactQueryGetUser } from "../scripts/dataHandlerReactquery";

import CustomToast from "../components/Common/CustomToast/CustomToast";
import ProfileForm from "../components/Form/ProfileForm";
import settings from "../configs/config_settings.json";
import SidebarContainer from "../components/navigation/profile/SidebarContainer";

const Profile = () => {
	const { t } = useTranslation();
	const { organizationId, key } = settings.organization;
	const [profileData, setProfileData] = useState([]);
	const [loading, setLoading] = useState(false);

	const { user } = useMyContext();

	const [state, InputHandler] = useForm(
		{
			EMAIL: {
				value: "",
				isValid: false,
			},

			FIRSTNAME: {
				value: "",
				isValid: false,
			},
			LASTNAME: {
				value: "",
				isValid: false,
			},
			CITY: {
				value: "",
				isValid: true,
			},
			ADDRESS: {
				value: "",
				isValid: true,
			},
			POSTALCODE: {
				value: "",
				isValid: true,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);

	const fetchUserDetail = () => {
		return reactQueryGetUser(organizationId, user?.userToken);
	};

	const { data: userDetail, isLoading } = useQuery(`userDetail`, fetchUserDetail, {
		// sends API request when user is authenticated ->user should not reach here if they are not authenticated
		enabled: user?.userId * 1 > 1,

		// by default the cache time is 5 minutes
		cacheTime: 300000,
	});

	useEffect(() => {
		if (!isLoading) {
			const newData = {
				firstName: userDetail?.data.firstName,
				lastName:
					userDetail?.data.lastName !== "x" &&
					userDetail?.data.lastName !== "Sub" &&
					userDetail?.data.lastName !== "-"
						? userDetail?.data.lastName
						: "",
				phone:
					userDetail?.data.phone !== "0" && userDetail?.data.phone !== "-"
						? userDetail?.data.phone
						: "",
				email: userDetail?.data.emailAddress,
				city: userDetail?.data.city !== "-" ? userDetail?.data.city : "",
				country: userDetail?.data.country !== "x" ? userDetail?.data.country : "",
				countryId: userDetail?.data.countryId !== "x" ? userDetail?.data.countryId : "",
				yTunnus:
					userDetail?.data?.userSettings?.items?.find((item) => item.name === "yTunnus") &&
					userDetail?.data?.userSettings?.items?.find((item) => item.name === "yTunnus").value !==
						"x"
						? userDetail?.data?.userSettings?.items?.find((item) => item.name === "yTunnus").value
						: "",
				organizationName:
					userDetail?.data?.userSettings?.items?.find((item) => item.name === "organizationName") &&
					userDetail?.data?.userSettings?.items?.find((item) => item.name === "organizationName")
						.value !== "x"
						? userDetail?.data?.userSettings?.items?.find(
								(item) => item.name === "organizationName"
						  ).value
						: "",
				address: userDetail?.data.address !== "-" ? userDetail?.data.address : "",
				postalCode: userDetail?.data.postalCode !== "0" ? userDetail?.data.postalCode : "0",
				regionId: userDetail?.data.regionId !== "-" ? userDetail?.data.regionId : "",
				dateOfBirth:
					userDetail?.data?.dateOfBirth && userDetail?.data?.dateOfBirth !== "x"
						? userDetail?.data.dateOfBirth
						: Date(),

				userId: userDetail?.data?.userId,
			};

			setProfileData({ ...newData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const formSubmitHandler = async () => {
		setLoading(true);
		try {
			// Update user data
			const response = await updateUser(
				user?.userId,
				user?.userToken,
				organizationId,
				key,
				state.inputs.FIRSTNAME.value ? state.inputs.FIRSTNAME.value : "-",
				state.inputs.LASTNAME.value ? state.inputs.LASTNAME.value : "-",
				// NOT ALLOWING TO CHANGE PHONE NUMBER SO SENDING 1
				1,
				// NOT ALLOWING TO CHANGE COUNTRY AND NEED TO SEND COUNTRYID LATER SO SENDING 88 WHICH IS FINLAND COUNTRYID

				88,
				profileData.regionId,
				state.inputs.CITY.value ? state.inputs.CITY.value : "-",
				state.inputs.POSTALCODE.value ? state.inputs.POSTALCODE.value : "-",
				state.inputs.EMAIL.value,
				state.inputs.ADDRESS.value
			);
			response?.data?.status === "ok"
				? CustomToast(t("user.profileUpdated"))
				: CustomToast(response?.data?.message || t("shared.errorGeneric"), "error");

			setLoading(false);
		} catch (err) {
			console.log(err);
			CustomToast(t("shared.errorGeneric"), "error");
			setLoading(false);
		}
	};
	return (
		profileData?.email && (
			<SidebarContainer>
				<ProfileForm
					formSubmitHandler={formSubmitHandler}
					loading={loading}
					profileData={profileData}
					state={state}
					InputHandler={InputHandler}
				/>
			</SidebarContainer>
		)
	);
};

export default Profile;
