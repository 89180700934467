import React from "react";
import { convertSecondToMin } from "../../../scripts/utils";
import classes from "./AssetItemInfo.module.css";
import { getContentRatingText } from "../../../lib/getContentRatingText";
import { isAssetSerie } from "../../../lib/isAssetSerie";

const AssetItemInfo = ({ asset, showDuration, extraClassname }) => {
	const duration = convertSecondToMin(asset?.duration);
	let releaseYear = asset?.releaseYear * 1;
	return (
		<div className={`${classes.container} ${extraClassname && classes.extraClassname} font-200`}>
			{(!isAssetSerie(asset) || showDuration) && <div>{duration}</div>}
			{/* // WE DONT SHOW DURATION IN CASE OF SERIE ->CAUSE THEY HAVE MULTIPLE EPISODES but have to show duration when it is episodes */}

			{(!isAssetSerie(asset) || showDuration) &&
				duration &&
				!isNaN(releaseYear) &&
				releaseYear * 1 > 0 && <div className={classes.divider}>{"•"}</div>}

			{!isNaN(releaseYear) && releaseYear * 1 > 0 && <div>{releaseYear}</div>}
			{!isNaN(releaseYear) && releaseYear * 1 > 0 && <div className={classes.divider}>{"•"}</div>}
			<div>{getContentRatingText(asset)}</div>
		</div>
	);
};

export default AssetItemInfo;
