import * as React from "react";
import { useLocation } from "react-router";

import { ROUTES } from "../constants/route";

export default function ScrollToTop() {
	const { pathname } = useLocation();
	// / Exclude certain paths from the scroll-to-top behavior
	const excludePaths = [ROUTES.TOS, ROUTES.SERIES_CATEGORIES];
	const shouldExclude = excludePaths.some((path) => pathname.startsWith(path));

	console.log(shouldExclude, "shouldExclude", excludePaths, pathname);
	React.useEffect(() => {
		if (!shouldExclude) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [pathname, shouldExclude]);

	return null;
}
