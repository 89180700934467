export const ROUTES = {
	HOME: "/",

	LOGIN: "/auth/login",
	SIGNUP: "/auth/signup",
	FORGET_PASSWORD: "/auth/forget-password",

	PROFILE: "/user/profile",
	CHANGE_PASSWORD: "/user/change-password",
	SUBSCRIPTION: "/user/subscription",

	SEARCH: "/search",

	PACKAGES: "/packages",

	VIDEO: "/video",

	VIDEO_DETAILS: "/video/details",
	SERIES_DETAILS: "/serie/details",

	SERIES_CATEGORIES: "/series/categories",
	CATEGORIES: "/categories",
	KATSO_CATEGORIES: "/katso/categories",
	VUOKRAAMO_CATEGORIES: "/vuokraamo/categories",
	SERIES: "/series",

	LIVE: "/embed/livetv",

	FAQ: "/faq",
	PRIVACY_POLICY: "/privacy-policy",
	ACCESSIBILITY: "/accessibility",
	TOS: "/tos",
	CONTACT: "/contact",

	TVOD: "/vuokraamo",
	SVOD: "/katso",
};
