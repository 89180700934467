import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import SubscriptionDetail from "./SubscriptionDetail";
import Header from "../Common/Header/Header";
import Translations from "../Translations";

import classes from "./Subscription.module.css";
import { cancelSVODSubscription } from "../../scripts/dataHandlers";
import settings from "../../configs/config_settings.json";
import CustomToast from "../Common/CustomToast/CustomToast";
import { useMyContext } from "../../contexts/StateHolder";

const SubscriptionItem = ({ user }) => {
  console.log("props", user);
  const { t } = useTranslation();

  const { user: contextUser } = useMyContext();

  const history = useHistory();

  if (!user) return null;

  let InActiveSuscription = [];

  let activeSubscriptions = [];

  if (user?.buyerProducts?.length > 0) {
    activeSubscriptions = user?.buyerProducts?.filter(
      (el) => el?.validTo >= Date.now()
    );
    InActiveSuscription = user?.buyerProducts
      .filter((product) => product?.validTo < Date.now())
      ?.sort((a, b) => b?.validTo * 1 - a?.validTo * 1);
  }
  console.log("InActiveSuscription", InActiveSuscription);

  const onCancelSubscripion = async (item) => {
    console.log("item", item);

    console.log("user", contextUser);

    const res = await cancelSVODSubscription(
      settings.organization.organizationId,
      item?.buyerProductId,
      contextUser?.userToken
    );
    console.log("res", res);

    if (res?.data?.status === "ok") {
      CustomToast("Subscription cancelled");
      history.go(0); // REFRESHING THE PAGE
    } else {
      CustomToast(res?.data?.message || t("shared.errorGeneric"), "error");
    }
  };

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {activeSubscriptions?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Header
            title={<Translations text="subscriptions.activePlan" />}
            viewAll={false}
          />
          <div
            className="grid-container"
            style={{
              padding: "10px",
              width: "100%",
              gap: "20px",
            }}
          >
            {activeSubscriptions?.map((el, i) => (
              <SubscriptionDetail
                item={el}
                key={i.toString()}
                onCancelSubscripion={onCancelSubscripion}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <Header
            title={<Translations text="subscriptions.activePlan" />}
            viewAll={false}
          />

          <div className={`${classes.noplan} font-400 width-80`}>
            <Translations text="subscriptions.noplanDescription" />
          </div>
        </div>
      )}
      {InActiveSuscription?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Header title={t("subscriptions.subTitle")} viewAll={false} />
          <div
            className="grid-container"
            style={{
              padding: "10px",
              width: "100%",
              gap: "20px",
            }}
          >
            {InActiveSuscription?.map((el, i) => (
              <SubscriptionDetail
                item={el}
                key={i.toString()}
                onCancelSubscripion={onCancelSubscripion}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionItem;
