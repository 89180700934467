import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./BannerItem.module.css";
import { convertSecondToMin } from "../../scripts/utils";
import Button from "../Common/Button/Button";

export default function BannerItem(props) {
  const { t } = useTranslation();

  let releaseYear =
    props.item.releaseYear && props.item.releaseYear !== 0
      ? props.item.releaseYear
      : false;
  return (
    <div
      className={`${classes.container_singleItem} `}
      onClick={() => props.clickItem(props.item)}
    >
      <div className={`${classes.container_singleItemPrimaryBanner2} `}>
        {props.item.bannerImageSmall && (
          <img
            src={props.item.bannerImageSmall}
            alt=""
            style={{
              position: "relative",
              zIndex: "-1",
            }}
            className={`${classes.bannerImageSmall}`}
          />
        )}
      </div>
      {
        <>
          <div className={classes.wrapper_center}>
            <div className={classes.wrapper_center_info}>
              <div className={`${classes.wrapper_center_info_title1} font-600`}>
                {props.showtitle && props.item?.name}
              </div>

              <div
                className={`${classes.wrapper_center_info_duration} font-400`}
              >
                <p>
                  {props.showDuration
                    ? convertSecondToMin(props.item.duration)
                    : null}
                </p>

                <p>{props.showReleaseYear && releaseYear && "•"}</p>
                {props.showReleaseYear && releaseYear && <p> {releaseYear}</p>}
              </div>
            </div>

            {props.showActionButton ? (
              <Button
                inverse
                style={{
                  borderRadius: "12px",
                }}
              >
                {t("banner.action")}
              </Button>
            ) : null}
          </div>
        </>
      }
    </div>
  );
}
