import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { getImageByKey } from "../../scripts/getImageByKey";

const Logo = ({ src }) => {
  return (
    <Link to={"/"}>
      <LazyLoadImage
        src={getImageByKey(src ? src : "logo")}
        height={"100%"}
        width={300}
        alt="kotikino Logo"
        className="logo"
      />
    </Link>
  );
};

export default Logo;
