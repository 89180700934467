import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/index.css";
import "./styles/carousel.css";
import "./styles/modal.css";
import "./styles/navbar.css";
import "./styles/search-modal.css";
import "./styles/pro-sidebar.css";

import "react-loading-skeleton/dist/skeleton.css";
import "react-multi-carousel/lib/styles.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// REACT TOAST
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom"; // HashRouter, basename //app to router // BrowserRouter
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import StateHolder from "./contexts/StateHolder";
import ContextFunctions from "./contexts/ContextFunctions";
import ScrollToTop from "./components/ScrollToTop";
import { ProSidebarProvider } from "react-pro-sidebar";

import App from "./App";
import "./configs/i18n/i18n";

const helmetContext = {};
const queryClient = new QueryClient();

// console.log = function () {}; // Disable all console.logs from console

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ProSidebarProvider>
			<QueryClientProvider client={queryClient}>
				<Router>
					<CookiesProvider>
						<HelmetProvider context={helmetContext}>
							<StateHolder>
								<ContextFunctions>
									<ScrollToTop />
									<App />
								</ContextFunctions>
								<ToastContainer />
							</StateHolder>
						</HelmetProvider>
					</CookiesProvider>
				</Router>
				<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
			</QueryClientProvider>
		</ProSidebarProvider>
	</React.StrictMode>
);
