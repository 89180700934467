import { getImageByKey } from "../../../scripts/getImageByKey";

const ContentRatings = (props) => {
  const { asset } = props;

  const renderContentRatings = (item) => {
    if (item) {
      const ratings = item.contentRatings.concat(item.contentTypes);

      return ratings.map((ratingElement) => {
        return (
          <img
            key={ratingElement.id}
            src={getImageByKey(ratingElement.key)}
            title={ratingElement.name}
            alt={ratingElement.name}
            style={{ width: props.size ? props.size : "30px" }}
          />
        );
      });
    }
  };

  return <div>{renderContentRatings(asset)}</div>;
};

export default ContentRatings;
