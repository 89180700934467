import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import InfiniteScroll from "react-infinite-scroll-component";

import settings from "./../configs/config_settings.json";
import { useMyContext } from "../contexts/StateHolder";
import { getUniqueSerieAndAssets } from "../lib/getUniqueSerieAndAssets";
import { createToken } from "../scripts/utils";
import { reactQueryfetchCategoryAssets } from "../scripts/dataHandlerReactquery";
import emptyState from "../constants/assets/lottie/default/data.json";

import Header from "../components/Common/Header/Header";
import GridItem from "../components/Assets/GridItem/GridItem";
import SearchSkeletonLoader from "../components/Common/SketetonPlaceholder/SearchSkeletonLoader";
import Loader from "../components/Common/Loader/Loader";

const FETCH_MORE_ITEM_NUMBER = 200;
const SHOW_HIDDEN = false;

const AssetCategories = ({ assetProperty }) => {
	const { key } = settings.organization;
	const { catTitle, orgId, catId } = useParams();
	const [pageStart, setpageStart] = useState(FETCH_MORE_ITEM_NUMBER);

	const [allVideos, setAllVideos] = useState([]);

	const [hasMore, setHasMore] = useState(false);

	const { user, language } = useMyContext();

	const fetchVideoItems = (a, b, c, d, e) => {
		const token = createToken(a, b, key);
		return reactQueryfetchCategoryAssets(a, b, token, c, assetProperty, d, e, pageStart); // FIX ME -A,B,C,D,E
	};
	// FETCH ALL ASSETS
	// initiall y it fetches NUMBER_OF_Items ->while scrolling it fetches accurate item
	const { isLoading, data } = useQuery(
		[`categories${catId}`, orgId, pageStart, language],
		() => fetchVideoItems(orgId, catId, language, user, SHOW_HIDDEN),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnSuccess: false,
			refetchInterval: false,
			select: (res) => res?.data?.assets,
			enabled: orgId * 1 > 0 && catId * 1 > 0,
		}
	);

	useEffect(() => {
		// WHEN WE HAVE FETCHED VIDEOS FROM BACKEND WE SAVE IT IN STATE AND DETERMINE WHETHER IT HAS MORE ITEMS TO FETCH OR NOT
		if (!isLoading) {
			if (data?.length > 0) {
				if (
					// IF PAGE START IS MORE THAN FETCH MORE NUMBER I.E THIS CONDINTION WILL ONLY APPLY WHEN WE ARE FETHING FOR SECOND TIME AND WE CANNOT FETCH MORE THAN 2 TIMES CAUSE OF OUR API DOESNOT ALLOW IT
					// WHEN WE GET THE DATA AND TOTOAL NUMBER OF DATA IS LESS THAN FETCH MORE -IT MEANS NO NEED TO SEND MORE DATA
					pageStart > FETCH_MORE_ITEM_NUMBER ||
					data?.length < FETCH_MORE_ITEM_NUMBER
				) {
					setHasMore(false);
				} else {
					setHasMore(true);
				}

				setAllVideos(data);
			} else {
				// no items
				setAllVideos([]);
				setHasMore(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isLoading]);

	const fetchMoreInfinitiveScrollData = () => {
		setpageStart((prev) => prev + 300);
	};

	if ((isLoading && allVideos?.length === 0) || !allVideos) {
		return <SearchSkeletonLoader />;
	}
	return (
		<div className="container">
			<Header title={catTitle} viewAll={false} />
			{allVideos?.length > 0 ? (
				<InfiniteScroll
					dataLength={allVideos?.length}
					hasMore={hasMore}
					pageStart={pageStart}
					next={fetchMoreInfinitiveScrollData}
					loader={<Loader />}
					style={{
						overflow: "hidden",
						height: "100%",
						marginTop: "20px",
						paddingBottom: "100px",
					}}
				>
					<div className="grid-container">
						{getUniqueSerieAndAssets(allVideos)?.map((el) => (
							<GridItem
								asset={el}
								key={el?.id}
								pageStart={pageStart}
								setpageStart={setpageStart}
								hasMore={hasMore}
							/>
						))}
					</div>
				</InfiniteScroll>
			) : (
				<div className="container">
					<Lottie animationData={emptyState} loop={true} style={{ height: 800 }} />
				</div>
			)}
		</div>
	);
};
export default AssetCategories;
