import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ForgetPasswordForm from "../components/Form/Password/ForgetPasswordForm";
import { useTranslation } from "react-i18next";
import { useForm } from "../hooks/form-hook";
import { resetPasswordToken } from "../scripts/utils";

import settings from "../configs/config_settings.json";
import { ACTIONS } from "../constants/action";
import { requestResetPassword } from "../scripts/dataHandlers";
import CustomToast from "../components/Common/CustomToast/CustomToast";
import PasswordToken from "../components/User/Password/PasswordToken";
import useLoginModal from "../hooks/useLoginModal";

const ForgotPassword = () => {
  const location = useLocation();

  const onClose = useLoginModal((state) => state.onClose);
  const isOpen = useLoginModal((state) => state.isOpen);

  const [queryTokenChecked, setQueryTokenChecked] = useState(false);
  const [tokenFromEmail, setTokenFromEmail] = useState(false);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [resetEmailSuccess, setResetEmailSuccess] = useState(false);

  const [state, InputHandler] = useForm(
    {
      EMAIL: {
        value: "",
        isValid: false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = resetPasswordToken(
        state.inputs.EMAIL.value,
        settings.organization.organizationId,

        ACTIONS.ISMSUBSCRIBER,
        settings.organization.key
      );

      const res = await requestResetPassword(
        state.inputs.EMAIL.value,
        settings.organization.organizationId,

        ACTIONS.ISMSUBSCRIBER,
        token
      );
      if (res?.data?.status === "ok") {
        setResetEmailSuccess(true);
      } else if (res?.data?.status === "error") {
        CustomToast(t("pages.forgotPassword.emailDoesnotExist"), "error");
      } else {
        // this basically means our servers are down
        CustomToast(t("shared.errorGeneric"), "error");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      CustomToast(t("shared.errorGeneric"), "error");
    }
  };

  useEffect(() => {
    const tok1 = new URLSearchParams(location.search).get("token");
    console.log("tok1", tok1);

    if (tok1) {
      setTokenFromEmail(tok1);
    }
    setQueryTokenChecked(true);
    return () => {
      setTokenFromEmail("");
    };
  }, [location.search]);
  useEffect(() => {
    if (isOpen) {
      onClose();
    }
  }, [isOpen, onClose]); // we close login modal if open

  console.log("tokenFromEmail", tokenFromEmail, queryTokenChecked);

  if (!queryTokenChecked) {
    return "loading";
  }

  return !tokenFromEmail ? (
    <ForgetPasswordForm
      state={state}
      InputHandler={InputHandler}
      resetEmailSuccess={resetEmailSuccess}
      formSubmitHandler={formSubmitHandler}
      loading={loading}
    />
  ) : (
    <PasswordToken tokenFromEmail={tokenFromEmail} />
  );
};

export default ForgotPassword;
