// import { useTranslation } from "react-i18next";
// import ReactShowMoreText from "react-show-more-text";

// import classes from "./ExpandabelText.module.css";
import VideoDetailDescription from "../../Assets/VideoDetails/VideoDetailDescription/VideoDetailDescription";

const ExpandableText = ({ text }) => {
	// const { t } = useTranslation();
	return (
		// <ReactShowMoreText
		// 	lines={10}
		// 	more={t("guidesBar.Read more")}
		// 	less={t("guidesBar.readLess")}
		// 	className={classes.showMoreText}
		// 	anchorClass={classes.showMoreLessClickable}
		// 	expanded={false}
		// 	truncatedEndingComponent={"... "}
		// >
		<VideoDetailDescription
			showAll={true}
			desc={text}
			size="1000000"
			mobileSize="1000000"
			sanitizeHTML={true}
			color={"black"}
		/>
		// </ReactShowMoreText>
	);
};

export default ExpandableText;
