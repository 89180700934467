import { useTranslation } from "react-i18next";
import classes from "./PromoCard.module.css";

import { PromoCardItem } from "./PromoCardItem";

export const PromoCard = () => {
	const { t, i18n } = useTranslation();
	const items = [
		// {
		// 	title: t("navigation.menuList.svod"),
		// 	buttonText: t("promo.svodButtonText"),
		// 	buttonLink: "/katso",
		// 	description: i18n?.language.includes("fi")
		// 		? ` <br/><h2 style=text-align:center>6,99 &euro;/kk</h2><br/>
		// 	<h2>Elokuvat ja sarjat</h2>
		// 	<br/>
		// 	<p>Katso niin paljon kuinhaluat. Ei pitk&auml;&auml; sitoutumista, irtisano koska vaan</p>`
		// 		: `<br/><h2 style="text-align:center">6.99 euros/month</h2><br/>
		// 	<h2>Movies and series</h2>
		// 	<br/>
		// 	<p>Watch as much as you want. No long-term commitment, cancel anytime.</p>
		// 	`,
		// },
		// {
		// 	title: t("navigation.menuList.tvod"),
		// 	description: i18n?.language.includes("fi")
		// 		? `<h2><br />
		// 	Elokuvat ja sarjat</h2>
		// 	<p>Paljon kotimaisia ja ulkomaisia huippuleffoja sek&auml; klassikoita, joita et muualta l&ouml;yd&auml;</p>
		// 	<h2 style=margin-bottom:5px><br /><strong>
		// 	Aina edulliset hinnat </strong></h2>
		// 	<p>Alk. 2,88 &euro;<br />
		// 	&nbsp;</p>`
		// 		: `<h2><br />
		// 	Movies and series</h2>
		// 	<p>Many domestic and foreign top movies as well as classics that you won't find elsewhere</p>
		// 	<h2 style="margin-bottom:5px"><br /><strong>
		// 	Always affordable prices </strong></h2>
		// 	<p>Starting from 2.88 euros<br />
		// 	&nbsp;</p>
		// 	`,
		// 	buttonText: t("promo.tvodButtonText"),
		// 	buttonLink: "/vuokraamo",
		// },

		{
			title: t("navigation.menuList.svod"),
			buttonText: t("promo.tvodButtonText"),
			buttonLink: "/katso",
			description: i18n?.language.includes("fi")
				? ` <br/><h2>9,99 &euro;/kk</h2><br/>

			<h2>Elokuvat ja sarjat</h2>
			<br/>
			
			<p>Jatkuvasti kasvava valikoima huippuelokuvia ja -sarjoja.</p>
      <br/><p>Ei pitkää sitoutumista, keskeytä koska tahansa.</p>`
				: `<br/><h2>9.99 euros/month</h2><br/>

			<h2>Movies and series</h2>
			<br/>
			
			<p>A constantly growing selection of top movies and series.</p>
			
      <br/>
      <p>No long commitment, cancel anytime.</p>`,

			content: (
				<>
					<h1
						style={{
							marginBottom: "12px",
							marginTop: "12px",
							fontSize: "2.4em",
						}}
					>
						9,99 €/kk
					</h1>
					<h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
						}}
					>
						{i18n?.language.includes("fi") ? "Elokuvat js sarjat" : "Movies and series"}
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							display: "flex",
							flexDirection: "column",
							gap: "12px",
							fontSize: "16px",
						}}
					>
						<p style={{}}>
							{i18n?.language.includes("fi")
								? "Jatkuvasti kasvava valikoima huippuelokuvia ja -sarjoja."
								: "A constantly growing selection of top movies and series."}
						</p>
						<p>
							{i18n?.language.includes("fi")
								? "Ei pitkää sitoutumista, keskeytä koska tahansa."
								: "No long commitment, cancel anytime."}
						</p>
					</p>
				</>
			),
		},
		{
			title: t("navigation.menuList.tvod"),
			description: i18n?.language.includes("fi")
				? `<h2><br />
			Elokuvat ja sarjat</h2>
			
			<p>Vuokraa elokuvia ja sarjoja kertamaksulla. Vuokraelokuva on katsottavissa 48h ajan.</p>
			
			<h2 style=margin-bottom:5px><br /><strong>
			Aina edulliset hinnat </strong></h2>
			
			<p>Alk. 3,99 &euro;<br />
			&nbsp;</p>`
				: `<h2><br />
			Movies and series</h2>
			
			<p>Rent movies and series for a one-time fee. The rental movie can be watched for 48 hours.</p>
			
			<h2 style="margin-bottom:5px"><br /><strong>
			Always affordable prices </strong></h2>
			
			<p>Starting from 3.99 euros<br />
			&nbsp;</p>
			`,

			buttonText: t("promo.tvodButtonText"),

			buttonLink: "/vuokraamo",

			content: (
				<>
					<h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
							marginTop: "30px",
						}}
					>
						{i18n?.language.includes("fi") ? "Elokuvat js sarjat" : "Movies and series"}
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							fontSize: "16px",
						}}
					>
						<p style={{}}>
							{i18n?.language.includes("fi")
								? "Vuokraa elokuvia ja sarjoja kertamaksulla. Vuokraelokuva on katsottavissa 48h ajan."
								: "Rent movies and series for a one-time fee. The rental movie can be watched for 48 hours."}
						</p>
					</p>
					<h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
							marginTop: "20px",
						}}
					>
						{i18n?.language.includes("fi") ? "Aina edulliset hinnat" : "Always affordable prices"}
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							fontSize: "16px",
						}}
					>
						{i18n?.language.includes("fi") ? "Alk. 3,99 €" : "Starting from 3.99 €"}
					</p>
				</>
			),
		},
	];
	return (
		<div className={classes.container}>
			{items.map((el) => (
				<PromoCardItem item={el} key={el.title} />
			))}
		</div>
	);
};
