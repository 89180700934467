import classes from "./PasswordInavlidToken.module.css";

// THIRD PARTY IMPORT

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translations from "../../Translations";
import Button from "../../Common/Button/Button";

const PasswordInvalidToken = () => {
  return (
    <div className={`${classes.container}`}>
      <div
        className="font-700"
        style={{ textAlign: "center", color: "#ab0303" }}
      >
        <Translations text="user.invalidLink" />
      </div>

      <FontAwesomeIcon
        icon={faTimesCircle}
        className="font-800"
        style={{ display: "flex", alignSelf: "center", color: "#ab0303" }}
      />
      <div>
        <Translations text="user.tokenExpired" />
      </div>

      <div>
        <Translations text="user.getNewEmail" />
      </div>

      <Button href={`/auth/forget-password`}>
        <Translations text="user.requestResetPassword" />
      </Button>
    </div>
  );
};

export default PasswordInvalidToken;
