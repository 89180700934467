import { getImageByKey } from "../../../scripts/getImageByKey";

import classes from "./PaymentMethod.module.css";
import classes2 from "../PackageComponent/PackageComponent.module.css";

//single payment method
const PaymentMethod = (props) => {
	const { paymentMethod, buyFunction, userLoggedIn } = props;

	return (
		<img
			src={getImageByKey(`paymentMethod${paymentMethod?.name}`)}
			className={`${classes2.container} ${classes.image} ${!userLoggedIn && classes.imageDisabled}`}
			alt={paymentMethod?.name}
			onClick={() => userLoggedIn && buyFunction(paymentMethod.id, paymentMethod.key)}
		/>
	);
};

export default PaymentMethod;
