import React, { useEffect, useState } from "react";

import settings from "../configs/config_settings.json";
import VodVideoPlayer from "../components/Player/Vod/VodVideoPlayer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { reactQueryGetAsset } from "../scripts/dataHandlerReactquery";
import { useMyContext } from "../contexts/StateHolder";
import { useQuery } from "react-query";
import { createAssetIdToken } from "../scripts/utils";
import { useCookies } from "react-cookie";
import { ROUTES } from "../constants/route";

const Video = () => {
	const { organizationId, key, groupId } = settings?.organization;
	const { language } = settings;

	const { userLoggedIn, user } = useMyContext();
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies("");

	let playerConfig = {};
	const { asset } = useParams();
	const location = useLocation();
	const history = useHistory();

	const allCampaigns = [];
	const [isAccessedGranted, setIsAccessedGranted] = useState(false);
	let host = "";

	const fetchAsset = (a, b, c, d, e, f) => {
		console.log("a,b,c,d,e", a, b, c, d, e);
		return reactQueryGetAsset(a, b, c, d, e, null, f); // FIX ME
	};

	const { data, isLoading } = useQuery(
		[`getAssets${asset}`, asset, userLoggedIn, cookies?.useMy],
		() => fetchAsset(organizationId, language, key, asset, user, cookies?.useMy),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnSuccess: false,
			refetchInterval: false,
			enabled: asset * 1 > 0,
		}
	);

	const assetToken = createAssetIdToken(organizationId, asset, false, key);

	useEffect(() => {
		if (!isLoading) {
			const item = data?.data;
			if (item?.accessInfo?.access === 1) {
				// DO NOTHING
				setIsAccessedGranted(true);
			} else if (item?.accessInfo?.products) {
				if (item?.accessInfo?.products?.length === 0) {
					// DO NOTHING
					setIsAccessedGranted(true);
				} else {
					if (userLoggedIn) {
						// If there's product available, but user doesnt have access, push to packages route
						setCookie(
							"packageReturn",
							{ returnPath: location.pathname },
							{ path: "/", secure: true, sameSite: "none" }
						);
						history.push(`${ROUTES.PACKAGES}/asset/${asset}`);
					} else {
						history.push(ROUTES.LOGIN);
					}
				}
			}
		}
	}, [isLoading, asset, data?.data, setCookie, location.pathname, history, userLoggedIn]);

	return (
		isAccessedGranted && (
			<VodVideoPlayer
				asset={data?.data}
				playerConfig={playerConfig}
				assetToken={assetToken}
				organizationId={organizationId}
				groupId={groupId}
				allCampaigns={allCampaigns}
				host={host}
				useMY={cookies?.useMy}
			/>
		)
	);
};

export default Video;
