import React from "react";
import ReactModal from "react-modal";
import useLoginModal from "../../hooks/useLoginModal";
import { customStyles } from "../../lib/getLoginModalCustomStyles";
import Login from "../../pages/Login";

const LoginModal = () => {
  const loginModal = useLoginModal();
  return (
    <ReactModal
      isOpen={loginModal?.isOpen}
      style={customStyles}
      onRequestClose={() => loginModal.onClose()}
      overlayClassName="Overlay"
    >
      <Login
        hideImage
        extraClassName="modal-login-container"
        customCallback={() => {}} // put this else it will nvigate to home page whenlogged in
      />
    </ReactModal>
  );
};

export default LoginModal;
