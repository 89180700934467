import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import settings from "../configs/config_settings.json";

// NEXT AND REACT IMPORT

import { useMyContext } from "../contexts/StateHolder";
import { searchAssets } from "../scripts/dataHandlers";
import { ROUTES } from "../constants/route";
import { getUniqueSerieAndAssets } from "../lib/getUniqueSerieAndAssets";

import GridItem from "../components/Assets/GridItem/GridItem";
import SearchSkeletonLoader from "../components/Common/SketetonPlaceholder/SearchSkeletonLoader";
import SearchBar from "../components/Search/SearchBar";
import NoSearchResult from "../components/Search/NoSearchResult";

import Header from "../components/Common/Header/Header";

const Search = () => {
	const { organizationId } = settings.organization;

	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();

	const { user, language } = useMyContext();

	const [searchQuery, setSearchQuery] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [searchResultSeries, setSearchResultSeries] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchFieldInput, setSearchFieldInput] = useState("");

	console.log("location", location);

	useEffect(() => {
		let searchParam = new URLSearchParams(history.location?.search);
		let query = searchParam.get("value");
		console.log("query", query);

		if (query && query !== searchQuery) {
			setSearchQuery(query);
			setSearchFieldInput(query);
		}
	}, [history.location?.search, searchQuery, location?.search]);

	useEffect(() => {
		if (searchQuery) {
			const fetchData = async () => {
				setIsLoading(true);

				const response = await searchAssets(searchQuery, user, organizationId, language);
				if (response.data.status === "ok") {
					let newSeries = [];
					response?.data?.series?.map((el) => {
						let updatedItem = { ...el };
						updatedItem.isSerieCategory = true;
						updatedItem.organizationId = organizationId;
						return newSeries.push(updatedItem);
					});

					let allAssets = [...response.data.assets];
					let updatedAllAssets = getUniqueSerieAndAssets(allAssets);

					let updatedAllSeries = getUniqueSerieAndAssets([...newSeries]);

					setSearchResult(updatedAllAssets);
					setSearchResultSeries(updatedAllSeries);
				}
				setIsLoading(false);
			};

			fetchData();
		}
	}, [searchQuery, user, organizationId, language]);

	const searchButtonHandler = (e) => {
		e.preventDefault();
		setSearchQuery(searchFieldInput);

		history.push(`${ROUTES.SEARCH}?value=${searchFieldInput}`);
	};

	const RenderSearchResult = ({ assets, series }) => {
		// THERE IS A PROBLEM IN LazyLoadImage ->WHEN USING SEARCH INPUT IT CAUSES FLIECKERING AND RERENDER
		return (
			<div>
				{series?.length > 0 && (
					<div>
						<Header title={t("navBar.series")} viewAll={false} />

						<div className="grid-container">
							{series?.map((el, i) => (
								<GridItem asset={el} key={`${el?.id}.${i}`} useIMG={true} />
							))}
						</div>
					</div>
				)}

				{assets?.length > 0 && (
					<div>
						<Header title={t("navBar.movies")} viewAll={false} />

						<div className="grid-container">
							{assets?.map((el, i) => (
								<GridItem asset={el} key={`${el?.id}.${i}`} useIMG={true} />
							))}
						</div>
					</div>
				)}
			</div>
		);
	};
	console.log("isLoading", isLoading, searchResult?.length);

	return (
		<div className="container">
			<SearchBar
				submitHanlder={searchButtonHandler}
				setSearchFieldInput={setSearchFieldInput}
				searchFieldInput={searchFieldInput}
			/>

			{isLoading ? (
				<SearchSkeletonLoader />
			) : searchResult?.length > 0 || searchResultSeries?.length > 0 ? (
				<RenderSearchResult assets={searchResult} series={searchResultSeries} />
			) : (
				<NoSearchResult />
			)}
		</div>
	);
};

export default Search;
