"use client";

import { ChevronDown, ChevronUp } from "lucide-react";

const buttonStyle = {
	height: "32px",
	padding: 0,
	width: "32px",
	background: "transparent",
	cursor: "pointer",
};

const iconStyle = {
	height: "32px",
	width: "32px",
	background: "transparent",
	cursor: "pointer",
};

const ToggleDescription = ({
	isDescriptionOpen,
	descriptionClosehandler,
	descriptionOpenhandler,
}) => {
	return isDescriptionOpen ? (
		<button onClick={descriptionClosehandler} aria-label="See more description" style={buttonStyle}>
			<ChevronUp className={"w-8 h-8"} fill="brand" style={iconStyle} />
		</button>
	) : (
		<button onClick={descriptionOpenhandler} aria-label="See less description" style={buttonStyle}>
			<ChevronDown fill="brand" style={iconStyle} />
		</button>
	);
};

export default ToggleDescription;
