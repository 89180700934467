import TOS from "../components/TOS/TOS";

const tos = () => {
	return (
		<div className="container">
			<TOS />
		</div>
	);
};

export default tos;
