import React from "react";
import { useQuery } from "react-query";

import settings from "../configs/config_settings.json";

import Header from "../components/Common/Header/Header";
import GridItem from "../components/Assets/GridItem/GridItem";
import Translations from "../components/Translations";
import SearchSkeletonLoader from "../components/Common/SketetonPlaceholder/SearchSkeletonLoader";

import { GROUPTYPE } from "../constants/groupType";
import { ROUTES } from "../constants/route";
import { getAllCategoriesByGroupType } from "../scripts/dataHandlerReactquery";
const AllSeries = () => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;
	let user = {};

	const fetchAllSerie = () => {
		// NEED TO FETCH CATEGORYID OF ALL SERIES
		return getAllCategoriesByGroupType(organizationId, key, language, GROUPTYPE.SERIES, user);
	};

	const { data: allSerie, isLoading } = useQuery(`allSerie`, fetchAllSerie, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
		select: (res) => res?.data?.groupItems?.reverse(),
	});

	if ((isLoading && allSerie?.length === 0) || !allSerie) {
		return <SearchSkeletonLoader />;
	}
	return (
		<div className="container">
			<Header title={<Translations text="series.allSeries" />} viewAll={false} />

			<div className="grid-container">
				{allSerie?.map((el) => (
					<GridItem
						asset={el}
						key={el?.id}
						pushRoute={`${ROUTES.SERIES_CATEGORIES}/${organizationId}/${el?.id}`}
					/>
				))}
			</div>
		</div>
	);
};

export default AllSeries;
