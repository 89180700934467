import React from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";

import "./styles.css";

import DropdownItem from "./DropdownItem";

const ScrollAreaRadix = ({ items, title, ...props }) => (
	<ScrollArea.Root className="ScrollAreaRoot" style={props?.style}>
		<ScrollArea.Viewport className="ScrollAreaViewport">
			<div style={{ padding: "15px 5px" }}>
				{title && <div className="title font-500">{title}</div>}
				{items.map((tag, i) => (
					<div
						className={i !== 0 ? "Tag" : ""}
						key={tag?.title || i}
						style={{
							cursor: "pointer",
						}}
					>
						<DropdownItem {...props} {...tag} />
					</div>
				))}
			</div>
		</ScrollArea.Viewport>
		<ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="vertical">
			<ScrollArea.Thumb className="ScrollAreaThumb" />
		</ScrollArea.Scrollbar>
		<ScrollArea.Scrollbar className="ScrollAreaScrollbar" orientation="horizontal">
			<ScrollArea.Thumb className="ScrollAreaThumb" />
		</ScrollArea.Scrollbar>
		<ScrollArea.Corner className="ScrollAreaCorner" />
	</ScrollArea.Root>
);

export default ScrollAreaRadix;
