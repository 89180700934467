import React from "react";

import classes from "./Subscription.module.css";
import { convertDate } from "../../scripts/utils";
import { useTranslation } from "react-i18next";
import Colors from "../Colors";
import Button from "../Common/Button/Button";

const SubscriptionDetail = ({ item, onCancelSubscripion }) => {
  const isCancelSVODVisible =
    item?.sku?.includes("SV") &&
    item?.validTo >= Date.now() &&
    item?.nextPayment > Date.now();
  const { t } = useTranslation();
  return (
    <div
      className={classes.container}
      style={{
        border: item?.validTo >= Date.now() && `2px dashed ${Colors.brand}`,
        // color: item?.validTo >= Date.now() && Colors.background,
      }}
    >
      <div className={classes.item}>
        <div className={`${classes.itemName} font-400`}>{item.name}</div>
        <div className={classes.inner}>
          <div className={classes.subtitle}>{`${t(
            "subscriptions.activeTill"
          )}:`}</div>
          <div className={classes.validity}>
            {convertDate(item.validFrom)}
            <div className={classes.separator}>-</div>
            {convertDate(item.validTo)}
          </div>

          {/* // WE DONT SHOW NEXT PAYMENT DATE FOR INACTIVE OR TVOD PRODUCT */}
          {isCancelSVODVisible && (
            <>
              <div className={classes.subtitle}>{`${t(
                "subscriptions.nextPayment"
              )}:`}</div>
              {convertDate(item.nextPaymentDate)}

              <div
                className="mt-20"
                style={{
                  maxWidth: "100%",
                  padding: "auto",
                }}
              >
                <Button
                  style={{
                    maxWidth: "100%",
                    padding: "6px 40px",
                    color: Colors.white,
                  }}
                  danger
                  onClick={() => onCancelSubscripion(item)}
                >
                  {t("shared.cancel")}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetail;
