import NavItem from "../NavItem";

import classes from "./Dropdown.module.css";

// NEXT ANF REACT IMPORT
import React, { useEffect, useRef, useState } from "react";

// THIRD PARTY IMPORT
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../WindowDimension";
import { useMyContext } from "../../../contexts/StateHolder";
import Translations from "../../Translations";
import { getImageByKey } from "../../../scripts/getImageByKey";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/route";
import ScrollAreaRadix from "./ScrollArea";

const AuthDropdown = ({ ariaLabelledBy, setIsMobileNavOpen }) => {
	const [open, setOpen] = useState(false);

	const menuRef = useRef();
	const history = useHistory();
	const [cookies, removeCookie] = useCookies("");

	const { t } = useTranslation();

	const { userLoggedIn, user, setUser, setUserLoggedIn } = useMyContext();

	const { width } = useWindowDimensions();

	const DROPDOWN_ITEMS = [
		{
			text: t("navigation.menuList.profile"),
			href: "/user/profile",
			icon: <FontAwesomeIcon icon={faUser} aria-hidden={false} />,
		},

		{
			text: t("navigation.menuList.logout"),

			href: "/",
			icon: <FontAwesomeIcon icon={faRightFromBracket} aria-hidden={false} />,
			onClick: () => {
				// signOut();
				setUserLoggedIn(false);
				setUser({});

				if (cookies?.user) {
					removeCookie("user", 1, { path: "/", expires: new Date(Date.now()) });
				}
				history.push(ROUTES.HOME);

				window.location.reload();
			},
		},
	];

	useEffect(() => {
		const handler = (e) => {
			if (!menuRef?.current?.contains(e.target)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	return userLoggedIn && user?.firstName ? (
		<div
			style={{
				position: "relative",
			}}
			ref={menuRef}
			// SHOWING DROPDOWN ON HOVER AND LEAVE AND BIGGER DEVICE
			onMouseEnter={() => width >= 768 && setOpen(true)}
			onMouseLeave={() => width >= 768 && setOpen(false)}
			aria-labelledby={ariaLabelledBy}
		>
			<button
				className={`${classes.menuContainer}  `}
				id={ariaLabelledBy}
				aria-label={ariaLabelledBy}
			>
				<div
					className={classes.menuTrigger}
					onClick={() => {
						setOpen(!open);
					}}
				>
					{/* // in small screen we also show name (profile) */}
					{width <= 768 && (
						<div className="nav__item">
							<Translations text="navigation.auth.user" />
						</div>
					)}

					<img
						src={getImageByKey("user_profile")}
						alt="user_profile"
						height={50}
						width={50}
						className={classes.containerbgImage}
						placeholder="blur"
					/>
				</div>
			</button>
			<div
				className={`${classes["dropdown-menu"]} ${
					open ? classes.activeDropdown : classes.inactiveDropdown
				}`}
			>
				<div className={classes.dropdownItemsContainer}>
					<ScrollAreaRadix
						items={DROPDOWN_ITEMS}
						setOpen={setOpen}
						setIsMobileNavOpen={setIsMobileNavOpen}
						style={{
							height: "100%",
						}}
						title={user?.firstName || "kotikino"}
					/>
				</div>
			</div>
		</div>
	) : (
		// show only login button when not logged in
		<div
			onClick={() => {
				setIsMobileNavOpen(false);
			}}
		>
			<NavItem
				{...{
					text: <Translations text="navigation.menuList.login" />,
					href: "/auth/login",
				}}
			/>
		</div>
	);
};

export default AuthDropdown;
