import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useMyContext } from "../../contexts/StateHolder";

import classes from "./FAQ.module.css";
import { ROUTES } from "../../constants/route";

const FAQEn = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const { languageCode } = useMyContext();

	useEffect(() => {
		if (languageCode?.includes("fi")) {
			return history.push(ROUTES.FAQ);
		}
	}, [languageCode, history]);

	return (
		<div className="container">
			<div className={`${classes.faqContainer} font-300`}>
				<div className={`${classes.faqTitle} font-600`}>{t("faq.title")}:</div>
				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						How do I place an order, and will I receive an order confirmation?
					</div>
					<br />
					The customer registers in the Kotikino system and creates a personal account (email and
					password). The order is placed by paying for the ordered service through the online
					store's payment system. If, for any reason, the customer does not receive an order
					confirmation, the order likely did not go through, or there may have been an error in the
					provided email address. In such a situation, we encourage contacting our customer service
					promptly.
					<br />
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>How can I pay for events?</div>
					<br />
					We use the Visma Pay service, through which you can pay with online banking credentials,
					e-wallet, payment cards (credit/debit), invoice, or installment. The following payment
					methods are available: Osuuspankki, Nordea, Danske Bank, Oma Säästöpankki, Säästöpankki,
					Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Masterpass,
					Visa, Visa Debit, Visa Electron, MasterCard and Debit MasterCard. Additionally, separately
					redeemed vouchers can be used as a payment method.
					<br />
					<br />
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						My payment failed / was interrupted, what can I do?{" "}
					</div>
					<br />
					If you chose the Visma Pay payment method, pop-up windows will appear. Ensure that pop-ups
					are allowed in your browser and complete the payment with your online banking credentials.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						How long in advance do I need to make a payment for it to be confirmed in time for me to
						see the event?
					</div>
					<br />
					The payment system sends a notification to Kotikino immediately when the payment is
					accepted, allowing you to access content immediately after completing the payment.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>Can I pay with a cultural voucher?</div>
					<br />
					We currently do not support payment with cultural vouchers. When does my subscription
					start?
					<br />
					<br />
					• 24h (one-time purchases)
					<br />
					• Ongoing (monthly billed service)
					<br />
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						How can I cancel my recurring subscription?
					</div>
					<br />
					You can cancel your recurring subscription yourself. Log in with the account for the
					subscription you want to terminate. After logging in, go to the Profile from the icon in
					the upper corner and select 'My Subscriptions.' There, you will see all your
					subscriptions. For an active recurring subscription, there is a button to end the
					subscription. For a recurring subscription, you can use the service until the next payment
					date. The cancellation is confirmed when the cancellation button disappears.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>On what devices can I view content?</div>
					<br />
					The Kotikino service works on all the latest browsers on various desktop, mobile, and
					tablet devices.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Does the service work with Chromecast?
					</div>
					<br />
					The video player used by Kotikino also supports Chromecast/Googlecast functionality on
					some devices, but there are differences in devices, and we cannot currently test all of
					them and provide support for their operation.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						Is there a limit to the number of views?
					</div>
					<br />
					We do not limit the number of views for monthly subscriptions, so you can watch as much
					content from the selected package as you want during the validity period of your
					subscription.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						The video is buffering, what can I do?
					</div>
					<br />
					Video buffering can occur for various reasons. Smooth viewing requires only about a 3 Mbps
					internet connection, but especially during exceptional times, internet connections in
					different areas may be under heavy use, causing disruptions. If available, we recommend
					trying another connection, either mobile internet or home Wi-Fi. Also, restarting the
					browser, device, or (when using home broadband) the modem may help.
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						I'm having trouble with login/registration, what can I do?{" "}
					</div>
					<br />
					If you have problems with registration/login, try using another internet browser (e.g.,
					Mozilla Firefox, Chrome, or Windows Explorer).
				</div>

				<div className={classes.item}>
					<div className={`${classes.faqTitle} font-400`}>
						What do I do if I cannot resolve the issue on my own?{" "}
					</div>
					<br />
					You can contact our customer service:
					<a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi</a>
					<br />
					<br />
					We serve from Monday to Friday between 8:00 - 16:00 and aim to respond within 48 hours of
					receiving the message.
				</div>
			</div>
		</div>
	);
};

export default FAQEn;
