import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { getAssetRoute } from "../../../lib/getAssetRoute";
import { selectAssetImage } from "../../../lib/selectAssetImage";
import { getVideoCurrentPrice } from "../../../lib/getVideoCurrentPrice";

import classes from "./AssetItem.module.css";
import AssetItemDetail from "./AssetItemDetail";
import settings from "../../../configs/config_settings.json";
import useWindowDimensions from "../../WindowDimension";

const AssetsItem = ({ asset, pushRoute, useIMG, hidePrice }) => {
	const { organizationId } = settings.organization;

	const history = useHistory();

	const dimension = useWindowDimensions();

	let pushRoutes = getAssetRoute(asset, organizationId);

	let videoCurrentPrice = getVideoCurrentPrice(asset);

	return (
		<div className={classes.container}>
			{/* ASSET IMAGE */}
			<div className={`${classes.assetImage} `}>
				{/* // THERE IS A PROBLEM IN LazyLoadImage ->WHEN USING SEARCH INPUT IT CAUSES FLIECKERING AND RERENDER */}
				{useIMG ? (
					<img
						src={selectAssetImage(asset)}
						style={{
							width: "100%",
							height: "100%",
							borderTopRightRadius: "10px",
							borderTopLeftRadius: "10px",
						}}
						alt="kotikino"
						onClick={() => {
							history.push(pushRoute ? pushRoute : pushRoutes);
						}}
						effect={dimension?.width > 768 ? "blur" : ""}
						loading={dimension?.width > 768 ? "lazy" : ""}
					/>
				) : (
					<div className={classes.imageContainer}>
						<div>
							{dimension?.width > 768 ? (
								<LazyLoadImage
									src={`${selectAssetImage(asset)}`}
									style={{
										width: "100%",
										height: "100%",
										borderTopRightRadius: "10px",
										borderTopLeftRadius: "10px",
									}}
									alt="kotikino-image"
									onClick={() => {
										history.push(pushRoute ? pushRoute : pushRoutes);
									}}
									effect={dimension?.width > 768 ? "blur" : ""} // https://www.goodday.work/t/29VNJs - solution
									loading={dimension?.width > 768 ? "lazy" : ""}
									placeholderSrc="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCACBAFYDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgABAwT/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAREC/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQACA//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIRAxEAPwApjXJ1amNQSSKZRpUaUNZW0agkxAOurR1sqbJo60AmJFMo1tGlMo2q0bUG6h1AOmtlDWypp0lbAhxJqSIGjSoUgbQtbaFoS1DqCdSglE0cKDCiDUlTANDo659NAOnO0+nOilmpiZL0FGYUiJQ4MhwBMpDWoBrn06Vz6aDn0510oWCoE3Ey09WFI3GyBNkKRSFiTBpjTA51z6da51oOdGw7GYqnPEeJkvVhSLCkZKkbjZGpDRp0KYHOhXShWwFjMLFgQ4ixAvS2JsZLY1RBModHQ6agChT6CtIU1ILE1BO7Yky0UakENDpJqAOgqTSYkkGpIF//2Q=="
								/>
							) : (
								<img
									src={`${selectAssetImage(asset)}&width=300`} // FOR SMALL DEVICE LESS WIDTH
									style={{
										width: "100%",
										height: "100%",
										borderTopRightRadius: "10px",
										borderTopLeftRadius: "10px",
									}}
									alt="kotikino"
									onClick={() => {
										history.push(pushRoute ? pushRoute : pushRoutes);
									}}
									loading="eager"
									placeholder="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCACBAFYDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgABAwT/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAREC/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQACA//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIRAxEAPwApjXJ1amNQSSKZRpUaUNZW0agkxAOurR1sqbJo60AmJFMo1tGlMo2q0bUG6h1AOmtlDWypp0lbAhxJqSIGjSoUgbQtbaFoS1DqCdSglE0cKDCiDUlTANDo659NAOnO0+nOilmpiZL0FGYUiJQ4MhwBMpDWoBrn06Vz6aDn0510oWCoE3Ey09WFI3GyBNkKRSFiTBpjTA51z6da51oOdGw7GYqnPEeJkvVhSLCkZKkbjZGpDRp0KYHOhXShWwFjMLFgQ4ixAvS2JsZLY1RBModHQ6agChT6CtIU1ILE1BO7Yky0UakENDpJqAOgqTSYkkGpIF//2Q=="
								/>
							)}
						</div>
						{videoCurrentPrice > 0 && !hidePrice && (
							<div
								style={{
									position: "absolute",
									top: "2rem",
									background: " rgb(29 29 39/0.7",
									padding: "3px 5px",
									left: "0.5rem",
									borderRadius: "0.25rem",
									color: "white",
									textTransform: "capitalize",
									fontSize: "16px",
									lineHeight: "20px",
								}}
							>
								{videoCurrentPrice} €
							</div>
						)}
					</div>
				)}
			</div>

			{/* ASSET DETAILS */}
			<AssetItemDetail asset={asset} />
		</div>
	);
};

export default AssetsItem;
