import React from "react";
import { getImageByKey } from "../../scripts/getImageByKey";
import classes from "./ContactUs.module.css";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
	// Setup translate function
	const { t, i18n } = useTranslation();
	console.log(i18n.languages);

	return (
		<div className={classes.ContactUs}>
			<div className={classes.ContactUsPrimary}>
				<div className={classes.ContactUs_image}>
					<img src={getImageByKey("contactus")} alt="Kotikino" />
				</div>
				<div className={classes.ContactUs_description}>
					<div className={classes.ContactUs_description_Primary}>
						<h4 className="font-700">{t("contactUs.contactUs")}</h4>
						<div className={classes.ContactUs_description_address}>
							<div className={`${classes.ContactUs_description_address_info} font-400`}>
								<div>{"Nordic Content Distribution Oy"}</div>
								<div className={classes.ContactUs_description_address_info_title}>
									{" "}
									{t("contactUs.address")}
								</div>
								<div>Vanha talvitie 11 A,</div>
								<div> 00520 Helsinki</div>
							</div>
						</div>
						{/* <div className={classes.ContactUs_description_phone}>
              <div className={classes.ContactUs_description_address_info_title}>
                {" "}
                {t("contactUs.phone")}
              </div>
              <div>010 341 1000</div>
            </div> */}
						<div className={classes.ContactUs_description_email}>
							<a
								className={classes.ContactUs_description_email_button}
								href="mailto:tuki@kotikino.fi"
								target="_blank"
								rel="noreferrer"
							>
								{t("contactUs.sendEmail")}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
