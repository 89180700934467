import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { useMyContext } from "../../../contexts/StateHolder";
import { reactQueryGetAsset } from "../../../scripts/dataHandlerReactquery";
import { selectAssetImage } from "../../../lib/selectAssetImage";

import settings from "../../../configs/config_settings.json";
import classes from "./LoggedInBox.module.css";
import { useEffect, useState } from "react";

//this package just shows users email address if he is logged in
const LoggedInBox = () => {
	const { organizationId, key } = settings.organization;
	const language = settings.language;

	const { user, userLoggedIn } = useMyContext();

	const { t } = useTranslation();
	const { itemId, itemType } = useParams();
	const location = useLocation();

	const [receiptAssetId, setReceiptAssetId] = useState(null);

	useEffect(() => {
		if (itemType === "receipt") {
			const queryParams = new URLSearchParams(location.search);

			const assetId = queryParams.get("assetId");

			setReceiptAssetId(assetId);
		}
	}, [location.search, itemType]);

	const fetchAsset = (a, b, c, d, e) => {
		return reactQueryGetAsset(a, b, c, d, e); // FIX ME
	};

	const { data, isLoading } = useQuery(
		[`getAssets${itemId}`, itemId, userLoggedIn, receiptAssetId],
		() => fetchAsset(organizationId, language, key, receiptAssetId ? receiptAssetId : itemId, user),
		{
			enabled: itemId * 1 > 0 || receiptAssetId * 1 > 0,
			cacheTime: 300000,
			staleTime: Infinity,
		}
	);

	return (
		!isLoading && (
			<div className={`${classes.container} asset-main-container package-container`}>
				{!isLoading && (
					<div>
						<img
							src={selectAssetImage(data?.data)}
							width={250}
							height={333.33}
							alt={data?.data?.name}
							style={{
								objectFit: "cover",
							}}
						/>
					</div>
				)}
				<div className={classes.loggedInContainer}>
					<div className={classes.loggedInMessage}>
						{data?.data && (
							<div>
								<span>{t("packages.youHaveSelected")}</span>
								<span className={classes.movieTitle}> "{data?.data?.name}"</span>
							</div>
						)}
						<br />
						{t("packages.LoggedInAs")}:<br />
						<span className={classes.loggedInEmail}>{user.eMail}</span>
					</div>
				</div>
			</div>
		)
	);
};

export default LoggedInBox;
