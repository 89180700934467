import { LazyLoadImage } from "react-lazy-load-image-component";
import Translations from "../Translations";

import { getImageByKey } from "../../scripts/getImageByKey";

const NoSearchResult = () => {
  return (
    <div
      className="display-flex-center"
      style={{
        flexDirection: "column",
        gap: "20px",
        margin: "50px",
      }}
    >
      <h2 className="pb-20 pt-20">
        {/* Tulosta ei löytynyt */}
        <Translations text="search.noResult" />
      </h2>
      <LazyLoadImage
        src={getImageByKey("no_result")}
        height={400}
        width={400}
        alt="no_result"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
        }}
      />
    </div>
  );
};
export default NoSearchResult;
