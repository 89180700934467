import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "../Colors";

const SideBarIcon = ({ menuRef, setIsMobileNavOpen, isMobileNavOpen }) => {
  return (
    <button
      onClick={() => {
        setIsMobileNavOpen((prev) => !prev);
      }}
      className={`nav__menu-bar`}
      ref={menuRef}
      style={{
        background: "transparent",
      }}
      aria-label="Open Navigation menu"
    >
      <FontAwesomeIcon
        icon={!isMobileNavOpen ? faBarsStaggered : faXmark}
        color={Colors.brand}
        aria-hidden={false}
      />
    </button>
  );
};

export default SideBarIcon;
