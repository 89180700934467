import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { createToken } from "../scripts/utils";
import { reactQuerygetSubCategories } from "../scripts/dataHandlerReactquery";
import { useMyContext } from "../contexts/StateHolder";

import SerieEpisodes from "../components/Series/SerieDetail/SerieEpisodes/SerieEpisodes";
import SerieSkeletonPlaceholder from "../components/Common/SketetonPlaceholder/SerieSkeletonPlaceholder";
import SeriesParent from "../components/Series/SeriesParent/SeriesParent";
import settings from "../configs/config_settings.json";

const Series = () => {
	const { seriesId, seasonId } = useParams();
	const { user } = useMyContext();

	const fetchSubCategories = () => {
		const { organizationId, key } = settings.organization;
		const { language } = settings.language;

		const token = createToken(organizationId, seriesId, key);

		return reactQuerygetSubCategories(organizationId, token, seriesId, language, user);
	};

	const { data, isLoading } = useQuery(`seriesCategories${seriesId}`, fetchSubCategories, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
		enabled: seriesId * 1 > 0,
	});

	useEffect(() => {
		if (!seasonId) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [seasonId]);

	return !isLoading ? (
		<>
			<SeriesParent asset={data?.data?.data?.[0]} />

			<div className="asset-main-container">
				<SerieEpisodes
					asset={data?.data?.data?.[0]}
					serieCategories={data?.data?.data?.[0]?.groupItems}
				/>
			</div>
		</>
	) : (
		<SerieSkeletonPlaceholder />
	);
};

export default Series;
