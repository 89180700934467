import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";

import classes from "./PrivacyTerms.module.css";

import { useMyContext } from "../../contexts/StateHolder";
import { ROUTES } from "../../constants/route";

const PrivacyTermsEn = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const { languageCode } = useMyContext();

	useEffect(() => {
		if (languageCode?.includes("fi")) {
			return history.push(ROUTES.PRIVACY_POLICY);
		}
	}, [languageCode, history]);

	return (
		<div className="container">
			<div className={`${classes.privacyTermsContainer} font-300`}>
				<div className={`${classes.privacyTermsTitle} font-600`}>{t("privacyTerms.title")}:</div>
				Effective from March 1, 2022.
				<br />
				<br />
				Kotikino respects and protects the privacy of individuals visiting its website, customers,
				and individuals involved in stakeholder collaboration. On this page, you will find our
				privacy principles, terms of use, and register descriptions.
				<div className={classes.item}>
					<div className={`${classes.privacyTermsTitle} font-400`}>Our Privacy Principles</div>
					<br />
					We collect and process only necessary information and remove outdated data. We use
					personal information in communication, customer service, and service delivery. Personal
					information is processed centrally at the office of Nordic Content Distribution Oy in
					Helsinki.
					<br />
					<br />
					Personal information is also used for authorized marketing and advertising. We do not, by
					default, disclose personal information outside the company. For more detailed information
					about the processing and use of personal information and your rights, please refer to the
					personal data register descriptions below.
					<br />
				</div>
				<div className={classes.item}>
					<div className={`${classes.privacyTermsTitle} font-400`}>Cookies</div>
					<br />
					When visiting the Kotikino website, your web browser automatically stores cookies on your
					device's hard drive. A cookie may contain text, numbers, dates, location information, and
					other data, but it does not store any personal information. A cookie is not an
					application, and it cannot introduce viruses or other malware to your device. Cookies do
					not harm your device in any way.
					<br />
					<br />
					Cookies can be used to determine visitor numbers, save choices made on the site (e.g.,
					language settings), track how the site is used (click paths), and target and manage
					advertising (e.g., avoid showing the same ads repeatedly). However, we do not track
					individual visitor data; instead, we aggregate visitor information based on behavior and
					geographical location.
					<br />
					<br />
					Through cookies, we aim to improve the user experience on our site and provide
					advertisements that interest users. For example, you may see Kotikino advertising when
					visiting other websites. This is possible through cookies.
					<br />
					<br />
					If a visitor to Kotikino's site does not want the above information collected through
					cookies, they can prohibit the use of cookies from the browser settings.
				</div>
				<div className={classes.item}>
					<div className={`${classes.privacyTermsTitle} font-400`}>
						Marketing Register Statement
					</div>
					<br />
					Combined Register Statement and Information Document <br />
					<br />
					<div className={classes.bolded}>1. Data Controller</div>
					<br />
					Nordic Content Distribution Oy Vanha talvitie 11 A 00520 Helsinki Phone: 010 341 1000
					Email: <a href={"mailto:tuki@kotikino.fi"}>tuki@kotikino.fi</a>
					<br />
					<br />
					<div className={classes.bolded}>
						2. Contact Details for Matters Related to Processing Personal Data
					</div>
					<br />
					Vanha talvitie 11 A
					<br />
					00520 Helsinki
					<br />
					Email: <a href={"mailto:tuki@kotikino.fi"}>tuki@kotikino.fi</a>
					<br />
					<br />
					<div className={classes.bolded}>3. Register Name</div>
					<br />
					Kotikino's information and marketing register. <br />
					<br />
					<div className={classes.bolded}>
						4. Legal Basis and Purpose of Personal Data Processing
					</div>
					<br />
					General conditions of Section 8 of the Personal Data Act and Chapter 2 of the EU General
					Data Protection Regulation. The purpose of processing personal data is to communicate
					information about the Kotikino service and new content. The processing of necessary
					personal information is a prerequisite for achieving this purpose. The legal basis for
					processing personal data is consent. The typical uses of the data include service
					development, direct marketing, donation requests, event invitations, digital marketing and
					distance selling, customer and opinion surveys.
					<br />
					<br />
					Kotikino only retains data necessary for the operation of the service and the purposes of
					data usage. Data that has become unnecessary and data for which there is no longer a legal
					basis for processing is anonymized or securely destroyed.
					<br />
					<br />
					Personal information may be processed by third parties in accordance with applicable law,
					including marketing, distance selling, and market and opinion research. Third parties may
					be advertising agencies, printing houses, or similar partners supporting the fulfillment
					of the register's purpose. Kotikino has appropriate agreements with these third parties
					for the processing of personal data.
					<br />
					<br />
					<div className={classes.bolded}> 5. Data Content of the Register</div>
					<br />
					The marketing register may process the following information: <br />
					<br />
					· Name
					<br />
					· Contact information (email)
					<br />
					· Source of address (how the address came into the register)
					<br />
					· History data of contacts
					<br />
					<br />
					<div className={classes.bolded}>6. Regular Data Sources</div>
					<br />
					Personal data for the marketing register is collected through various channels from
					individuals who have provided their contact information to Kotikino (e.g., newsletter
					subscription, completion of a competition/contest card).
					<br />
					<br />
					Providing necessary information is a prerequisite for receiving marketing.
					<br />
					<br />
					The register does not perform automatic updates. <br />
					<br />
					<div className={classes.bolded}>7. Regular Data Sources</div>
					<br />
					The company does not disclose the information of individuals in its marketing and
					stakeholder register to third parties for purposes other than those mentioned in this
					register description, to fulfill its statutory duties, or to exercise its rights without
					the explicit and informed consent of the data subject.
					<br />
					<br />
					Personal data of registrants may be processed by third parties participating in the
					implementation of Kotikino's marketing and remote sales, such as a mailing house or for
					possible customer or opinion research. Kotikino has appropriate agreements with such third
					parties for the processing of personal data.
					<br />
					<br />
					<div className={classes.bolded}>
						8. Transfer of Data Outside the EU or the European Economic Area
					</div>
					<br />
					Kotikino does not transfer data from its donor and stakeholder register outside the EU or
					the EEA.
					<br />
					<br />
					<div className={classes.bolded}>9. Principles of Register Protection</div>
					<br />
					The marketing register is maintained in electronic form in the Icareus Suite system and,
					to a small extent, on the network drive in Excel files. The databases and software of the
					systems are on the service provider's servers on the Internet, protected by user and
					password security from workstations.
					<br />
					<br />
					Register use requires a personal username and password, which the system forces to change
					periodically. Access rights end when a person moves away from the tasks for which they
					have been granted access. The username is also locked at the same time.
					<br />
					<br />
					Personal data is processed confidentially. The data controller has implemented access
					control to its premises.
					<br />
					<br />
					<div className={classes.bolded}>10. Right of Inspection and Correction of Data </div>
					<br />
					The data subject has the right to check the information about themselves in Kotikino's
					register and request the correction of incorrect information. The data subject can request
					the right of inspection personally or in writing to the contact details mentioned in
					section 2. The right of inspection will be implemented within one week of the request
					being made. The register does not include information for which the right of inspection
					cannot be implemented. The data subject can request the correction of information at any
					time from the person responsible for register matters.
					<br />
					<br />
					Information may be corrected or supplemented by the data controller or at the request of
					the data subject. The data subject has the right to demand the restriction of the
					processing of data and object to the processing of data. The data subject in Kotikino's
					register has the right to prohibit the use of their information for direct marketing or
					similar purposes, change their consent regarding publication, and, in general, avail
					themselves of the rights protected by data protection legislation. If a person does not
					wish to receive any mail or contacts from Kotikino, they can prohibit it by contacting the
					address mentioned in section 2.
					<br />
					<br />
					Kotikino: removes unnecessary personal data from the register upon the request of the data
					subject.
					<br />
					<br />
					The data subject has the right to file a complaint with the data protection authority.
					<br />
					<br />
					<div className={classes.bolded}>11. Data Retention</div>
					<br />
					Data is retained until further notice, as long as it is necessary. Data that has become
					unnecessary and data for which there is no longer any other basis for processing will be
					destroyed securely.
				</div>
			</div>
		</div>
	);
};

export default PrivacyTermsEn;
