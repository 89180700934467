import ContentRatings from "../../Assets/VideoDetails/ContentRatings";
import AssetItemInfo from "../../Assets/AssetsItem/AssetItemInfo";
import useWindowDimensions from "../../WindowDimension";

import classes from "./SerieItemInfo.module.css";

const SerieItemInfo = ({ asset }) => {
	const { width } = useWindowDimensions();
	return (
		<div className={classes.container}>
			<div>
				{/* TITILE */}

				<div className={classes.title}>{asset?.name || asset?.title}</div>
				{/* // SOME INFO -DURATION &YEAR */}
				<AssetItemInfo asset={asset} showDuration extraClassname />
			</div>
			{/* CONTENT RATINGS */}

			<ContentRatings size={width > 1920 ? "40px" : "35px"} asset={asset} />
		</div>
	);
};

export default SerieItemInfo;
