import { useQuery } from "react-query";

import SeriesBackgroundBanner from "./SeriesBackgroundBanner/SeriesBackgroundBanner";
import SeriesParentDescription from "./SeriesParentDescription/SeriesParentDescription";
import SerieSkeletonPlaceholder from "../../Common/SketetonPlaceholder/SerieSkeletonPlaceholder";

import settings from "../../../configs/config_settings.json";

import { getImageByKey } from "../../../scripts/getImageByKey";
import { createAssetIdToken } from "../../../scripts/utils";
import { useMyContext } from "../../../contexts/StateHolder";
import { reactQueryfetchEpisodes } from "../../../scripts/dataHandlerReactquery";

import classes from "./SerieParent.module.css";

const SerieCoverImage = ({ asset, coverImage }) => {
	return (
		<div className={classes.imageContainer}>
			{asset?.serie?.coverImageSmall ||
				(asset?.coverImageSmall && (
					<img
						className={classes.coverImage}
						src={coverImage ? coverImage : getImageByKey("fallbackImage")}
						alt="coverImage"
					/>
				))}
		</div>
	);
};

const SeriesParent = ({ asset }) => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;

	const { user, userLoggedIn } = useMyContext();

	let coverImage;
	if (asset?.coverImageSmall || asset?.serie?.coverImageSmall) {
		coverImage = asset.isSerie
			? asset?.serie?.coverImageSmall || asset.coverImageSmall
			: asset.coverImageSmall;
	}

	const fetchEpisodes = () => {
		const token = createAssetIdToken(organizationId, asset?.groupItems?.[0]?.id, null, key);

		const res = reactQueryfetchEpisodes(
			organizationId,
			asset?.groupItems?.[0]?.id,
			token,

			language,

			user
		);
		return res;
	};

	const { data: firstEpisode, isLoading } = useQuery(
		[`getEpisodes${asset?.groupItems?.[0]?.id}`, asset?.groupItems?.[0]?.id, userLoggedIn],
		fetchEpisodes,
		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,

			// when asset?.groupItems?.[0]?.id changes this will refetch the data
			enabled: asset?.groupItems?.[0]?.id * 1 > 0,
			select: (res) => res?.data?.assets?.sort((a, b) => a.episodeNumber - b.episodeNumber)?.[0],
		}
	);

	if (isLoading) return <SerieSkeletonPlaceholder />;

	return (
		<>
			<div className={classes.container}>
				{/* BACKGROUND IMAGE */}
				<SeriesBackgroundBanner asset={asset} />

				<div className={classes.secondaryContainer}>
					<SerieCoverImage asset={asset} coverImage={coverImage} />
					<div className={classes.textContainer}>
						<SeriesParentDescription asset={asset} firstEpisode={firstEpisode} />
					</div>
				</div>
			</div>
		</>
	);
};

export default SeriesParent;
