import { useTranslation } from "react-i18next";
import classes from "./GuidesBar.module.css";
import { HashLink } from "react-router-hash-link";
import { getImageByKey } from "../../../scripts/getImageByKey";

const GuidesBar = (props) => {
  const { t } = useTranslation();

  const { routes } = props;

  return (
    <div className={classes.GuidesBar}>
      <HashLink
        to={`${routes.tos}#guidesDesktop`}
        className={classes.GuidesBarItemContainer}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesDesktop")}
          title={t("guidesBar.Desktop")}
          alt="Desktop"
        />
        <div className="font-400">{t("guidesBar.Desktop")}</div>
        <div className={`${classes.GuidesBarReadMore} font-300`}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>

      <HashLink
        className={classes.GuidesBarItemContainer}
        to={`${routes.tos}#guidesDesktop`}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesMobile")}
          title={t("guidesBar.Mobile")}
          alt="Mobile"
          style={props?.styles?.navBar}
        />
        <div className="font-400">{t("guidesBar.Mobile")}</div>
        <div className={`${classes.GuidesBarReadMore} font-300`}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>

      <HashLink
        className={classes.GuidesBarItemContainer}
        to={`${routes.tos}#guidesDesktop`}
      >
        <img
          className={classes.GuidesBarImage}
          src={getImageByKey("guidesChromecast")}
          title={t("guidesBar.Chromecast")}
          alt="Chromecast"
          style={props?.styles?.navBar}
        />
        <div className="font-400">{t("guidesBar.Chromecast")}</div>
        <div className={`${classes.GuidesBarReadMore} font-300`}>
          {t("guidesBar.Read more")}
        </div>
      </HashLink>
    </div>
  );
};

export default GuidesBar;
