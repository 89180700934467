import classes from "./AssetItemDetail.module.css";

import AssetItemCategories from "./AssetItemCategories";
import AssetItemInfo from "./AssetItemInfo";
import AssetItemTitle from "./AssetItemTitle";
import Colors from "../../Colors";

const AssetItemDetail = ({ asset }) => {
	return (
		<div className={classes.container}>
			<AssetItemTitle
				asset={asset}
				style={{
					color: Colors.brand,
				}}
			/>
			<AssetItemCategories asset={asset} />
			<AssetItemInfo asset={asset} />
		</div>
	);
};

export default AssetItemDetail;
