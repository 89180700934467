// THIRD PARTY IMPORT

import classes from "./GridItem.module.css";

import AssetsItem from "../AssetsItem/AssetsItem";

const GridItem = ({ asset, useIMG, pushRoute }) => {
	return (
		<div className={classes.container}>
			<AssetsItem asset={asset} useIMG={useIMG} pushRoute={pushRoute} />
		</div>
	);
};

export default GridItem;
