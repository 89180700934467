import { ROUTES } from "../../../../constants/route";
import classes from "./FolderNames.module.css";
import { useHistory } from "react-router-dom";

import settings from "../../../../configs/config_settings.json";

// Renders contentRatings of chosen item
const FolderNames = (props) => {
  const history = useHistory();
  const { organizationId } = settings?.organization;

  const renderItem = props?.asset;

  const clickHandler = (el) => {
    if (renderItem.isSerie) {
      return;
    }
    history.push(`${ROUTES.CATEGORIES}/${el?.name}/${organizationId}/${el.id}`);
  };

  const RenderFolderNames = () => {
    return renderItem.folders.map((cat, i) => {
      return (
        <div
          className={`${classes.folderName} font-400`}
          key={cat.id}
          onClick={() => clickHandler(cat)}
        >
          {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
          {i < renderItem?.folders?.length - 1 ? " ," : null}
        </div>
      );
    });
  };

  return <div className={classes.folderNamesList}>{<RenderFolderNames />}</div>;
};

export default FolderNames;
