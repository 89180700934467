import classes from "../Form.module.css";

// THIRD PARTY IMPORT

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import Translations from "../../Translations";
import { Input } from "../../Common/Input/Input";
import {
  VALIDATOR_PASSWORDMATCH,
  VALIDATOR_REQUIRE,
} from "../../Common/Validation/Validator";
import Button from "../../Common/Button/Button";

const ChangePasswordForm = ({
  isLoading,
  formSubmitHandler,
  state,
  InputHandler,
  includeOldPassword,
}) => {
  const formhandle = (e) => {
    e.preventDefault();

    formSubmitHandler(state);
  };

  return (
    <div className={`${classes.profile_container} `}>
      <form
        className={`${classes.formContainer}  pb-20  `}
        onSubmit={formhandle}
      >
        <h2 className={`${classes.formHeaderSignUp} font-700 pb-20  `}>
          <Translations text="form.password.changePassword" />
        </h2>

        {includeOldPassword && (
          <Input
            id="OLD_PASSWORD"
            label={<Translations text="form.password.oldPassword" />}
            placeholder={<Translations text="form.password.enterOldPassword" />}
            errorText={
              <Translations text="form.password.oldPasswordRequired" />
            }
            type="Password"
            element="input"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={InputHandler}
            iconName={<FontAwesomeIcon icon={faLock} />}
          />
        )}

        <Input
          id="PASSWORD"
          label={<Translations text="form.password.newPassword" />}
          placeholder={<Translations text="form.password.enterNewPassword" />}
          errorText={<Translations text="form.password.newPasswordRequired" />}
          type="Password"
          element="input"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={InputHandler}
          iconName={<FontAwesomeIcon icon={faLock} />}
        />
        <Input
          id="PASSWORD_CONFIRM"
          label={<Translations text="form.password.passwordConfirm" />}
          placeholder={<Translations text="form.password.enterPasswordAgain" />}
          errorText={<Translations text="form.password.passwordMismatch" />}
          type="Password"
          element="input"
          validators={[
            VALIDATOR_PASSWORDMATCH(
              state.inputs.PASSWORD.value,
              state.inputs.PASSWORD_CONFIRM.value
            ),
          ]}
          onInput={InputHandler}
          iconName={<FontAwesomeIcon icon={faLock} />}
        />

        <Button
          className={`${classes.loginFormButton} width-100`}
          disabled={!state.isValid || isLoading}
          formButton
        >
          {isLoading ? (
            <Translations text="shared.loading" />
          ) : (
            <Translations text="form.password.changePassword" />
          )}
        </Button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
