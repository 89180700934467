import settings from "../../../configs/config_settings.json";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { createAssetIdToken } from "../../../scripts/utils";
import { reactQuerygetTrendingAssets } from "../../../scripts/dataHandlerReactquery";
import AssetsCustomCarousel, {
	AssetsCustomCarouselSlickSettings,
} from "../../Common/CustomCarousel/AssetsCustomCarousel";
import Header from "../../Common/Header/Header";
import AssetsItem from "../AssetsItem/AssetsItem";
import { getUniqueSerieAndAssets } from "../../../lib/getUniqueSerieAndAssets";

const TrendingAssets = (props) => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;
	const { t } = useTranslation();
	let user = {};
	const { groupItemId, id } = props?.settings;
	const { title } = props;

	const fetchTrendingAssets = () => {
		const token = createAssetIdToken(organizationId, "", null, key); // DONT PASS ANY GROUPITEMIDS HERE
		return reactQuerygetTrendingAssets(
			organizationId,
			token,
			language,
			groupItemId,
			10, // limit
			365, // 1 year

			user
		);
	};

	const { data: trendingAssets, isLoading } = useQuery(
		`${id}trendingAssets${groupItemId}`,
		fetchTrendingAssets,
		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
			select: (res) => getUniqueSerieAndAssets(res.data.assets.map((el) => el.asset)),
		}
	);
	// console.log(trendingAssets);
	const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];

	if (isLoading) {
		return (
			<>
				<Header title={t(title)} viewAll={false} />
				<AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
					{skeletonItem.map((el2) => (
						<SkeletonTheme key={el2}>
							<p>
								<Skeleton
									key={el2}
									width={"100%"}
									height={"325px"}
									style={{
										borderRadius: "10px",
									}}
								/>
							</p>
						</SkeletonTheme>
					))}
				</AssetsCustomCarousel>
			</>
		);
	}

	if (!isLoading && trendingAssets && trendingAssets?.length > 0) {
		return (
			<div>
				<Header title={t(title)} viewAll={false} />

				<AssetsCustomCarousel>
					{trendingAssets?.map((el) => (
						<AssetsItem asset={el} key={el?.id} />
					))}
				</AssetsCustomCarousel>
			</div>
		);
	}
};

export default TrendingAssets;
