// THIRD PARTY IMPORT

import { useTranslation } from "react-i18next";

// REACT AND NEXT IMPORT
import React, { useState } from "react";
import { useForm } from "../../../hooks/form-hook";
import { resetPasswordChange } from "../../../scripts/dataHandlers";
import CustomToast from "../../Common/CustomToast/CustomToast";
import ChangePasswordForm from "../../Form/Password/ChangePasswordForm";
import { ROUTES } from "../../../constants/route";
import { useHistory } from "react-router-dom";
const PasswordValidToken = ({ tokenFromEmail }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [state, InputHandler] = useForm(
    {
      PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORD_CONFIRM: {
        value: "",
        isValid: false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const formSubmitHandler = async () => {
    setIsLoading(true);
    try {
      const response = await resetPasswordChange(
        state.inputs.PASSWORD.value,
        state.inputs.PASSWORD_CONFIRM.value,
        tokenFromEmail
      );
      console.log("res", response);
      response?.data?.status === "ok"
        ? CustomToast(t("user.passwordChangedSuccess"))
        : CustomToast(
            response?.data?.message || t("shared.errorGeneric"),
            "error"
          );

      if (response?.data?.status === "ok") {
        // we send them to main page
        setIsLoading(false);
        setTimeout(() => {
          // they cant come back
          history.replace(ROUTES.HOME);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      CustomToast(t("shared.errorGeneric"), "error");
    }
  };
  return (
    <ChangePasswordForm
      isLoading={isLoading}
      formSubmitHandler={formSubmitHandler}
      state={state}
      InputHandler={InputHandler}
    />
  );
};

export default PasswordValidToken;
