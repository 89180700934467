import classes from "./Share.module.css";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import settings from "../../../../configs/config_settings.json";
import HelmetMetaData from "../../../HelmetMetaData";
import { getImageByKey } from "../../../../scripts/getImageByKey";

const Share = (props) => {
	const { organization } = settings;
	return (
		<>
			<div className={`${classes.details_share}`}>
				<HelmetMetaData
					image={
						"https://images.unsplash.com/photo-1599420186946-7b6fb4e297f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80"
					}
					title={props.item?.title || props.item?.name}
					description={props.item?.ingress || props.item?.description}
				/>

				<FacebookShareButton
					url={window.location.href}
					quote={`${props.item?.serie?.title || props.item?.title || props.item?.name}\n\n
        ${props.item?.serie?.description || props.item?.ingress || props.item?.description}
        `}
					hashtag={`#${settings.organization.name}`}
					image={
						props.item?.bannerImageSmall ||
						props.item?.coverImageSmall ||
						props.chosenImage?.thumbnailSmall
					}
					className={classes.socialMediaButton}
				>
					<img
						src={getImageByKey("facebook")}
						className={`${classes.shareBTN} `}
						alt="facebook share icon"
						width={35}
					/>{" "}
				</FacebookShareButton>

				<TwitterShareButton
					url={window.location.href}
					title={`${props.item?.title || props.item?.name}
          \n`}
					image={
						props.item?.bannerImageSmall ||
						props.item?.coverImageSmall ||
						props.chosenImage?.thumbnailSmall
					}
					hashtags={[...(organization.name ? [organization.name] : [])]}
					//via='Somenamehere, shows up like @something in end of tweet'
					className={classes.socialMediaButton}
				>
					<img
						src={getImageByKey("twitter")}
						className={`${classes.shareBTN} `}
						alt="twiiter share icon"
						width={35}
					/>
				</TwitterShareButton>
			</div>
		</>
	);
};

export default Share;
