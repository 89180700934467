import { getThumbnailImage } from "../../../lib/selectAssetImage";
import SerieItemDetail from "../SerieItemDetail/SerieItemDetail";
import classes from "./SerieItem.module.css";

const SerieItem = ({ asset, onClick }) => {
  return (
    <>
      <div className={classes.container}>
        {/* ASSET IMAGE */}

        <div className={classes.imageContainer}>
          <img
            src={getThumbnailImage(asset)}
            className={classes.serieImage}
            alt="some-text"
            onClick={() => onClick()}
            loading="lazy"
          />
        </div>

        {/*  DETAILS */}

        <SerieItemDetail asset={asset} />
      </div>
    </>
  );
};

export default SerieItem;
