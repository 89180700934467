import React from "react";
import classes from "./SerieParentDescription.module.css";
import Share from "../../../Assets/VideoDetails/Share/Share";
import InfoData from "../../../Assets/VideoDetails/InfoData/InfoData";

const Title = ({ asset }) => {
	return (
		<div className={classes.titleContainer}>
			<div className={`${classes.title} font-600 `}>{asset?.title || asset?.name}</div>

			{asset?.groupItems?.length ? (
				<div className={classes.seasons}>
					{asset?.groupItems?.length} {asset?.groupItems?.length === 1 ? "Season" : "Seasons"}
				</div>
			) : null}
		</div>
	);
};

const SeriesParentDescription = ({ asset, firstEpisode }) => {
	const updatedFirstEpisode = { ...firstEpisode };
	updatedFirstEpisode.duration = 0; // cause we dont want show the duration of fist episode in series parent
	return (
		<div className={`${classes.container} asset-main-container`}>
			<Title asset={asset} />

			<div
				dangerouslySetInnerHTML={{
					__html: asset?.description,
				}}
				className={classes.description}
			/>
			<InfoData asset={updatedFirstEpisode} />

			<Share item={asset} />
		</div>
	);
};

export default SeriesParentDescription;
