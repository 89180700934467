export const getUniqueSerieAndAssets = (assets) => {
  // filtering assets which includes isSeries or there is seriesId
  let allSerieAsset = assets?.filter((el) => el?.seriesId || el.isSerie);

  // remaining assets from assets after filtering all series stuff
  let remainingData = assets?.filter((el) => !allSerieAsset?.includes(el));

  // getting unique seriesid

  let uniqueArray = allSerieAsset.filter((item, index, self) => {
    return (
      !index ||
      !self
        .slice(0, index)
        .some((prevItem) => prevItem.seriesId === item.seriesId)
    );
  });

  // we have to change the id of the item if it belongs to a serie ->serie episodes id needs to be changed to main serie id
  uniqueArray = uniqueArray.map((el) => {
    if (el?.series?.length > 0) {
      let oldEl = { ...el };

      oldEl.id = el?.series?.[0]?.id;

      return oldEl;
    } else {
      return el;
    }
  });

  console.log("uniqueArray", uniqueArray);
  return [...uniqueArray, ...remainingData];
};
