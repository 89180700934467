import React from "react";
import { useHistory } from "react-router-dom";

import classes from "./PromoItem.module.css";
import PromoBannerCustomCarousel from "../Common/CustomCarousel/PromoCustomCarousel";

import settings from "../../configs/config_settings.json";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { getAssetRoute } from "../../lib/getAssetRoute";

const PromoItem = ({ assets }) => {
	const { organizationId } = settings.organization;
	const history = useHistory();

	return (
		assets?.length > 0 && (
			<PromoBannerCustomCarousel>
				{assets.map((el) => {
					let pushRoute = getAssetRoute(el, organizationId);

					return (
						<div className={classes.promoContainer} key={el?.id}>
							<LazyLoadImage
								src={
									el?.thumbnailSmall ||
									el?.thumbnailMedium ||
									el?.thumbnailLarge ||
									el?.bannerImageSmall ||
									el?.bannerImageMedium ||
									el?.bannerImageLarge
								}
								key={el?.id}
								className={`${classes.promoImage}`}
								alt={"home-banner"}
								onClick={() => {
									history.push(pushRoute);
								}}
								placeholderSrc="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCACBAFYDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAgABAwT/xAAXEAEBAQEAAAAAAAAAAAAAAAAAAREC/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQACA//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIRAxEAPwApjXJ1amNQSSKZRpUaUNZW0agkxAOurR1sqbJo60AmJFMo1tGlMo2q0bUG6h1AOmtlDWypp0lbAhxJqSIGjSoUgbQtbaFoS1DqCdSglE0cKDCiDUlTANDo659NAOnO0+nOilmpiZL0FGYUiJQ4MhwBMpDWoBrn06Vz6aDn0510oWCoE3Ey09WFI3GyBNkKRSFiTBpjTA51z6da51oOdGw7GYqnPEeJkvVhSLCkZKkbjZGpDRp0KYHOhXShWwFjMLFgQ4ixAvS2JsZLY1RBModHQ6agChT6CtIU1ILE1BO7Yky0UakENDpJqAOgqTSYkkGpIF//2Q=="
								effect="blur"
								loading="lazy"
							/>
						</div>
					);
				})}
			</PromoBannerCustomCarousel>
		)
	);
};

export default PromoItem;
