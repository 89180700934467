import { Link } from "react-router-dom";
import classes from "./PromoCardItem.module.css";

export const PromoCardItem = ({ item }) => {
	return (
		<Link className={classes.card} to={item?.buttonLink}>
			<div className={classes.container}>
				<div>
					<h1 className={classes.title} style={{ fontWeight: "normal" }}>
						{item?.title}
					</h1>

					{/* <h1
						style={{
							marginBottom: "12px",
							marginTop: "12px",
							fontSize: "2.4em",
						}}
					>
						6,99 €/kk
					</h1>
					<h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
						}}
					>
						Elokuvat js sarjat
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							display: "flex",
							flexDirection: "column",
							gap: "12px",
							fontSize: "16px",
						}}
					>
						<p style={{}}>Jatkuvasti kasvava valikoima huippuelokuvia ja -sarjoja.</p>
						<p>Ei pitkää sitoutumista, keskeytä koska tahansa.</p>
					</p> */}

					{/* <h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
							marginTop: "30px",
						}}
					>
						Elokuvat js sarjat
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							fontSize: "16px",
						}}
					>
						<p style={{}}>
							Vuokraa elokuvia ja sarjoja kertamaksulla. Vuokraelokuva on katsottavissa 48h ajan.
						</p>
					</p>
					<h3
						style={{
							marginBottom: "5px",
							fontSize: "1.4em",
							marginTop: "20px",
						}}
					>
						Aina edulliset hinnat
					</h3>

					<p
						style={{
							color: "rgba(210,198,198)",
							fontSize: "16px",
						}}
					>
						Alk. 2,88 &euro
					</p> */}

					{item?.content}
				</div>
				<button className={classes.button}>{item?.buttonText}</button>
			</div>
		</Link>
	);
};
