import { validatePasswordResetToken } from "../../../scripts/dataHandlers";
import PasswordInvalidToken from "./PasswordInvalidToken";
import PasswordValidToken from "./PasswordValidToken";

// NEXT AND REACT IMPORT
import React, { useEffect, useState } from "react";

const PasswordToken = ({ tokenFromEmail }) => {
  // we first need to check whether the token is valid or not

  const [tokenFromEmailvalid, setTokenFromEmailvalid] = useState(false);
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const runFunction = async () => {
      const res = await validatePasswordResetToken(tokenFromEmail);

      if (res?.data?.status === "ok") {
        // means token is valid and they can change the pasword
        setTokenFromEmailvalid(true);
      } else {
        // cant change need to ask for reset password again
        setTokenFromEmailvalid(false);
      }
      setIsTokenChecked(true);
    };

    !isTokenChecked && runFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenChecked]);

  console.log("setIsTokenChecked", isTokenChecked);
  return !isTokenChecked ? (
    <>loading</>
  ) : tokenFromEmailvalid ? (
    <PasswordValidToken tokenFromEmail={tokenFromEmail} />
  ) : (
    <PasswordInvalidToken />
  );
};

export default PasswordToken;
