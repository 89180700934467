import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import classes from "./SerieItemDetail.module.css";
import Colors from "../../Colors";
import useWindowDimensions from "../../WindowDimension";

const SerieItemDetailDescription = ({ description }) => {
  let description1 = description;

  const [desc, setDesc] = useState("");
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const { width } = useWindowDimensions();

  const [descriptionSize, setDescriptionSize] = useState(
    width > 1920 ? 400 : 200
  );

  useEffect(() => {
    setDescriptionSize(width > 1920 ? 600 : 200);
  }, [width]);

  useEffect(() => {
    if (description1?.length > descriptionSize) {
      setDesc(`${description1?.slice(0, descriptionSize)}...`);
      setHasMore(true);
    } else {
      setDesc(description1);
    }
  }, [description1, descriptionSize]);

  const descriptionOpenhandler = useCallback(() => {
    setIsDescriptionOpen(true);
    setDesc(description1);
  }, [description1]);
  const descriptionClosehandler = useCallback(() => {
    setIsDescriptionOpen(false);

    setDesc(`${description1?.slice(0, descriptionSize)}...`);
  }, [description1, descriptionSize]);
  return (
    <div className={classes.descriptionContainer}>
      <div
        className={`${classes.packageDescription}  `}
        dangerouslySetInnerHTML={{ __html: desc }}
      />

      {hasMore &&
        (isDescriptionOpen ? (
          <FontAwesomeIcon
            icon={faChevronUp}
            onClick={descriptionClosehandler}
            className={classes.descriptionIcon}
            aria-hidden={false}
            color={Colors.error}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={descriptionOpenhandler}
            className={classes.descriptionIcon}
            aria-hidden={false}
            color={Colors.disabled}
          />
        ))}
    </div>
  );
};

export default SerieItemDetailDescription;
