//import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../contexts/StateHolder";
import { convertCurrency } from "../../../scripts/utils";

import classes from "./SelectedPackage.module.css";

//just prints selected PAckage data. It is used in both receipt and paymentMethods views
const SelectedPackage = ({ successPayment }) => {
	const { selectedPackage } = useMyContext();

	const { t } = useTranslation();

	if (Object.keys(selectedPackage).length === 0) {
		return null;
	} else {
		const taxPerc = selectedPackage.taxRate;
		const taxType = selectedPackage.taxType;

		return (
			<div>
				<div
					className={successPayment ? "font-400" : "font-600"}
					style={{
						margin: "10px 0",
					}}
				>
					{successPayment ? t("packages.ReceiptText") : t("packages.PackagePayment")}
				</div>

				<div className={classes.selectedPackage}>
					<div className={classes.selectedPackageRow}>
						<div className={`${classes.selectedPackageName} font-400`}>{selectedPackage.name}</div>
						<div className={classes.priceContainer}>
							<div>
								{selectedPackage.price} {convertCurrency(selectedPackage.currency)}
							</div>
							<div>{t("packages.IncludeVat", { taxPerc, taxType })}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default SelectedPackage;
