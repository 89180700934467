// REACT AND NEXT
import React, { useState } from "react";

import settings from "../configs/config_settings.json";

// THIRD PARTY
import { useTranslation } from "react-i18next";
import { useForm } from "../hooks/form-hook";
import { useHistory } from "react-router-dom";
import { changePassword } from "../scripts/dataHandlers";
import { useMyContext } from "../contexts/StateHolder";
import CustomToast from "../components/Common/CustomToast/CustomToast";
import { ROUTES } from "../constants/route";
import ChangePasswordForm from "../components/Form/Password/ChangePasswordForm";
import SidebarContainer from "../components/navigation/profile/SidebarContainer";

const ChangePassword = () => {
  const { t } = useTranslation();
  const { organizationId, key } = settings.organization;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { user } = useMyContext();
  const [state, InputHandler] = useForm(
    {
      OLD_PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORD: {
        value: "",
        isValid: false,
      },
      PASSWORD_CONFIRM: {
        value: "",
        isValid: false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );

  const formSubmitHandler = async () => {
    // Do the signUp/login process
    setIsLoading(true);

    try {
      const response = await changePassword(
        organizationId,
        key,
        user?.userId,

        state.inputs.PASSWORD.value,
        state.inputs.PASSWORD_CONFIRM.value,
        state.inputs.OLD_PASSWORD.value,
        user?.userToken
      );

      console.log(response);

      response?.data?.status === "ok"
        ? CustomToast(t("user.passwordChangedSuccess"))
        : CustomToast(
            response?.data?.message || t("shared.errorGeneric"),
            "error"
          );

      if (response?.data?.status === "ok") {
        setIsLoading(false);
        setTimeout(() => {
          history.push(ROUTES.PROFILE);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      CustomToast(t("shared.errorGeneric"), "error");
    }
  };
  return (
    <SidebarContainer>
      <ChangePasswordForm
        isLoading={isLoading}
        formSubmitHandler={formSubmitHandler}
        state={state}
        InputHandler={InputHandler}
        includeOldPassword={true}
      />
    </SidebarContainer>
  );
};

export default ChangePassword;
