import { useTranslation } from "react-i18next";

import { useMyContext } from "../../../contexts/StateHolder";
import PackageComponent from "../PackageComponent/PackageComponent";
import PackageTitle from "./PackageTitle";

import classes from "./PackagesView.module.css";
import classes2 from "../PackageComponent/PackageComponent.module.css";
import { getImageByKey } from "../../../scripts/getImageByKey";

const PackagesView = (props) => {
	const { buyButtonAction, profileData, paymentMethods } = props;
	const { packages } = useMyContext();

	const { t } = useTranslation();

	return (
		<div
			className={"asset-main-container package-container"}
			style={{
				flexDirection: "column",
			}}
		>
			<PackageTitle title={t("packages.choosePackage")} active />
			<div className={classes.packageHelp}>
				<div className={classes.packageHelpText}>{t("packages.PackageHelpText")}</div>
			</div>
			<div className={classes.packages}>
				{Object.values(packages).map((value) => (
					<PackageComponent
						key={value.id}
						pkg={value}
						buyButtonAction={buyButtonAction}
						userPackages={profileData.buyerProducts ? profileData.buyerProducts : []}
					/>
				))}
			</div>
			<PackageTitle title={t("packages.choosePayment")} />

			<div className={classes.packages}>
				{Object.values(paymentMethods).map((value) => (
					<>
						<img
							src={getImageByKey(`paymentMethod${value?.name}`)}
							className={`${classes2.container} ${classes.image}`}
							alt={value?.name}
						/>
					</>
				))}
			</div>
		</div>
	);
};

export default PackagesView;
