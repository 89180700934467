import { Link } from "react-router-dom";
import classes from "./Header.module.css";
import Translations from "../../Translations";

const Header = ({ title, viewAll = true, viewAllLink, style }) => {
	let text;
	if (typeof title === "string") {
		text = title?.slice(0, 1)?.toUpperCase() + title?.slice(1, title?.length); // CAPITALIZE ONLY FIRST WORD CAUSE FINNISH LETTER CANNOT HAVE MULTIPLE CAPITALIZE WORD
	} else {
		text = title;
	}
	return (
		<div className={`${classes.container}`} style={style}>
			<div className={`${classes.title} font-600`}>{text}</div>
			{viewAll && (
				<div className="font-300">
					<Link to={viewAllLink}>
						<Translations text="shared.viewAll" />
					</Link>
				</div>
			)}
		</div>
	);
};

export default Header;
