import React from "react";
import Carousel from "react-multi-carousel";

import classes from "./PromoBannerCustomCarousel.module.css";
import useWindowDimensions from "../../WindowDimension";

const slickSettings = {
	infinite: false,

	responsive: {
		// desktop: {
		// 	breakpoint: {
		// 		max: 8000,
		// 		min: 1441,
		// 	},
		// 	items: 3,
		// 	partialVisibilityGutter: 0,
		// 	slidesToSlide: 1,
		// },
		laptop: {
			breakpoint: {
				max: 8000,
				min: 550,
			},
			items: 3,
			partialVisibilityGutter: 0,
			slidesToSlide: 1,
		},

		mobile: {
			breakpoint: {
				max: 549,
				min: 0,
			},
			items: 1,
			partialVisibilityGutter: 40,
			slidesToSlide: 1,
		},
	},
};

const PromoBannerCustomCarousel = ({ children }) => {
	const { width } = useWindowDimensions();
	return (
		<Carousel
			{...slickSettings}
			// centermode and partial mode needs to be other way araound. both cannot be true
			centerMode={width > 968 ? true : false}
			partialVisbile={width > 968 ? false : true}
			itemClass={classes["promo-carousel-item-padding-10-px"]}
			containerClass={classes["promo-container"]}
			// removeArrowOnDeviceType={["desktop", "laptop"]}
			arrows={false} // important
		>
			{children}
		</Carousel>
	);
};

export default PromoBannerCustomCarousel;
