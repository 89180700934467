import React from "react";
import SidebarContainer from "../components/navigation/profile/SidebarContainer";
import SubscriptionItem from "../components/Subscription/Subscription";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";

import { reactQueryGetUser } from "../scripts/dataHandlerReactquery";

import settings from "../configs/config_settings.json";
import { useMyContext } from "../contexts/StateHolder";
import SubscriptionPlaceholder from "../components/Common/SketetonPlaceholder/SubscriptionPlaceholder";

const Subscription = () => {
  const { user } = useMyContext();

  const { organizationId } = settings.organization;
  const fetchUserDetail = () => {
    return reactQueryGetUser(organizationId, user?.userToken);
  };

  const { data: userDetail, isLoading } = useQuery(
    `userDetail`,
    fetchUserDetail,
    {
      // sends API request when user is authenticated ->user should not reach here if they are not authenticated
      enabled: user?.userId * 1 > 1,
      staleTime: 20000, // 20 SECONDS

      // by default the cache time is 5 minutes
      cacheTime: 300000,
    }
  );

  return (
    <SidebarContainer>
      {!isLoading ? (
        <div className="container">
          <SubscriptionItem user={userDetail?.data} />
        </div>
      ) : (
        <SubscriptionPlaceholder />
      )}
    </SidebarContainer>
  );
};

export default Subscription;
