import React, { useEffect, useState } from "react";

import { getImageByKey } from "../../../../scripts/getImageByKey";

import classes from "./SeriesBackgroundBanner.module.css";

const SeriesBackgroundBanner = ({ asset }) => {
	const [backgroundImage, setBackgroundImage] = useState(null);

	useEffect(() => {
		if (asset?.bannerImageSmall || asset?.serie?.bannerImageSmall) {
			setBackgroundImage(
				asset.isSerie
					? asset?.serie?.bannerImageSmall || asset.bannerImageSmall
					: asset.bannerImageSmall
			);
		}
	}, [asset]);

	return (
		<div className={classes["backgroundImageWrapper"]}>
			{asset?.serie?.bannerImageSmall ||
				(asset.bannerImageSmall && (
					// 				<div
					// 					className={classes.backgroundImage}
					// 					style={{
					// 						backgroundImage: `linear-gradient(190deg, rgba(255, 255, 255, 0.69) 0%,
					//   rgba(255, 255, 255, 0.87) 67%,rgba(0, 0, 0, 0.5) 100%),
					//   url(${backgroundImage ? backgroundImage : getImageByKey("channelBannerDemo")})`,
					// 					}}
					// 				/>
					<div className={classes.container}>
						<img
							src={backgroundImage ? backgroundImage : getImageByKey("channelBannerDemo")}
							className={classes.backgroundImage}
							alt={`backgroundImage for video ${asset.name || asset?.title} `}
						/>
						<div className={classes.gradientContainer}></div>
					</div>
				))}
		</div>
	);
};

export default SeriesBackgroundBanner;
