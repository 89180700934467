import { Link } from "react-router-dom";
import classes from "../Dropdown/Dropdown.module.css";

function DropdownItem({ setOpen, setIsMobileNavOpen, onClick, href, icon, text }) {
	const dropdownItemClickHandler = () => {
		// we have to manually close the profile and side menu cause they will not close when anyitem in dropdown container is clicked

		setOpen((prev) => !prev);
		setIsMobileNavOpen((prev) => !prev);
		onClick && onClick();
	};

	if (onClick) {
		return (
			<div
				className={`${classes["dropdownItem"]} nav__item`}
				onClick={dropdownItemClickHandler}
				aria-label={text}
			>
				{icon && icon}

				<div className="nav__item">{text}</div>
			</div>
		);
	}
	return (
		<Link
			className={`${classes["dropdownItem"]} nav__item   `}
			onClick={dropdownItemClickHandler}
			href={href}
			aria-label={text}
			to={href}
		>
			{icon && icon}

			<div className="nav__item">{text}</div>
		</Link>
	);
}

export default DropdownItem;
