import SerieSkeletonPlaceholder from "../../../Common/SketetonPlaceholder/SerieSkeletonPlaceholder";
import SerieEpisodesItem from "../SerieEpisodesItem/SerieEpisodesItem";
import SerieSeasonNumber from "../SerieSeasonNumber/SerieSeasonNumber";

// THIRD PARTY IMPORT
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import settings from "../../../../configs/config_settings.json";
import { ROUTES } from "../../../../constants/route";

const SerieEpisodes = ({ serieCategories }) => {
	const { seasonId, seriesId } = useParams();
	const { organizationId } = settings.organization;

	// we have to check if there is any season or not ->after checking we will render the respective component
	const [seasonSelected, setSeasonSelected] = useState(false);
	const [selectedSeason, setSelectedSeason] = useState(null);
	const [seasonitems, setSeasonItems] = useState([]);

	useEffect(() => {
		if (serieCategories) {
			let seasonDropDownValue = [];

			if (serieCategories?.length > 0) {
				for (const el of serieCategories) {
					//serieCategories?.forEach((el) => {
					let label = "";
					label = `${label} ${el?.title}`;
					seasonDropDownValue.push({
						label: label,
						value: el?.id,
					});
				}
				setSeasonItems(seasonDropDownValue);

				let isSeasonIdPresentInCurrentSeries = !!serieCategories.find(
					(el) => el.id === seasonId * 1
				);

				if (seasonId && isSeasonIdPresentInCurrentSeries) {
					setSelectedSeason(seasonId * 1);
				} else {
					setSelectedSeason(seasonDropDownValue?.[0]?.value);
				}

				seasonDropDownValue = [];
				setSeasonSelected(true);
			} else {
				// no season to show
				setSeasonSelected(true);
			}
		}

		return () => {
			setSeasonItems([]);
			setSeasonSelected(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serieCategories]);

	return (
		<>
			{seasonSelected ? (
				<>
					<SerieSeasonNumber
						// all the lists of season
						seasonitems={seasonitems}
						// selected season
						selectedSeason={selectedSeason}
						// changing the value of selected season
						setSelectedSeason={setSelectedSeason}
						pushRoute={`${ROUTES.SERIES_CATEGORIES}/${organizationId}/${seriesId}`}
					/>
					<SerieEpisodesItem selectedSeason={selectedSeason * 1} />
				</>
			) : (
				<SerieSkeletonPlaceholder />
			)}
		</>
	);
};

export default SerieEpisodes;
