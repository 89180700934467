import { TailSpin } from "react-loader-spinner";
import React from "react";

const Loader = () => {
  return (
    <div className="display-flex-center">
      <TailSpin color="#f39939" height={50} width={50} />
    </div>
  );
};

export default Loader;
