import NavItem from "./NavItem";
import Colors from "../Colors";
import AuthDropdown from "./Dropdown/AuthDropdown";
import SearchBar from "../Search/SearchBar";
import Logo from "./Logo";
import Dropdown from "./Dropdown/DropDown";
import Translations from "../Translations";
import useScrollListener from "../../hooks/useScrollListener";
import SideBarIcon from "./SideBarIcon";
import useWindowDimensions from "../WindowDimension";

import settings from "../../configs/config_settings.json";
import { useMyContext } from "../../contexts/StateHolder";
import { ROUTES } from "../../constants/route";

// REACT AND NEXXT IMPORT
import React, { useEffect, useRef, useState, useCallback } from "react";

// THIRD PARTY IMPORT
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

const customStyles = {
	content: {
		// inset: 0,
		border: "none",
		padding: "50px 20px",
		transition: "all 300ms ease-in-out",
		top: "50%",
		transform: "translate(-50%, -50%)",
		left: "50%",
		background: Colors.background,
		height: "400px",
	},
};
const ALL_CATEGORIES = [
	{
		title: "navigation.categories.series",
		href: "/series",
	},
	{
		title: "navigation.categories.action",
		id: 118453982,
	},
	{
		title: "navigation.categories.adventure",
		id: 118453983,
	},
	{
		title: "navigation.categories.animation",
		id: "118453382",
	},

	{
		title: "navigation.categories.comedy",
		id: 118453388,
	},
	{
		title: "navigation.categories.crime",
		id: 118454430,
	},

	{
		title: "navigation.categories.documentary",
		id: 118453390,
	},
	{
		title: "navigation.categories.drama",
		id: 118453391,
	},
	{
		title: "navigation.categories.family",
		id: 118453392,
	},
	{
		title: "navigation.categories.fantasy",
		id: 118454429,
	},
	{
		title: "navigation.categories.historical",
		id: 118454431,
	},
	{
		title: "navigation.categories.horror",
		id: 118453395,
	},
	{
		id: 118453385,
		title: "navigation.categories.kids",
	},

	{
		title: "navigation.categories.musical",
		id: 118453966,
	},

	{
		title: "navigation.categories.romance",
		id: 118453967,
	},
	{
		title: "navigation.categories.scifi",
		id: 118453971,
	},
	{
		title: "navigation.categories.shortFilm",
		id: 118453972,
	},
	{
		title: "navigation.categories.sport",
		id: 118453974,
	},
	{
		title: "navigation.categories.thriller",
		id: 118453976,
	},
	{
		title: "navigation.categories.war",
		id: 118454417,
	},
	{
		id: 118453979,
		title: "navigation.categories.western",
	},
];

const Navbar = ({ secondaryPage = true }) => {
	const history = useHistory();
	const menuRef = useRef();
	const headerRef = useRef();
	const scroll = useScrollListener();
	const { i18n } = useTranslation();
	const { organizationId } = settings.organization;
	const { t } = useTranslation();

	const dimension = useWindowDimensions();

	const { setLanguageCode, setLanguage } = useMyContext();

	const [cookies, setCookie] = useCookies(["ln"]);

	const allLanguages = [
		{
			text: <Translations text="navigation.language.finnish" />,

			onClick: () => {
				languageChangeHandler("fi");
			},
		},
		{
			text: <Translations text="navigation.language.english" />,

			onClick: () => {
				languageChangeHandler("en");
			},
		},
	];

	const MENU_LIST = [
		// {
		//   text: <Translations text="navigation.menuList.contact" />,
		//   href: ROUTES.CONTACT,
		// },

		// {
		//   text: <Translations text="navigation.menuList.faq" />,
		//   href: ROUTES.FAQ,
		// },
		{
			text: <Translations text="navigation.menuList.svod" />,
			href: ROUTES.SVOD,
		},
		{
			text: <Translations text="navigation.menuList.tvod" />,
			href: ROUTES.TVOD,
		},
		// {
		// 	text: <Translations text="navigation.menuList.search" />,
		// 	href: ROUTES.SEARCH,
		// 	onClick: () => {
		// 		setShowSearchModal(true);
		// 	},
		// },
	];
	const SEARCH_LIST = [
		{
			text: <Translations text="navigation.menuList.search" />,
			href: ROUTES.SEARCH,
			onClick: () => {
				setShowSearchModal(true);
			},
		},
	];

	const allCategoriesHandler = useCallback(
		(text, id, href) => {
			return href ? href : `${ROUTES.CATEGORIES}/${text}/${organizationId}/${id}`;
		},
		[organizationId]
	);

	const restructreAllCategories = useCallback(
		(items) => {
			return items?.map((el) => ({
				text: <Translations text={el?.title} />,
				href: allCategoriesHandler(t(el?.title), el?.id, el?.href),
			}));
		},
		[allCategoriesHandler, t]
	);

	// SMALL SCREEN NAVIGATION MENU open or not

	const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
	const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);

	const [navClassList, setNavClassList] = useState([]);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [searchFieldInput, setSearchFieldInput] = useState("");
	const [languageDropdownTitle, setLanguageDropdownTitle] = useState(
		cookies?.ln === "en" ? allLanguages[1]?.text : allLanguages[0]?.text
	);

	const [updatedAllCategories, setUpdatedAllCategories] = useState([]);

	const languageChangeHandler = (code) => {
		if (code === "fi") {
			setLanguageDropdownTitle(<Translations text="navigation.language.finnish" />);
		} else if (code === "en") {
			setLanguageDropdownTitle(<Translations text="navigation.language.english" />);
		}
		setCookie("ln", code, {
			path: "/",
			Secure: true,
			SameSite: "none",
		});

		i18n.changeLanguage(code);
		setLanguageCode(code);
		setLanguage(code === "fi" ? "fi_FI" : "en_US");
	};

	useEffect(() => {
		const handler = (e) => {
			if (!menuRef?.current?.contains(e.target) && !e.target.closest(".nav__menu-list")) {
				// we also dont close the sidebar auto when any navitem is clicked
				setIsMobileNavOpen(false);
			}
		};

		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	useEffect(() => {
		if (cookies?.ln === "fi") {
			setLanguageCode("fi");
			setLanguage("fi_FI");
			setLanguageDropdownTitle(<Translations text="navigation.language.finnish" />);
			i18n.changeLanguage("fi");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// update classList of nav on scroll
	useEffect(() => {
		const _classList = [];

		if (scroll.y > 150 && scroll.y - scroll.lastY > 0) _classList.push("nav-bar--hidden");

		setNavClassList(_classList);
	}, [scroll.y, scroll.lastY]);

	useEffect(() => {
		if (scroll.y > 0) {
			setIsNavbarScrolled(true);
		} else {
			setIsNavbarScrolled(false);
		}
	}, [scroll.y]);

	useEffect(() => {
		setUpdatedAllCategories(restructreAllCategories(ALL_CATEGORIES));
	}, [i18n, restructreAllCategories]);

	const searchButtonHandler = (e) => {
		e.preventDefault();

		if (searchFieldInput.trim() === "") {
			setShowSearchModal(false);
			return;
		}
		let cur = searchFieldInput;
		setSearchFieldInput("");
		setShowSearchModal(false);
		console.log("here");

		history.push(`${ROUTES.SEARCH}?value=${cur}`);
	};

	const searchESCButtonHandler = () => {
		setShowSearchModal(false);
	};

	return (
		<>
			<header
				ref={headerRef}
				className={navClassList.join(" ")}
				style={{
					backgroundColor: isNavbarScrolled ? Colors.background : Colors.white,

					height: "80px",
				}}
			>
				<nav className={`nav`}>
					<div className="nav-container">
						<Logo />

						<SideBarIcon
							menuRef={menuRef}
							setIsMobileNavOpen={setIsMobileNavOpen}
							isMobileNavOpen={isMobileNavOpen}
						/>

						<div className={`${isMobileNavOpen ? "active" : ""} nav__menu-list`}>
							{MENU_LIST.map((menu, i) => (
								<div
									onClick={() => {
										setIsMobileNavOpen(false);
									}}
									key={i.toString()}
								>
									<NavItem {...menu} />
								</div>
							))}

							<Dropdown
								title={<Translations text="navBarCategoryDropDown.categories" />}
								secondaryPage={secondaryPage}
								setIsMobileNavOpen={setIsMobileNavOpen}
								items={updatedAllCategories}
								ariaLabelledBy="categories-dropdown"
							/>

							{/* <AuthDropdown
								setIsMobileNavOpen={setIsMobileNavOpen}
								ariaLabelledBy="user-button-container"
							/> */}

							{dimension?.width <= 768 && (
								<>
									{SEARCH_LIST.map((menu, i) => (
										<div
											onClick={() => {
												setIsMobileNavOpen(false);
											}}
											key={i.toString()}
										>
											<NavItem {...menu} />
										</div>
									))}

									<AuthDropdown
										setIsMobileNavOpen={setIsMobileNavOpen}
										ariaLabelledBy="user-button-container"
									/>
									<Dropdown
										title={languageDropdownTitle}
										secondaryPage={secondaryPage}
										setIsMobileNavOpen={setIsMobileNavOpen}
										items={allLanguages}
										ariaLabelledBy="language-selector-button-container"
										autoHeight
									/>
								</>
							)}
						</div>
					</div>

					{dimension?.width > 768 && (
						<div
							style={{
								display: "flex",
							}}
							className="nav__menu-list"
						>
							{SEARCH_LIST.map((menu, i) => (
								<div
									onClick={() => {
										setIsMobileNavOpen(false);
									}}
									key={i.toString()}
								>
									<NavItem {...menu} />
								</div>
							))}
							<AuthDropdown
								setIsMobileNavOpen={setIsMobileNavOpen}
								ariaLabelledBy="user-button-container"
							/>
							<Dropdown
								title={languageDropdownTitle}
								secondaryPage={secondaryPage}
								setIsMobileNavOpen={setIsMobileNavOpen}
								items={allLanguages}
								ariaLabelledBy="language-selector-button-container"
								autoHeight // sets the height to max content for dropdown
							/>
						</div>
					)}
				</nav>
			</header>
			<ReactModal
				isOpen={showSearchModal}
				// overlayClassName="overlay-search-modal"
				overlayClassName="Overlay"
				style={customStyles}
				onRequestClose={() => setShowSearchModal(false)}
				shouldCloseOnEsc={true}
				shouldCloseOnOverlayClick={true}
				preventScroll={true}
				ariaHideApp={false}
				className="search-modal"
			>
				<SearchBar
					submitHanlder={searchButtonHandler}
					searchESCButtonHandler={searchESCButtonHandler}
					setSearchFieldInput={setSearchFieldInput}
					searchFieldInput={searchFieldInput}
					isModal
				/>
			</ReactModal>
		</>
	);
};

export default Navbar;
