import settings from "../configs/config_settings.json";
import MD5 from "spark-md5";

const convertCurrency = (currency) => {
  switch (currency.toLowerCase()) {
    case "eur":
      return "€";
    case "ksh":
      return "KsH";
    case "dol":
      return "$";
    default:
      return "";
  }
};

export const manageCarouselResponsiveness = (
  slickSettings,
  windowDimension,
  assets,
  className1
) => {
  let arr = Object.keys(slickSettings.responsive).map(
    (k) => slickSettings.responsive[k]
  );
  let arr1 = [...arr];

  let j = [...arr1].find(
    (el) =>
      el.breakpoint.min <= windowDimension.width &&
      el.breakpoint.max >= windowDimension.width
  );

  if (assets?.length === j?.items) {
    return "addSeecondary";
  } else {
    return "";
  }
};
export const resetPasswordToken = (emailAddress, organizationId, role, key) => {
  const currentTime = getCurrentTime();
  const signature = MD5.hash(
    `${emailAddress}:${organizationId}:${role}:${currentTime}:${key}`
  );

  let version = "01";

  return version + currentTime + signature;
};

// Converts seconds to hours and minutes
const convertDuration = (dur) => {
  const dS = Number(dur);

  const h = Math.floor(dS / 3600);
  const m = Math.floor((dS % 3600) / 60);

  return h > 0 ? `${h} h ${m} min` : `${m} min`;
};
export const convertSecondToMin = (dur) => {
  const dS = Number(dur);

  const m = Math.floor(dS / 60);

  if (m > 0) {
    return `${m} min`;
  } else {
    return null;
  }
};

const trancuateDesc = (text, sizes, maxLines) => {
  if (text.length > sizes) {
    return text.substring(0, sizes) + "...";
  } else {
    return text;
  }
};

const trancuate = (text, size, maxLines) => {
  if (text.length > size) {
    return text.substring(0, size) + "...";
  } else {
    return text;
  }
};

// TOKEN CREATOR
const createToken = (organizationId, groupItemId, key) => {
  const currentTime = getCurrentTime();
  const signature = MD5.hash(
    `${organizationId}:${groupItemId}:${currentTime}:${key}`
  );

  return "05" + currentTime + signature;
};

// TOKEN CREATOR FOR ASSETS
const createAssetIdToken = (organizationId, assetId, languageId, key) => {
  const currentTime = getCurrentTime();
  let signature;

  if (!languageId) {
    signature = MD5.hash(`${organizationId}:${assetId}:${currentTime}:${key}`);
  } else {
    signature = MD5.hash(
      `${organizationId}:${assetId}:${languageId}:${currentTime}:${key}`
    );
  }

  return "03" + currentTime + signature;
};

// TOKEN CREATOR FOR ACCOUNT MANAGEMENT
const createAccountToken = (
  userId,
  organizationId,
  key,
  firstName,
  lastName,
  phoneNumber,
  countryId,
  regionId,
  cityName,
  postalCode,
  eMail
) => {
  const currentTime = getCurrentTime();
  const signature = MD5.hash(
    `${organizationId}:${userId}:${firstName}:${lastName}:${countryId}:${regionId}:${postalCode}:${cityName}:${phoneNumber}:${currentTime}:${key}`
  );

  return "01" + currentTime + signature;
};

// TOKEN CREATOR FOR PASSWORD CHANGING PROCESS
const createChangePasswordToken = (
  organizationId,
  key,
  userId,
  newPassword,
  confirmPassword
) => {
  const currentTime = getCurrentTime();
  const signature = MD5.hash(
    `${organizationId}:${userId}:${newPassword}:${confirmPassword}:${currentTime}:${key}`
  );

  return "01" + currentTime + signature;
};

// GroupItemId creates string from category id:s, separated with comma and returns it to main program
const createGroupItemId = (categories, exclude) => {
  let groupItemId = "";

  // Add id:s to string, separating with comma. On last item, dont add comma.
  for (let i = 0; i < categories.length; i++) {
    if (exclude && !exclude.includes(categories[i].id)) {
      if (i === categories.length - 1) {
        groupItemId += `${categories[i].id}`;
      } else {
        groupItemId += `${categories[i].id},`;
      }
    } else if (!exclude) {
      if (i === categories.length - 1) {
        groupItemId += `${categories[i].id}`;
      } else {
        groupItemId += `${categories[i].id},`;
      }
    }
  }
  return groupItemId;
};

const checkVod = (routes, pathname) => {
  let vod = "";
  // Check current location, if it's svod or tvod side of a site
  if (pathname.includes(routes.svod)) {
    vod = routes.svod;
  } else {
    vod = routes.tvod;
  }
  return vod;
};

// Checks if all arguments of a function are defined
const checkArguments = (...args) => {
  return args.every((arg) => arg !== undefined);
};
const modifySlickSettings = (settings, items) => {
  if (checkArguments(settings.responsive, items)) {
    console.log(settings.responsive, items, window.innerWidth, "window");

    // Check number of slides to show from correct responsitivity setting
    const slides = settings.responsive.find(
      (resp) => window.innerWidth >= resp.breakpoint
    ).settings.slidesToShow;

    // If there's less or equal amount of items in category than there's slidesToShow, set slick infinite to false to prevent duplicates (slick bug)
    if (items <= slides) {
      settings.infinite = false;
    }
    return settings;
  }
};

// Get current time for token creators
const getCurrentTime = () => {
  return Math.floor(new Date().getTime() / 1000).toString(16);
};

// Removes punctuation from string
const removePunctuation = (v) => {
  console.log(settings.language);
  // Punctuaton is only for greek language
  if (settings.language !== "el_GR") return v ? v.toUpperCase() : "";
  if (v && v !== "") {
    v = v.replace(/Ά/g, "Α");
    v = v.replace(/Ή/g, "Η");
    v = v.replace(/Ί/g, "Ι");
    v = v.replace(/Ό/g, "Ο");
    v = v.replace(/Έ/g, "Ε");
    v = v.replace(/Ύ/g, "Υ");
    v = v.replace(/Ώ/g, "Ω");
    v = v.replace(/ή/g, "h");
    v = v.replace(/ό/g, "o");
    v = v.replace(/ά/g, "a");
    v = v.replace(/ί/g, "i");
    v = v.replace(/ύ/g, "y");
    console.log(v);
    return v.toUpperCase();
  }
};

const removePunctuationFromResponse = (array) => {
  if (Array.isArray(array)) {
    array.forEach((item) => {
      item.description = removePunctuation(item.description);
      item.ingress = removePunctuation(item.ingress);
      item.name = removePunctuation(item.name);
      item.title = removePunctuation(item.title);
    });
    return array;
  } else {
    const item = array;
    item.description = removePunctuation(item.description);
    item.ingress = removePunctuation(item.ingress);
    item.name = removePunctuation(item.name);
    item.title = removePunctuation(item.title);
    return item;
  }
};

const convertDate = (value) => {
  const date = new Date(value);

  // Return date in DD/MM/YYYY format
  return date.toLocaleDateString("en-GB");
};

export {
  convertCurrency,
  convertDate,
  convertDuration,
  createToken,
  createAssetIdToken,
  createAccountToken,
  createChangePasswordToken,
  createGroupItemId,
  checkVod,
  modifySlickSettings,
  checkArguments,
  removePunctuation,
  removePunctuationFromResponse,
  trancuateDesc,
  trancuate,
};
