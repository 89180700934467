import { ROUTES } from "../constants/route";

export const getAssetRoute = (asset, organizationId) => {
  if (asset?.isSerieCategory || asset?.isSerie || asset?.series?.length > 0) {
    // isSerieCategory is NOT RETURNED FROM API ->WE ARE PASSING IT MANUALLY
    return `${ROUTES.SERIES_CATEGORIES}/${organizationId}/${asset.id}`;
  } else {
    // ASSET ROUTE

    return `${ROUTES.VIDEO_DETAILS}/${organizationId}/${asset?.id}`;
  }
};
