import React from "react";

import SerieItemDetailDescription from "./SerieItemDetailDescription";
import SerieItemInfo from "../SerieItemInfo/SerieItemInfo";

import classes from "./SerieItemDetail.module.css";

const SerieItemDetail = ({ asset }) => {
	return (
		<div className={classes.container}>
			<SerieItemInfo asset={asset} />

			<SerieItemDetailDescription description={asset?.description} />
		</div>
	);
};

export default SerieItemDetail;
