import { getImageByKey } from "../scripts/getImageByKey";
import { isAssetSerie } from "./isAssetSerie";

export const selectAssetImage = (el) => {
	const isSerie = isAssetSerie(el);

	if (isSerie) {
		if (el?.serie?.coverImageLarge || el?.serie?.coverImageMedium || el?.serie?.coverImageSmall) {
			return (
				el?.serie?.coverImageLarge ||
				el?.serie?.coverImageMedium ||
				el?.serie?.coverImageSmall ||
				el?.serie?.thumbnailLarge ||
				el?.serie?.thumbnailMedium ||
				el?.serie?.thumbnailSmall ||
				el?.serie?.bannerImageLarge ||
				el?.serie?.bannerImageMedium ||
				el?.serie?.bannerImageSmall ||
				getImageByKey("fallbackImage")
			);
		}
	}
	return (
		el?.coverImageLarge ||
		el?.coverImageMedium ||
		el?.coverImageSmall ||
		el?.thumbnailLarge ||
		el?.thumbnailMedium ||
		el?.thumbnailSmall ||
		el?.bannerImageLarge ||
		el?.bannerImageMedium ||
		el?.bannerImageSmall ||
		getImageByKey("fallbackImage")
	);
};

export const getThumbnailImage = (el) => {
	return (
		el?.thumbnailLarge ||
		el?.thumbnailMedium ||
		el?.thumbnailSmall ||
		el?.bannerImageLarge ||
		el?.bannerImageMedium ||
		el?.bannerImageSmall ||
		getImageByKey("fallbackImage")
	);
};

export const getBannerImage = (el) => {
	return (
		el?.bannerImageLarge ||
		el?.bannerImageMedium ||
		el?.bannerImageSmall ||
		getImageByKey("fallbackImage")
	);
};
