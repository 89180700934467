import React from "react";
import Button from "../Common/Button/Button";
import { Input } from "../Common/Input/Input";
import { VALIDATOR_EMAIL, VALIDATOR_NONE, VALIDATOR_REQUIRE } from "../Common/Validation/Validator";
import Translations from "../Translations";

import classes from "./Form.module.css";

// THIRD PARTY IMPORT

import {
	faEnvelope,
	faUser,
	faMapMarked,
	faMapPin,
	faCity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserDeleteModal } from "../modal/UserDeleteModal";
import useDeleteModal from "../../hooks/useDeleteModal";

const ProfileForm = ({ loading, profileData, formSubmitHandler, state, InputHandler }) => {
	const deleteModal = useDeleteModal();

	const formhandle = async (e) => {
		e.preventDefault();
		formSubmitHandler();
	};
	return (
		<div className={`${classes.profile_container} `} style={{}}>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					gap: "20px",
				}}
			>
				<form className={`${classes.formContainer} pb-20 `} onSubmit={formhandle}>
					<h2 className={`${classes.formHeaderSignUp} font-700 pb-20  `}>PROFILE DETAIL</h2>

					{profileData?.email && (
						<>
							<Input
								id="EMAIL"
								label={<Translations text="form.password.email" />}
								placeholder={<Translations text="form.password.enterEmail" />}
								type="text"
								element="input"
								validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
								errorText={<Translations text="form.password.enterValidEmail" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faEnvelope} />}
								disabled={true}
								initialValue={profileData?.email}
								initialValid={true}
							/>

							<Input
								id="FIRSTNAME"
								label={<Translations text="form.profile.firstName" />}
								placeholder={<Translations text="form.profile.enterFirstName" />}
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								errorText={<Translations text="form.profile.enterFirstName" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faUser} />}
								initialValid={true}
								initialValue={profileData.firstName}
							/>
							<Input
								id="LASTNAME"
								label={<Translations text="form.profile.lastName" />}
								placeholder={<Translations text="form.profile.enterlastName" />}
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								errorText={<Translations text="form.profile.enterLastName" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faUser} />}
								initialValid={true}
								initialValue={profileData.lastName}
							/>
							<Input
								id="ADDRESS"
								label={<Translations text="form.profile.address" />}
								placeholder={<Translations text="form.profile.enterAddress" />}
								type="text"
								element="input"
								validators={[VALIDATOR_NONE()]}
								errorText={<Translations text="form.profile.enterAddress" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faMapPin} />}
								initialValid={true}
								initialValue={profileData?.address}
							/>
							<Input
								id="POSTALCODE"
								label={<Translations text="form.profile.postal" />}
								placeholder={<Translations text="form.profile.enterPostal" />}
								type="text"
								element="input"
								validators={[VALIDATOR_NONE()]}
								errorText={<Translations text="form.profile.enterPostal" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faMapMarked} />}
								initialValid={true}
								initialValue={profileData?.postalCode}
							/>
							<Input
								id="CITY"
								label={<Translations text="form.profile.city" />}
								placeholder={<Translations text="form.profile.enterCity" />}
								type="text"
								element="input"
								validators={[VALIDATOR_NONE()]}
								errorText={<Translations text="form.profile.enterCity" />}
								onInput={InputHandler}
								iconName={<FontAwesomeIcon icon={faCity} />}
								initialValid={true}
								initialValue={profileData?.city}
							/>

							<Button
								className={`${classes.loginFormButton} width-100`}
								disabled={!state.isValid || loading}
								formButton
							>
								{loading ? (
									<Translations text="shared.loading" />
								) : (
									<Translations text="shared.save" />
								)}
							</Button>
						</>
					)}
				</form>
				<div className={`${classes.formContainer} ${classes.deleteUserContainer}`}>
					<UserDeleteModal>
						<Button
							type="button"
							onClick={() => {
								deleteModal.data.user = profileData;
								deleteModal.onOpen();
							}}
						>
							<Translations text="deleteModal.deleteAccount" />
						</Button>
					</UserDeleteModal>
				</div>
			</div>
		</div>
	);
};
export default ProfileForm;
