import React from "react";
import { trancuateText } from "../../../lib/trancuateText";
import { isAssetSerie } from "../../../lib/isAssetSerie";

const AssetItemTitle = ({ asset, style }) => {
	let title;

	let updatedTitle = asset?.name || asset?.title;

	const isSerie = isAssetSerie(asset);

	if (isSerie) {
		// WE HAVE TO SHOW MAIN SERIE NAME WHEN ASSET IS AN EPISODE
		updatedTitle = asset?.series?.[0]?.name;
	}

	title = trancuateText(updatedTitle, 20);
	return <div style={style}>{title}</div>;
};

export default AssetItemTitle;
