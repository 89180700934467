import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

import { convertCurrency } from "../../../scripts/utils";
import classes from "./PackageComponent.module.css";
import { trancuateText } from "../../../lib/trancuateText";
import ToggleDescription from "../../Common/ToggleDescription/ToggleDescription";
import Button from "../../Common/Button/Button";

const PackageComponent = (props) => {
	const { t } = useTranslation();

	const { pkg, buyButtonAction } = props;

	let description1 = pkg?.description;

	const [desc, setDesc] = useState("");
	const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
	const [hasMore, setHasMore] = useState(false);

	useEffect(() => {
		if (description1?.length > 148) {
			setDesc(trancuateText(description1, 148));
			setHasMore(true);
		} else {
			setDesc(description1);
		}
	}, [description1]);

	const descriptionOpenhandler = useCallback(() => {
		setIsDescriptionOpen(true);
		setDesc(description1);
	}, [description1]);
	const descriptionClosehandler = useCallback(() => {
		setIsDescriptionOpen(false);

		setDesc(trancuateText(description1, 148));
	}, [description1]);

	return (
		<div className={classes.container} key={pkg.id}>
			<div className={classes.title}>{pkg.name}</div>
			<div className={classes.price}>
				{pkg.price} {convertCurrency(pkg.currency)}
			</div>
			<div className={classes.textContainer}>
				<div className={classes.description} dangerouslySetInnerHTML={{ __html: desc }} />

				{hasMore && (
					<ToggleDescription
						isDescriptionOpen={isDescriptionOpen}
						descriptionClosehandler={descriptionClosehandler}
						descriptionOpenhandler={descriptionOpenhandler}
					/>
				)}
			</div>

			<div className={classes.buyButtonAction}>
				<Button onClick={() => buyButtonAction(pkg)} inverse>
					{t("packages.Order")}
				</Button>
			</div>
		</div>
	);
};

export default PackageComponent;
