import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import classes from "./SignUpBar.module.css";
import { validateEmail } from "../../../scripts/inputValidators";
import { ROUTES } from "../../../constants/route";

// Renders signUpBar version 2
const SignUpBar = (props) => {
	const [signUpBarInput, setSignUpBarInput] = useState("");

	const [showInfoMessage, setShowInfoMessage] = useState(false);

	const [cookies, setCookie] = useCookies("");

	const history = useHistory();

	const { t } = useTranslation();

	const clickSignUp = () => {
		if (validateEmail(signUpBarInput)) {
			// Make sure info message is hidden
			setShowInfoMessage(false);

			// Set userToken in cookies
			setCookie(
				"user",
				{
					eMail: signUpBarInput,
				},
				{
					path: "/",
					secure: true,
					sameSite: "none",
				}
			);

			// Redirect user to signUp page
			history.push(ROUTES.SIGNUP);
		} else {
			// Show info message about wrong password
			setShowInfoMessage(true);

			console.log(cookies);
		}
	};

	return (
		<div className={classes.SignUpBar}>
			<div className={classes.SignUpBarBackground} />

			<div className={classes.SignUpBarTitle}>{t("signUpBar.title")}</div>

			<div className={classes.SignUpBarSubtitle}>{t("signUpBar.subtitle")}</div>

			<div className={classes.SignUpBarText}>{t("signUpBar.welcomeText1")}</div>
			<div className={classes.SignUpContainer}>
				<input
					className={classes.SignUpBarSignUpInput}
					type="email"
					value={signUpBarInput}
					onChange={(e) => setSignUpBarInput(e.target.value)}
					placeholder={t("signUpBar.inputPlaceholderEmail")}
					onKeyPress={(e) => (e.key === "Enter" ? clickSignUp() : null)}
				/>

				<button
					className={classes.SignUpBarSignUpBTN}
					type="submit"
					value="signUp"
					onClick={() => clickSignUp()}
				>
					{t("signUpBar.signupButton")}
				</button>

				<div
					className={classes.SignUpBarInfoMessage}
					style={showInfoMessage ? { display: "flex" } : { display: "none" }}
				>
					{t("signUpBar.errorIncorrectEmailAddress")}
				</div>
			</div>
		</div>
	);
};

export default SignUpBar;
