import React, { useEffect, useState } from "react";
import { createToken } from "../../scripts/utils";

import settings from "../../configs/config_settings.json";
import { reactQuerygetBanner } from "../../scripts/dataHandlerReactquery";
import { useMyContext } from "../../contexts/StateHolder";
import { useQuery } from "react-query";
import useWindowDimensions from "../WindowDimension";
import { getAssetPushRoute } from "../../lib/getAssetPushRoute";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import BannerItem from "./BannerItem";

const USE_HISTORY_PUSH = true;

const Banner = (props) => {
	const {
		groupItemId,
		routes,
		slickSettings,
		showDuration,
		showTitle,
		showActionButton,
		showDescription,
		showReleaseYear,
		showCategory,
		showContentRating,
		id,
	} = props.settings;

	const { organizationId, key } = settings.organization;
	const { language } = settings.language;

	const { user } = useMyContext();
	const windowDimension = useWindowDimensions();
	const [deviceType, setDeviceType] = useState(null);

	const history = useHistory();

	const fetchBanner = () => {
		const token = createToken(organizationId, groupItemId, key);

		return reactQuerygetBanner(organizationId, groupItemId, token, language, user);
	};
	const { data: banners, isLoading } = useQuery(`${id}Banner${groupItemId}`, fetchBanner, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
	});

	useEffect(() => {
		if (windowDimension.width <= 550) {
			setDeviceType("mobile");
		} else {
			setDeviceType(null);
		}
	}, [windowDimension.width]);

	const clickItem = (item) => {
		console.log("item", item);
		getAssetPushRoute(item, routes, organizationId, USE_HISTORY_PUSH, history);
	};

	const skeletonItem = [1];

	if (isLoading) {
		let updatedSlickSetting = { ...slickSettings };
		updatedSlickSetting.dots = false;
		updatedSlickSetting.showStatus = false;
		updatedSlickSetting.showIndicators = false;
		updatedSlickSetting.showThumbs = false;

		return (
			<Carousel {...updatedSlickSetting}>
				{skeletonItem.map((el2) => (
					<SkeletonTheme key={el2}>
						<p>
							<Skeleton
								key={el2}
								width={"100%"}
								height={"350px"}
								highlightColor="#272727"
								duration={2}
							/>
						</p>
					</SkeletonTheme>
				))}
			</Carousel>
		);
	}

	if (banners?.data?.assets) {
		return (
			<div className="bannerContainer">
				<Carousel
					{...slickSettings}
					centerMode={!deviceType ? true : false}
					centerSlidePercentage={!deviceType ? "100" : "100"}
					showArrows={!deviceType ? true : false}
					autoPlay={true}
				>
					{banners?.data?.assets
						? banners?.data?.assets.map((el) => {
								return (
									<BannerItem
										clickItem={clickItem}
										item={el}
										id={el.id}
										key={el.id}
										deviceType={deviceType}
										showDuration={showDuration}
										showtitle={showTitle}
										showActionButton={showActionButton}
										showDescription={showDescription}
										showCategory={showCategory}
										showReleaseYear={showReleaseYear}
										showContentRating={showContentRating}
									/>
								);
						  })
						: null}
				</Carousel>
			</div>
		);
	}
};

export default Banner;
