import React, { useState, useContext } from "react";

const MyContext = React.createContext();

const StateHolder = (props) => {
	const [language, setLanguage] = useState("fi_FI");

	//Holds organizations packages
	const [packages, setPackages] = useState([]);

	const [shoppingCategories, setShoppingCategories] = useState([]);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [selectedPackage, setSelectedPackage] = useState({});

	// // checked below

	const [languageCode, setLanguageCode] = useState("fi");

	const [user, setUser] = useState({});
	const [userLoggedIn, setUserLoggedIn] = useState(false);

	return (
		<MyContext.Provider
			value={{
				packages,
				setPackages,

				selectedPackage,
				setSelectedPackage,

				shoppingCategories,
				setShoppingCategories,

				paymentMethods,
				setPaymentMethods,

				language,
				setLanguage,

				// checked below
				languageCode,
				setLanguageCode,

				user,
				setUser,

				userLoggedIn,
				setUserLoggedIn,
			}}
		>
			{props.children}
		</MyContext.Provider>
	);
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
