import classes from "../Form.module.css";

// THIRD PARTY IMPORT

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translations from "../../Translations";
import Button from "../../Common/Button/Button";
import { Input } from "../../Common/Input/Input";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../Common/Validation/Validator";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getImageByKey } from "../../../scripts/getImageByKey";
import { Link } from "react-router-dom";

const ResetEmailSuccess = () => {
  return (
    <div className={classes.formPrimary}>
      <div className={classes.formSecondary}>
        <div
          className={`${classes.formBlog}
     
     
     
     `}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2 className={`${classes.formHeader} font-700 smallcasebold`}>
            <Translations text="form.password.passwordLinkSent" />
          </h2>
          <p
            className="font-200 "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <Translations text="form.password.passwordLinkMsg" />
          </p>

          <Button
            className={classes.loginFormButton}
            formButton
            to="/"
            style={{
              justifyContent: "center",
              display: "flex",
              textAlign: "center",
            }}
          >
            <Translations text="shared.home" />
          </Button>
        </div>
      </div>
    </div>
  );
};

const PasswordForm = ({ InputHandler, state, formSubmitHandler, loading }) => {
  return (
    <form className={`${classes.formContainer} `} onSubmit={formSubmitHandler}>
      <h2 className={`${classes.formHeader} font-700  `}>
        <Translations text="form.password.forgotPassword" />
      </h2>

      <Input
        id="EMAIL"
        label={<Translations text="form.password.email" />}
        placeholder={<Translations text="form.password.enterEmail" />}
        type="text"
        element="input"
        validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE()]}
        errorText={<Translations text="form.password.enterValidEmail" />}
        onInput={InputHandler}
        iconName={<FontAwesomeIcon icon={faEnvelope} />}
      />

      <Button
        className={classes.loginFormButton}
        disabled={!state.isValid || loading}
        formButton
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        {loading ? (
          <Translations text="shared.loading" />
        ) : (
          <Translations text="shared.submit" />
        )}
      </Button>

      {
        <div className={`${classes.linkContainer} font-200 `}>
          <div>
            <Translations text="form.password.alreadyHaveAccount" />
            <Link to={`/auth/login`} className={classes.linkText}>
              <Translations text="shared.login" />
            </Link>
          </div>

          <div>
            <Translations text="form.password.newUserSite" />

            <Link to={`/auth/signup`} className={classes.linkText}>
              <Translations text="shared.signUp" />
            </Link>
          </div>
        </div>
      }
    </form>
  );
};

const ForgetPasswordForm = ({
  resetEmailSuccess,
  InputHandler,
  state,
  formSubmitHandler,
  loading,
}) => {
  return (
    <div
      className={`${classes.container} `}
      style={{
        marginTop: "100px",
      }}
    >
      <LazyLoadImage
        src={getImageByKey("forgot_password")}
        height={500}
        width={300}
        className={`${classes.containerbgImage} width-80`}
        alt="forgot_password"
      />
      <>
        {resetEmailSuccess ? (
          <ResetEmailSuccess />
        ) : (
          <PasswordForm
            state={state}
            InputHandler={InputHandler}
            resetEmailSuccess={resetEmailSuccess}
            formSubmitHandler={formSubmitHandler}
            loading={loading}
          />
        )}
      </>
    </div>
  );
};

export default ForgetPasswordForm;
