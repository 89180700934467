const CONTENT_RATING_KEY = {
  fi_above_18: "K-18",
  fi_above_16: "K-16 ",

  fi_above_12: "K-12",
  fi_above_7: "K-7",
  fi_all_ages: "S",
};

export const getContentRatingText = (asset) => {
  const contentRating = asset?.contentRatings;
  if (contentRating?.length > 0) {
    let currentRating = contentRating?.[0]?.key;

    return CONTENT_RATING_KEY[currentRating];
  } else {
    return null;
  }
};
