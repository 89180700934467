import Button from "../Common/Button/Button";
import Translations from "../Translations";
import useWindowDimensions from "../WindowDimension";

import classes from "./SearchBar.module.css";

// THIRD PARTY IMPORT
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBar = ({
  submitHanlder,
  searchESCButtonHandler,
  setSearchFieldInput,
  searchFieldInput,
  isModal = false,
}) => {
  const { width } = useWindowDimensions();
  return (
    <form
      className={`${classes.container} display-flex-center ${
        isModal ? "width-80" : "width-100"
      }`}
      style={{
        flexDirection: width <= 599 ? "column" : isModal ? "column" : "row",
      }}
      onSubmit={submitHanlder}
    >
      <div className={classes.inputContainer}>
        <input
          className={` font-300 width-100 ${classes.searchInput}`}
          type="text"
          value={searchFieldInput}
          onChange={(e) => setSearchFieldInput(e.target.value)}
          placeholder={"Etsi elokuvia ja sarjoja"}
        />
        {isModal && (
          <div className={`font-300 display-flex-center ${classes.escIcon}`}>
            <FontAwesomeIcon
              icon={faClose}
              size="2x"
              onClick={searchESCButtonHandler}
            />
            <p>esc</p>
          </div>
        )}
      </div>
      <Button
        style={{
          width: width <= 599 ? "100%" : "auto",
        }}
        formButton
        disabled={!searchFieldInput}
        inverse
      >
        <Translations text="navigation.menuList.search" />
      </Button>
    </form>
  );
};

export default SearchBar;
