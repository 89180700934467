import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { useMyContext } from "../contexts/StateHolder";

import { authenticateUser, registerUser } from "../scripts/dataHandlers";

import settings from "../configs/config_settings.json";

const MyContextFunctions = React.createContext();

const ContextFunctions = (props) => {
  const { setUser, setUserLoggedIn } = useMyContext();

  const { organizationId } = settings.organization;

  const [cookies, setCookie] = useCookies("");

  const authProcess = async (email, password) => {
    try {
      const authResponse = await authenticateUser(
        organizationId,
        email,
        password
      );

      if (authResponse.data.status === "ok") {
        let newUser = {
          userId: authResponse.data.user_id,
          userToken: authResponse.data.user_token,
          firstName: authResponse.data.user_name,
          eMail: email,

          organizationId: authResponse.data.user_organization_id,
          organizationName: authResponse.data.user_organization_name,
        };

        setUser({ ...newUser });

        // Set userToken in cookies
        setCookie(
          "user",
          {
            userId: authResponse.data.user_id,
            userToken: authResponse.data.user_token,
            firstName: authResponse.data.user_name,

            organizationId: authResponse.data.user_organization_id,
            organizationName: authResponse.data.user_organization_name,
            eMail: email,
          },
          {
            path: "/",
          }
        );
        setCookie("ue", email, {
          path: "/",
        });
        setUserLoggedIn(true);
      }

      return authResponse;
    } catch (err) {
      console.log(err);
      console.log(cookies);
    }
  };

  const registerProcess = async (inputs) => {
    try {
      const response = await registerUser(organizationId, inputs);

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MyContextFunctions.Provider
      value={{
        authProcess,

        registerProcess,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
