import classes from "./PromoBanner.module.css";

import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import settings from "../../configs/config_settings.json";

import { getThumbnailImage } from "../../lib/selectAssetImage";
import { createAssetIdToken } from "../../scripts/utils";
import { reactQueryfetchCategoryAssets } from "../../scripts/dataHandlerReactquery";
import { useMyContext } from "../../contexts/StateHolder";
// import { shuffle } from "../../lib/shuffle";
import { getAssetRoute } from "../../lib/getAssetRoute";

import PromoBannerDetail from "./PromoBannerDetail";

const PromoBanner = (props) => {
	const { organizationId, key } = settings?.organization;
	const { language } = settings;
	const { user } = useMyContext();
	const history = useHistory();

	const { groupItemId, assetProperty } = props?.settings;

	const { index = 0 } = props;

	const fetchPromo = () => {
		const token = createAssetIdToken(organizationId, groupItemId, null, key);
		return reactQueryfetchCategoryAssets(
			organizationId,
			groupItemId,
			token,
			language,
			assetProperty,
			user
		);
	};

	const { data, isLoading } = useQuery(`${groupItemId}getPromo`, fetchPromo, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,

		select: (res) => {
			return res?.data?.assets;
		},
	});

	console.log("data", data);

	if (!isLoading && data?.[index]) {
		return (
			<div className={`${classes.container} grid-container grid-container-promo`}>
				<div className={classes.imageContainer}>
					<LazyLoadImage
						src={getThumbnailImage(data?.[index])} // first selects ccover imafe
						width={1920}
						height={780}
						alt={`Promo banner for ${data?.[0]?.name || data?.[index]?.title}`}
						onClick={() => history.push(getAssetRoute(data?.[index], organizationId))}
					/>
				</div>

				<PromoBannerDetail asset={data?.[index]} />
			</div>
		);
	}
};

export default PromoBanner;
