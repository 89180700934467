export const getVideoCurrentPrice = (asset) => {
	if (asset?.accessInfo?.products) {
		let price = asset?.accessInfo?.products
			?.filter((el) => !el?.recurring)
			?.map((el1) => el1?.price?.split(" ")?.[0] * 1)?.[0];

		return price;
	} else {
		return null;
	}
	// let videoCurrentPrice = asset?.accessInfo?.price;
	// if (videoCurrentPrice) {
	// 	videoCurrentPrice = videoCurrentPrice?.split(" ")?.[0];
	// }

	// else {
	// 	// NOT CHECK THE ARRAYS OF PRODUCT
	// 	let allProductsPrice = asset?.accessInfo?.products?.map((el) => el?.price?.split(" ")?.[0] * 1);

	// 	videoCurrentPrice = findMinimum(allProductsPrice);
	// 	console.log("allProductsPrice", allProductsPrice);
	// }
};
