import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";

import { getImageByKey } from "../../scripts/getImageByKey";
import { useMyContext } from "../../contexts/StateHolder";
import { ROUTES } from "../../constants/route";

import classes from "./TOS.module.css";

const TOSEn = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const { languageCode } = useMyContext();

	useEffect(() => {
		if (languageCode?.includes("fi")) {
			return history.push(ROUTES.TOS);
		}
	}, [languageCode, history]);

	return (
		<div className={`${classes.tosContainer} font-300`}>
			<div className={`${classes.tosTitle} font-600`}>{t("tos.title")}:</div>
			The terms of delivery are valid from 1 November 2024.
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>General Information </div>
				<br />
				Kotikino (hereinafter referred to as Kotikino or the Service) is a video and live streaming
				platform maintained and operated by Nordic Content Distribution Ltd., accessible at{" "}
				<NavLink to={`/`} exact activeClassName="no-class">
					https://www.kotikino.fi
				</NavLink>
				<br />
				<br />
				The Service and the materials, images, texts, and logos visible on it are the property of
				Nordic Content Distribution Ltd. or licensed materials, and may not be stored, shared,
				distributed, or displayed privately and/or publicly without the explicit permission of the
				aforementioned parties.
				<br />
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>
					Delivery Terms and Watching Videos and Live Streams{" "}
				</div>
				<br />
				Video and live broadcasts can be accessed on Kotikino using various end devices. Tracking
				and payment for broadcasts are carried out through the website.
				<br />
				<br />
				Contact Information:
				<br />
				<br />
				Nordic Content Distribution Ltd
				<br />
				Vanha talvitie 11 A
				<br />
				00520 Helsinki
				<br />
				Phone: 010 341 1000
				<br />
				Email <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a>
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Registration and Service Package </div>
				<br />
				The customer registers with the Kotikino system and creates a personal profile (email and
				password). The products to be ordered are selected during the purchase process. By placing
				an order, you agree to these delivery terms and the prices of the products. If an email
				address is provided at the time of ordering, an order confirmation will be sent by email.
				The order confirmation will indicate the ordered products and their prices. By registering
				for the Service, you commit to: (i) providing true, error-free, up-to-date, and complete
				information about yourself as required by the Service's registration form ('Registration
				Information'); and (ii) maintaining and promptly updating the Registration Information to
				ensure its accuracy, error-free nature, currency, and completeness. If you provide
				inaccurate, incomplete, outdated, or false information, or if Nordic Content Distribution
				Ltd has a reasonable reason to believe that you have done so, Nordic Content Distribution
				Ltd may terminate your user account or temporarily disable it. You are solely responsible
				for all activities related to your user account. You should not share your username or
				password with anyone. You agree to immediately inform Nordic Content Distribution Ltd of any
				unauthorized use of your user account or password and any other similar security breach.
				<br />
				<br />
				When you register for the Service, you can choose one or more Service packages, the content
				of which is determined by the description of the respective Service package. By choosing one
				or more Service packages, you commit to paying the total price until you change your
				selection. Nordic Content Distribution Ltd has the right, but not more than once every 12
				months, to increase prices according to the consumer price index.
				<br />
				<br />
				The Service includes the delivery of digital content and starts immediately upon purchasing
				your chosen Service package(s). You acknowledge that you do not have the right to cancel at
				this point, and payments made will not be refunded unless expressly stated otherwise.
				However, you can always terminate your subscription as specified in the 'Cancellation and
				Termination of Order' section below. If, for any reason, you do not receive an order
				confirmation, the order is likely unsuccessful, or there may be an error in the provided
				email address. In such a situation, we encourage you to contact our customer service
				promptly.
				<br />
				<br />
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Formation of Agreement </div>
				<br />
				The agreement is formed when the customer logs into the system and pays for the selected
				package. The transaction is made between the online store customer and Nordic Content
				Distribution Ltd.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Payment </div>
				<br />
				Stripe (Stripe, Inc.), which is one of the world's most used payment service providers, acts
				as the online store's payment intermediary. Stripe forwards the payments to the online
				retailer. Payment is safe, because all information about the payment transaction is
				transmitted using an encrypted connection so that no outside party can see the payment
				transaction information.
				<br />
				<br />
				The transaction is created between the customer of the online store and the online store.
				The online store is responsible for all obligations related to the store.
				<br />
				<br />
				Read more about Stripe: <a href={`hhttps://stripe.com/en-fi`}>hhttps://stripe.com/en-fi</a>
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Payment Methods </div>
				<br />
				Through the Stripe service, you can pay with payment cards (credit/debit) or with the
				MobilePay mobile application. The following card options are available: Visa, MasterCard,
				American Express, Diners Club, Discover and JCB cards. Due to content rights, we currently
				only allow payments with Finnish cards.
				<br />
				<br />
				You can pay with your MobilePay wallet if you have enabled payment in online stores in the
				application settings. Payments made with the MobilePay wallet are charged from the payment
				card attached to the wallet. If charging the payment from the payment card fails, paying
				with the MobilePay wallet is not possible in the online store.
				<br />
				<br />
				<img
					className={classes.tosImage}
					src={getImageByKey("paymentMethodVisma")}
					alt="VismaPay"
				></img>
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Promotional Codes and Vouchers </div>
				<br />
				You can use a voucher as a means of payment in the service. A voucher can be used for all
				regular products and orders. The voucher cannot be used to purchase any additional paid
				services. Promotional codes entitle you to the campaign-specific discount on certain
				products. The promotional code does not provide a discount for group orders or additional
				paid services. The promotional code cannot be used if the customer already has separately
				agreed-upon discount terms.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Prices </div>
				<br />
				Product prices include VAT. <br />
				<br />
				The product is sold at the price indicated on the online store page at the time of ordering.
				Nordic Content Distribution Ltd reserves the right not to sell the product at a price that,
				for example due to technical or human reasons, has been updated in the online store
				essentially incorrectly. In such a case, general contract law principles apply. In these
				cases, Nordic Content Distribution Ltd is in contact with the customer and cancellation of
				the transaction is possible.
				<br />
				<br />
				The prices in the online store are promotional prices for individual product packages. The
				price of group orders (e.g. vouchers) can be agreed on a case-by-case basis.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Period of use </div>
				<br />
				The Kotikino service package purchased by the customer can be used by the customer
				immediately when the online store has received confirmation from the payment system that the
				payment has been accepted. The use and validity period of larger order quantities (e.g.
				vouchers) will be agreed upon with our customer service upon acceptance of the offer.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Paying invoices for larger orders</div>
				<br />
				For larger orders (e.g., vouchers), the invoice is sent electronically/via email to the
				customer's provided address. The due date is 14 days from the invoice date. If the invoice
				is not paid by the due date, the receivable will be transferred for collection. Requests for
				payment deadline extensions and other inquiries are handled through the contact information
				provided in the reminder invoice.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Order cancellation and termination </div>
				<br />
				Consumer customers have the right to cancel their order in whole or in part before the
				commencement of service (vouchers). The customer must notify the cancellation in writing by
				sending an email to <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a> If a consumer
				customer cancels the order within the 14-day cancellation period, the cancellation must be
				made before the commencement of the service. Include your name, contact information, and
				bank account details for possible refunds with the return.
				<br />
				<br />
				Individual rental video purchases are non-refundable. Services provided outside our
				selection at the customer's request are non-refundable.
				<br />
				<br />
				For ongoing subscription customers, termination can be done by logging into the Kotikino
				service, going to their customer profile, and selecting order cancellation.
				<br />
				<br />
				For product returns and cancellations by business customers, we adhere to commercial
				legislation and good business practices. Returns and cancellations must be agreed upon on a
				case-by-case basis with Nordic Content Distribution Ltd. Nordic Content Distribution Ltd
				does not accept returns or cancellations made after the cancellation period without prior
				cancellation notice.
				<br />
				<br />
				Order cancellations and complaints should be reported to{" "}
				<a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi.</a>
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Availability, Changes to the Service</div>
				<br />
				The service is available through networks and devices that Nordic Content Distribution Ltd
				does not control and may be the responsibility of third parties, such as your Internet
				service provider, mobile network operator, or others. Therefore, you acknowledge that Nordic
				Content Distribution Ltd makes reasonable efforts to provide the Service to you and is not
				responsible for disruptions in the availability of the Service.
				<br />
				<br />
				Nordic Content Distribution Ltd may, at any time without prior notice, modify, exchange,
				withdraw, or otherwise change the content of any service package offered by Nordic Content
				Distribution Ltd, for example, to comply with applicable laws and/or the requirements of our
				licensors.
				<br />
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Content Availability </div>
				<br />
				You acknowledge and accept that you have the right to access and view movies, TV series,
				and/or other materials ( "Content") available to you on the Service only during the time you
				are registered on the Service and have purchased one or more Service packages.
				<br />
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Rights to Content </div>
				<br />
				You agree that Nordic Content Distribution Ltd and its licensors own all rights to the
				Service and Content, as well as other materials in the Service. Unless expressly stated
				otherwise in these Terms of Use, you do not have the right to: (i) use, distribute,
				transfer, reproduce, modify, create derivative works, or publicly perform the Content; (ii)
				embed or use any embedding technology with the Content; (iii) decompile, reverse engineer,
				or otherwise attempt to derive the source code or functionality of the software related to
				the Service, or (iv) use the Service with the aim of developing, marketing, selling, or
				distributing any products or services that compete with or contain features substantially
				similar to those offered by Nordic Content Distribution Ltd's services or products. Nordic
				Content Distribution Ltd reserves all rights not expressly granted to you.
				<br />
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Rules of Use </div>
				<br />
				Regarding the use of the Service, you are not entitled to: <br />
				<br />
				<div className={classes.tosMargin}>
					A.In connection with any support service provided by Nordic Content Distribution Ltd or
					otherwise, upload, post, transmit, or otherwise make available material that: (i) is
					unlawful, harmful, threatening, defamatory, disruptive, offensive, indecent, invasive of
					privacy, or otherwise objectionable; (ii) making available is unlawful or otherwise
					violates an agreement (such as confidential information or trade secrets that have come to
					your knowledge in an employment relationship or other information covered by a
					confidentiality agreement); (iii) infringes on any party's patent, trademark, trade
					secret, copyright, or other rights; (iv) contains unwanted or prohibited advertising,
					marketing materials, spam, chain letters, pyramid schemes, or commercial electronic
					messages; or (v) contains viruses or any other code, files, or programs designed to
					disrupt, destroy, or limit the functionality of any other software or hardware;
					<br />
					<br />
					B. Act in a way that negatively affects others' ability to use the Service;
					<br />
					<br />
					C. Act in a way that imposes an unreasonable or disproportionate burden on the Service or
					its infrastructure;
					<br />
					<br />
					D. Disrupt the Service or servers or networks connected to the Service or fail to comply
					with any requirements, procedures, practices, or rules related to the Service;
					<br />
					<br />
					E. Use search robots, robots, bots, or other similar means to use the Service or to
					download, copy, or archive any part of the Service;
					<br />
					<br />
					F.Sell, distribute, transfer, exchange, lend, or exploit for any commercial purpose any
					part of the Service, including but not limited to your user account and password or
					Content; or
					<br />
					<br />
					G. Violate any national or international law or regulation.
				</div>
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Suggestions </div>
				<br />
				If you decide to provide Nordic Content Distribution Ltd with any suggestions, comments,
				ideas, improvements, or other feedback related to the Service ("Suggestions"), Nordic
				Content Distribution Ltd has the right to use, disclose, reproduce, create, modify, license,
				transfer, or otherwise exploit and distribute Suggestions in any manner, without reference
				to you and without compensation to you.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>
					Company Responsibility and Limitations of Liability
				</div>
				<br />
				Nordic Content Distribution Ltd is not responsible for issues beyond the company's control
				(e.g., interruptions in Internet connectivity due to an individual consumer's Internet
				service provider or hardware compatibility issues).
				<br />
				<br />
				Nordic Content Distribution Ltd reserves the right to change delivery terms, prices, and
				schedules. Nordic Content Distribution Ltd also reserves the right to modify its delivery
				and contract terms without notice.
				<br />
				<br />
				Nordic Content Distribution Ltd operates in accordance with good business practices and
				complies with Finnish law in its operations. Disputes between the customer and Nordic
				Content Distribution Ltd are primarily resolved through negotiation. If an agreement cannot
				be reached, disputes will be settled in the Helsinki District Court.
				<br />
				<br />
				For all inquiries and feedback related to operations, customers are encouraged to contact
				customer service.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Force majeure</div>
				<br />
				Nordic Content Distribution Ltd is not liable for the non-functionality, delay, or
				prevention of service if the cause is beyond the control of Nordic Content Distribution Ltd.
				Such causes may include disruptions in the delivery of goods, adverse weather conditions,
				natural disasters, war, accidents, epidemics, import or export bans, government decisions,
				disruptions or prevention in the distribution of traffic or energy, labor strikes, or any
				similar event preventing or impeding the operations of Nordic Content Distribution Ltd or
				the previous sales channel.
			</div>
			<div className={classes.item} id="guidesDesktop">
				<div className={`${classes.tosTitle} font-400`}>
					Using on Computers and through the Browser Interface
				</div>
				<br />
				Kotikino can be accessed through the latest versions of all major browsers, including Google
				Chrome, Mozilla Firefox, Microsoft Edge, Opera, and Safari. There are differences between
				browsers and devices, and we cannot test all of them or provide support for other browsers
				or significant older versions of browsers. Nordic Content Distribution Ltd does not take
				responsibility if the Kotikino service does not work across all browsers and devices. If you
				encounter an issue using the Kotikino service through your browser, try updating your
				browser or use another browser or device to access the service.
			</div>
			<div className={classes.item} id="guidesMobile">
				<div className={`${classes.tosTitle} font-400`}>Using Through Mobile Applications</div>
				<br />
				Kotikino supports mobile and tablet devices. To use it, you can download the application
				from Google Play or the Apple Store. Mobile applications require login, and purchases cannot
				be made through them. You can easily make monthly subscriptions or individual purchases on
				the Kotikino website.
				<br />
				<br />
				Download the Android app here:
				<a
					href="https://play.google.com/store/apps/details?id=fi.kotikino.mobile"
					aria-label="play-store link to kotikino mobile app"
					target="_blank"
					rel="noreferrer"
				>
					{t("shared.clickHere")}
				</a>{" "}
				<br />
				Download the Apple iOS app here:{" "}
				<a
					href="https://apps.apple.com/fi/app/kotikino/id6471352620"
					aria-label="app-store link to kotikino mobile app"
					target="_blank"
					rel="noreferrer"
				>
					{t("shared.clickHere")}
				</a>
			</div>
			<div className={classes.item} id="guidesChromecast">
				<div className={`${classes.tosTitle} font-400`}>Watching with Chromecast </div>
				<br />
				The video player used by Kotikino also supports Chromecast/Googlecast functionality on most
				devices. However, there are differences between devices, and currently, we cannot test all
				of them. Nordic Content Distribution Ltd is not responsible if the Kotikino service does not
				work with all Chromecast/Googlecast devices. However, we gladly welcome feedback if the
				service does not work in a particular environment, and we strive to add support. If you
				provide feedback, please include the brand, model, and software versions of your device, as
				well as Chromecast/Googlecast versions.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Customer Register and Privacy Policy </div>
				<br />
				The use of the service requires the customer to log in to the Kotikino system. At that time,
				the customer's email and an optionally chosen password are stored in the background system.
				<br />
				<br />
				Customer information is confidential and is not disclosed to parties outside Nordic Content
				Distribution Ltd, except for possible payment service providers or related inquiries. Email
				notifications and advertising may be sent to registered customers regarding products in the
				service or upcoming products.
				<br />
				<br />
				In connection with voucher sales, we store, in addition to customer contact information, the
				personal identification number of customers. This information may be used, if necessary, for
				routine creditworthiness verification and possible debt collection actions. If authorities
				suspect a customer, for example, due to suspected criminal activity, customer and personal
				information may be disclosed to the police or other authorities in these cases.
				<br />
				<br />
				In our service, cookies may be used to store session data for the site. Session data may
				include, among other things, login information and the content of the selected service.
				Personal information is not stored in cookie files.
			</div>
			<div className={classes.item}>
				<div className={`${classes.tosTitle} font-400`}>Register Description </div>
				<br />
				Contact Information:
				<br />
				<br />
				Nordic Content Distribution Oy
				<br />
				<br />
				Vanha talvitie 11 A
				<br />
				00520 Helsinki
				<br />
				Phone: 010 341 1000
				<br />
				Email: <a href="mailto:tuki@kotikino.fi"> tuki@kotikino.fi</a>
				<br />
				Business ID: 2990282-5
			</div>
		</div>
	);
};

export default TOSEn;
