import React, { useEffect, useState } from "react";

import settings from "../configs/config_settings.json";

// THIRD PARTY IMPORT

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useForm } from "../hooks/form-hook";
import { ROUTES } from "../constants/route";
import { registerUser } from "../scripts/dataHandlers";
import { createToken } from "../scripts/utils";
import { reactQuerygetSubCategories } from "../scripts/dataHandlerReactquery.js";

import CustomToast from "../components/Common/CustomToast/CustomToast";
import SignupForm from "../components/Form/SignupForm";
import useLoginModal from "../hooks/useLoginModal";
import { useQuery } from "react-query";

const Signup = () => {
	const { t } = useTranslation();

	const onClose = useLoginModal((state) => state.onClose);
	const isOpen = useLoginModal((state) => state.isOpen);

	const { organizationId, key } = settings.organization;
	const { language } = settings;
	let user = {};

	const [loading, setLoading] = useState(false);
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(null);

	const history = useHistory();

	const fetchBannerImage = () => {
		const token = createToken(
			organizationId,
			settings.components.frontPagePromo_01.groupItemId,
			key
		);

		return reactQuerygetSubCategories(
			organizationId,
			token,
			settings.components.frontPagePromo_01.groupItemId,
			language,
			user
		);
	};

	const [state, InputHandler] = useForm(
		{
			EMAIL: {
				value: "",
				isValid: false,
			},
			PASSWORD: {
				value: "",
				isValid: false,
			},
			PASSWORD_CONFIRM: {
				value: "",
				isValid: false,
			},

			FIRSTNAME: {
				value: "",
				isValid: false,
			},
			LASTNAME: {
				value: "",
				isValid: false,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);

	useEffect(() => {
		if (isOpen) {
			onClose();
		}
	}, [isOpen, onClose]); // we close login modal if open

	// we first need to fetch the bacground banner first

	const {
		data: bannerData,
		isLoading: isBannerLoading,
		isSuccess: isBannerSuccess,
	} = useQuery(
		`${settings.components.frontPagePromo_01.groupItemId}getPromoBigBanner`,
		fetchBannerImage,
		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
		}
	);
	useEffect(() => {
		if (!isBannerLoading && isBannerSuccess) {
			setBackgroundImage(
				bannerData?.data?.data?.[0]?.bannerImageSmall ||
					bannerData?.data?.data?.[0]?.bannerImageMedium ||
					bannerData?.data?.data?.[0]?.bannerImageLarge
			);
		}
	}, [bannerData?.data?.data, isBannerLoading, isBannerSuccess]);

	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		console.log(state);
		try {
			const res = await registerUser(organizationId, state.inputs);
			console.log(res);
			if (res?.data?.status === "ok") {
				CustomToast(t("pages.signup.accountCreated"));
				history.push(ROUTES.LOGIN);
			} else {
				console.log(res?.data?.message);
				CustomToast(res?.data?.message || t("shared.errorGeneric"), "error");
			}
			setLoading(false);
		} catch (error) {
			CustomToast(t("shared.errorGeneric"), "error");
			setLoading(false);
		}
	};

	if (isBannerLoading) {
		return null;
	}
	return (
		<SignupForm
			formSubmitHandler={formSubmitHandler}
			state={state}
			InputHandler={InputHandler}
			loading={loading}
			isTermsAccepted={isTermsAccepted}
			setIsTermsAccepted={setIsTermsAccepted}
			backgroundImage={backgroundImage}
		/>
	);
};

export default Signup;
