import React from "react";
import classes from "../../../components/Subscription/Subscription.module.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SubscriptionItem = () => {
  return (
    <SkeletonTheme>
      <p>
        <Skeleton width={"100%"} height={"115px"} style={{}} />
      </p>
      <p>
        <Skeleton width={"50%"} height={"15px"} />
      </p>
      <p>
        <Skeleton width={"100%"} height={"30px"} />
      </p>
    </SkeletonTheme>
  );
};

const SubscriptionPlaceholder = () => {
  return (
    <div
      className="grid-container"
      style={{
        width: "100%",
      }}
    >
      {[...Array(25).keys()].map((el, i) => (
        <div
          className={classes.container}
          key={el.toString()}
          style={{
            border: "none",
            boxShadow: "none",
          }}
        >
          <SubscriptionItem />
        </div>
      ))}
    </div>
  );
};

export default SubscriptionPlaceholder;
