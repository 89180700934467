import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { useState } from "react";
import { useMyContext } from "../../contexts/StateHolder";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useDeleteModal from "../../hooks/useDeleteModal";
import Translations from "../Translations";
import CustomToast from "../Common/CustomToast/CustomToast";

import { authenticateUser, selfDeleteUser } from "../../scripts/dataHandlers";
import { AppConfig } from "../../configs/config";
import { ROUTES } from "../../constants/route";

import "./user-delete-style.css";

export const UserDeleteModal = ({ children }) => {
	const deleteModal = useDeleteModal();
	const { t } = useTranslation();

	const { user } = deleteModal.data;
	const { setUser, setUserLoggedIn } = useMyContext();

	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies, removeCookie] = useCookies("");
	const history = useHistory();

	const onConfirm = async (e) => {
		e.preventDefault();
		console.log("user", user);
		setError(null);
		setIsLoading(true);

		const res = await authenticateUser(
			AppConfig.organization.organizationId,
			user?.email,
			password
		);
		let currentUserToken = res?.data?.user_token;
		if (!currentUserToken && res?.data?.errorCode === "WRONGCREDENTIALS") {
			setError(true);
			setIsLoading(false);

			return;
		}
		if (!currentUserToken) {
			setIsLoading(false);

			return CustomToast(t("shared.errorGeneric"), "error");
		}
		// NOW DELETE THE ACTUAL USER

		const response = await selfDeleteUser(user?.userId, currentUserToken);

		if (response?.status === "ok") {
			CustomToast(t("deleteModal.accountDeletedSuccessfully"));

			setTimeout(() => {
				// LOGOUT

				setUserLoggedIn(false);
				setUser({});

				if (cookies?.user) {
					removeCookie("user", 1, { path: "/", expires: new Date(Date.now()) });
				}
				setIsLoading(false);
				deleteModal?.onClose();

				history.push(ROUTES.HOME);

				window.location.reload();
			}, 1000);
		} else {
			setIsLoading(false);

			return CustomToast(t("shared.errorGeneric"), "error");
		}
	};

	const onClose = () => {
		setError(null);
		setIsLoading(false);
		setPassword("");
		deleteModal?.onClose();
	};
	return (
		<AlertDialog.Root open={deleteModal?.isOpen}>
			<AlertDialog.Trigger asChild>{children}</AlertDialog.Trigger>
			<AlertDialog.Portal>
				<AlertDialog.Overlay className="AlertDialogOverlay" />

				<form onSubmit={onConfirm}>
					<AlertDialog.Content className="AlertDialogContent">
						<AlertDialog.Title className="AlertDialogTitle">
							<Translations text="deleteModal.deleteAccount" /> ?
						</AlertDialog.Title>
						<AlertDialog.Description className="AlertDialogDescription">
							<Translations text="deleteModal.deleteDescription" />
						</AlertDialog.Description>
						<input
							type="password"
							required
							className="inputDelete"
							value={password}
							onChange={(e) => {
								setError(null);
								setPassword(e.target.value);
							}}
							disabled={isLoading}
							style={{
								borderColor: error ? "red" : "unset",
								border: "1px solid",
							}}
						/>
						{error && (
							<AlertDialog.Description className="AlertDialogDescription password-error">
								<Translations text="deleteModal.invalidPassword" />
							</AlertDialog.Description>
						)}

						<div style={{ display: "flex", gap: 10, justifyContent: "flex-end" }}>
							<button className="Button mauve" onClick={onClose} type="button">
								<Translations text="deleteModal.cancel" />
							</button>
							<button className="Button red" disabled={!password || isLoading} type="submit">
								<Translations text="deleteModal.confirmDeleteAccount" />
							</button>
						</div>
					</AlertDialog.Content>
				</form>
			</AlertDialog.Portal>
		</AlertDialog.Root>
	);
};
