import Colors from "../../../Colors";
import Button from "../../../Common/Button/Button";

const SerieSeasonNumber = ({ seasonitems, selectedSeason, setSelectedSeason, pushRoute }) => {
	return (
		<div
			style={{
				display: "flex",
				gap: "10px",
				padding: "40px 0px",
				flexWrap: "wrap",
			}}
		>
			{seasonitems.map((el) => (
				<Button
					key={el?.value}
					style={{
						cursor: "pointer",
						border: `1px solid ${Colors.brand}`,
					}}
					onClick={() => setSelectedSeason(el?.value)}
					inverse={el?.value === selectedSeason ? true : false}
					to={`${pushRoute}/${el.value}`}
					// to={`/series/categories/70283302/256339995/${el.value}`}
				>
					{el.label}
				</Button>
			))}
		</div>
	);
};

export default SerieSeasonNumber;
