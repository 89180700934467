import Colors from "../../Colors";

import classes from "./Dropdown.module.css";

// REACT AND THIRD PARTY IMPORT
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import useWindowDimensions from "../../WindowDimension";
import ScrollAreaRadix from "./ScrollArea";

const Dropdown = ({ setIsMobileNavOpen, items, title, ariaLabelledBy, autoHeight }) => {
	const [open, setOpen] = useState(false);
	const menuRef = useRef();
	const { width } = useWindowDimensions();

	useEffect(() => {
		const handler = (e) => {
			if (!menuRef?.current?.contains(e.target)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handler);

		return () => {
			document.removeEventListener("mousedown", handler);
		};
	});

	return (
		<div
			style={{
				position: "relative",
				display: "flex",
			}}
			ref={menuRef}
			// SHOWING DROPDOWN ON HOVER AND LEAVE
			onMouseEnter={() => width >= 768 && setOpen(true)}
			onMouseLeave={() => width >= 768 && setOpen(false)}
			aria-labelledby={ariaLabelledBy}
		>
			<button
				className={`${classes.menuContainer}   `}
				style={{
					padding: "0",
					background: "none",
				}}
				id={ariaLabelledBy}
				aria-label={ariaLabelledBy}
				onClick={() => setOpen(true)} // THIS IS FOR ACCESSIBLIITY ON TABS PRESSED
			>
				<div
					className={classes.menuTrigger}
					onClick={() => {
						setOpen(!open);
					}}
				>
					<div
						className="nav__item"
						style={{
							color: Colors.black,
							display: "flex",
							gap: "10px",
						}}
					>
						{title}
						<FontAwesomeIcon icon={faChevronDown} className="categories-svg" aria-hidden={false} />
					</div>
				</div>
			</button>
			<div
				className={`${classes["dropdown-menu"]} ${
					open ? classes.activeDropdown : classes.inactiveDropdown
				}`}
			>
				<ScrollAreaRadix
					items={items}
					setOpen={setOpen}
					setIsMobileNavOpen={setIsMobileNavOpen}
					style={{
						height: autoHeight && "100%",
					}}
				/>
			</div>
		</div>
	);
};

export default Dropdown;
