export const AppConfig = {
	BASE_URL: "https://api.icareus.com/api",
	BASE_URL_PLAIN: "https://api.icareus.com",
	BASE_URL_PLAIN_MY: "https://my.icareus.com",
	BASE_URL_MY: "https://api.icareus.com/api/admin",

	// BASE_URL: "https://my.icareus.com/api",
	// BASE_URL_PLAIN: "https://my.icareus.com",
	// BASE_URL_PLAIN_MY: "https://my.icareus.com",
	// BASE_URL_MY: "https://my.icareus.com/api",

	// BASE_URL: "https://suiterc.icareus.com/api",
	// BASE_URL_PLAIN: "https://suiterc.icareus.com",
	// BASE_URL_PLAIN_MY: "https://suiterc.icareus.com",
	// BASE_URL_MY: "https://suiterc.icareus.com/api",

	BASE_URL_SUITERC: "https://suiterc.icareus.com/api",

	BASE_URL_CDN: "https://dvcf59enpgt5y.cloudfront.net",
	BASE_URL_CDN_SUITE_CACHE: "https://d15n7gkgywbuz.cloudfront.net",
	organization: {
		name: "Kotikino",
		organizationId: "70283302",
		key: "tALYp77Jvr",
		companyId: 10154,
		"GROUPID_IS_ALWAYS_ORGID+1": true,
		groupId: 70283303,
		channelServiceId: "",
		googleAnalytics: "",
		radiantPlayerLicense: "",
	},
};
