import SerieItem from "../../SerieItem/SerieItem";
import SerieSkeletonPlaceholder from "../../../Common/SketetonPlaceholder/SerieSkeletonPlaceholder";
import useLoginModal from "../../../../hooks/useLoginModal";

import settings from "../../../../configs/config_settings.json";

import { createAssetIdToken } from "../../../../scripts/utils";
import { reactQueryfetchEpisodes } from "../../../../scripts/dataHandlerReactquery";
import { useMyContext } from "../../../../contexts/StateHolder";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ROUTES } from "../../../../constants/route";

const SerieEpisodesItem = ({ selectedSeason }) => {
	const { organizationId, key } = settings.organization;
	const { language } = settings;

	const { user, userLoggedIn } = useMyContext();

	const history = useHistory();
	const location = useLocation();
	const loginModal = useLoginModal();

	// eslint-disable-next-line no-unused-vars
	const [_, setCookie] = useCookies("");

	const [selectedEpisodeId, setSelectedEpisodeId] = useState(null); // CHEKC WHICH ID HAS BEEN SLECTED

	const fetchEpisodes = () => {
		// here seriesAsset is any episode of series ->so use serieAsset seriesId to fetch data of main serie

		// example API->
		// https://my.icareus.com/publishing?action=getAssets&version=06&organizationId=70283302&languageId=en_US&groupItemId=223694328&token=0563e974fff6439228279a80cda3469fb5f2d69ae9&series=false&userId=221113101&userToken=eyJ0cyI6MTY3NjE1OTQ4MjM2MSwidXNlcklkIjoyMjExMTMxMDEsIm9yZ2FuaXphdGlvbklkIjo3MDI4MzMwMn0%3D.YWJhOWQ3MWU5YjM1ZjJlNjA2ZmQ3MzdmYTM2MmNhYzI2NzBlMTFhMGI5OTJhYTNjZDY3YmZmNzNmYjgxNGYzMWUyYjIwODFhYmZmNDlmZTk3MDAyOTNkYzFmYjM0MDJlNGY4YmU0OTdjNzFiNTk5NGEyZjBiNWMzOWFkZjdkODU%3D
		const token = createAssetIdToken(organizationId, selectedSeason, null, key);

		const res = reactQueryfetchEpisodes(
			organizationId,
			selectedSeason,
			token,

			language,

			user
		);
		return res;
	};

	const {
		data: allEpisodes,
		isLoading,
		isFetching,
	} = useQuery([`getEpisodes${selectedSeason}`, selectedSeason, userLoggedIn], fetchEpisodes, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,

		// when selectedSeason changes this will refetch the data
		enabled: selectedSeason * 1 > 0,
		select: (res) => res?.data?.assets?.sort((a, b) => a.episodeNumber - b.episodeNumber),
	});

	// show login modal when user

	const checkUserAccessForAsset = useCallback(
		(item) => {
			if (item?.accessInfo?.access === 1) {
				// If user has access to product, push to playVideo route
				history.push(`${ROUTES.VIDEO}/${organizationId}/${item?.id}`);
			} else if (item?.accessInfo?.products) {
				if (item?.accessInfo?.products?.length === 0) {
					// If there's no products (doesnt require payment) for asset, push to playVideo route
					history.push(`${ROUTES.VIDEO}/${organizationId}/${item?.id}`);
				} else {
					// If there's product available, but user doesnt have access, push to packages route
					setCookie(
						"packageReturn",
						{ returnPath: location.pathname },
						{ path: "/", secure: true, sameSite: "none" }
					);

					history.push(`${ROUTES.PACKAGES}/asset/${item?.id}`);
				}
			}
		},
		[history, location?.pathname, setCookie, organizationId]
	);

	const clickAction = (el) => {
		if (!user?.loggedIn) {
			// we need show login modal if not logged in
			setSelectedEpisodeId(el?.id);
			loginModal.onOpen();

			return;
		}
		checkUserAccessForAsset(el);
	};

	useEffect(() => {
		if (!isFetching && loginModal?.isOpen && userLoggedIn) {
			// IF DATA HAS BEEN FETCHED AGAIN AND WHEN USER IS LOGGED WE CHECK FOR ACCESS

			checkUserAccessForAsset(allEpisodes?.find((el) => el?.id === selectedEpisodeId));
			setSelectedEpisodeId(null);
			loginModal?.onClose();
		}
	}, [
		allEpisodes,
		checkUserAccessForAsset,
		isFetching,
		loginModal,
		selectedEpisodeId,
		userLoggedIn,
	]);
	return (
		<>
			{!isLoading || !isFetching ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						margin: "20px 0",
					}}
				>
					{allEpisodes?.map((el) => (
						<SerieItem asset={el} id={el?.id} key={el?.id} onClick={() => clickAction(el)} />
					))}
				</div>
			) : (
				<>{selectedSeason ? <SerieSkeletonPlaceholder /> : null}</>
			)}
		</>
	);
};

export default SerieEpisodesItem;
