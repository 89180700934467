import { faBagShopping, faBarsStaggered, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Translations from "../../Translations";
import Colors from "../../Colors";
import Header from "../../Common/Header/Header";
import useWindowDimensions from "../../WindowDimension";
import { ROUTES } from "../../../constants/route";

const SideBar = () => {
	const { collapseSidebar, toggleSidebar, collapsed } = useProSidebar();
	const [selected, setSelected] = useState("/user/profile");

	const { t } = useTranslation();

	const { width } = useWindowDimensions();

	const ITEMS = [
		{
			icon: faUser,
			to: ROUTES.PROFILE,
			text: "navigation.menuList.profile",
		},
		{
			icon: faLock,
			to: ROUTES.CHANGE_PASSWORD,
			text: "navigation.menuList.changePassword",
		},
		{
			icon: faBagShopping,
			to: ROUTES.SUBSCRIPTION,
			text: "profileMenu.subscriptions",
		},
	];

	const history = useHistory();

	useEffect(() => {
		if (history?.location?.pathname) {
			setSelected(history?.location?.pathname);
		}
	}, [history?.location?.pathname]);

	return (
		<div>
			<Sidebar defaultCollapsed={width >= 767 ? false : true}>
				<Menu
					iconShape="square"
					rootStyles={{
						padding: 0,
					}}
				>
					<MenuItem
						onClick={() => {
							toggleSidebar();
							collapseSidebar();
						}}
						icon={collapsed ? <FontAwesomeIcon icon={faBarsStaggered} /> : undefined}
						style={{
							margin: "10px 0 20px 0",
							color: Colors.black,
						}}
					>
						{/* If not collapsed */}
						{!collapsed && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									marginLeft: "15px",
								}}
							>
								<Header
									title={t("navigation.menuList.profile")}
									viewAll={false}
									style={{
										padding: 0,
									}}
								/>
								<FontAwesomeIcon icon={faBarsStaggered} />
							</div>
						)}
					</MenuItem>

					<div>
						{ITEMS.map((el) => (
							<MenuItem
								key={el?.text}
								icon={
									<FontAwesomeIcon
										icon={el?.icon}
										aria-hidden={false}
										aria-label={<Translations text={el?.text} />}
									/>
								}
								className="nav__item nav__item_sidebar"
								component={<NavLink to={el?.to} />}
								active={selected === el?.to}
								onClick={() => setSelected(el?.to)}
								style={{
									justifyContent: "space-between !important",
								}}
							>
								{!collapsed && <Translations text={el?.text} />}
							</MenuItem>
						))}
					</div>
				</Menu>
			</Sidebar>
		</div>
	);
};

export default SideBar;
