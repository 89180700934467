import { ROUTES } from "../constants/route";

export const getAssetsViewAllRoute = (
  category,
  organizationId,
  assetProperty
) => {
  // let property;
  // if (assetProperty === "SVOD") {
  //   property = "katso";
  // } else {
  //   property = "vuokraamo";
  // }

  // WE DONT CARE ABOUT PROPERY FOR NOW
  return `${ROUTES.CATEGORIES}/${category?.title}/${organizationId}/${category.id}`;
};
