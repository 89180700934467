import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { ROUTES } from "../constants/route";
import { useForm } from "../hooks/form-hook";
import { useMyContextFunctions } from "../contexts/ContextFunctions";
import { createToken } from "../scripts/utils";
import { reactQuerygetSubCategories } from "../scripts/dataHandlerReactquery";

import LoginForm from "../components/Form/LoginForm";
import CustomToast from "../components/Common/CustomToast/CustomToast";

import settings from "../configs/config_settings.json";

const Login = (props) => {
	const { t } = useTranslation();
	const { authProcess } = useMyContextFunctions();
	const { organizationId, key } = settings.organization;
	const { language } = settings;
	let user = {};

	const [loading, setLoading] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState(null);

	const fetchBannerImage = () => {
		const token = createToken(
			organizationId,
			settings.components.frontPagePromo_01.groupItemId,
			key
		);

		return reactQuerygetSubCategories(
			organizationId,
			token,
			settings.components.frontPagePromo_01.groupItemId,
			language,
			user
		);
	};

	// we first need to fetch the bacground banner first

	const {
		data: bannerData,
		isLoading: isBannerLoading,
		isSuccess: isBannerSuccess,
	} = useQuery(
		`${settings.components.frontPagePromo_01.groupItemId}getPromoBigBanner`,
		fetchBannerImage,
		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
		}
	);
	useEffect(() => {
		if (!isBannerLoading && isBannerSuccess) {
			setBackgroundImage(
				bannerData?.data?.data?.[0]?.bannerImageSmall ||
					bannerData?.data?.data?.[0]?.bannerImageMedium ||
					bannerData?.data?.data?.[0]?.bannerImageLarge
			);
		}
	}, [bannerData?.data?.data, isBannerLoading, isBannerSuccess]);

	const [state, InputHandler] = useForm(
		{
			EMAIL: {
				value: "",
				isValid: false,
			},
			PASSWORD: {
				value: "",
				isValid: false,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);

	const history = useHistory();

	const formSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			// Do the signUp/login process
			const authResponse = await authProcess(state.inputs.EMAIL.value, state.inputs.PASSWORD.value);
			setLoading(false);

			console.log("authResponse", authResponse);

			if (authResponse.data.status === "error") {
				if (authResponse?.data?.message) {
					return CustomToast(authResponse?.data?.message, "error");
				} else {
					return CustomToast(t("shared.errorGeneric"), "error");
				}
			} else {
				// if cutom callback that component will handle routes by themselves
				if (props.customCallback) {
					// we have to return this value here

					return props.customCallback(authResponse.data);
				} else {
					history.push(`${ROUTES.HOME}`);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	if (isBannerLoading) {
		return null;
	}

	return (
		<LoginForm
			state={state}
			InputHandler={InputHandler}
			formSubmitHandler={formSubmitHandler}
			loading={loading}
			hideImage={props.hideImage}
			extraClassName={props.extraClassName}
			backgroundImage={backgroundImage}
		/>
	);
};

export default Login;
