const Colors = {
  brand: "#f39939",
  background: "whitesmoke",
  black: "black",
  white: "white",
  red: "#af1d1f",
  error: "#af1d1f",
  primary: "#202124 ",
  disabled: "grey",
};

export default Colors;
