import classes from "./VodVideoPlayer.module.css";

// THIRD PARTY

import { useCookies } from "react-cookie";

// NEXT AND REACT IMPORT
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "../../WindowDimension";
import { usePlayerSetting } from "../../../hooks/player-setting-hook";
import { useMyContext } from "../../../contexts/StateHolder";
import Translations from "../../Translations";
import { ROUTES } from "../../../constants/route";
import { AppConfig } from "../../../configs/config";

const VodVideoPlayer = ({
	asset,
	playerConfig,
	assetToken,
	organizationId,
	groupId,
	allCampaigns,
	host,
	useMY,
}) => {
	const history = useHistory();
	const { t } = useTranslation();

	const dimension = useWindowDimensions();

	const [cookies, setCookie, removeCookie] = useCookies(["vodCampaign"]);

	const rmpPlayer = useRef();

	const { user, userLoggedIn, languageCode } = useMyContext();

	const [loading, setLoading] = useState(true);
	const [radiantMediaPlayerObject, setRadiantMediaPlayerObject] = useState(null);

	const [campaingId, setCampaingId] = useState(null);

	const [state, getPlayerSetting] = usePlayerSetting();

	console.log("languageCode", languageCode);

	useEffect(() => {
		if (playerConfig && organizationId && groupId && rmpPlayer.current && userLoggedIn) {
			setLoading(true);
			getPlayerSetting(playerConfig, host);

			setLoading(false);
			// checking for campaignId
			if (allCampaigns?.campaigns?.length > 0) {
				let unusedCampaigns = allCampaigns?.campaigns.filter(
					(el) => !cookies?.vodCampaign?.includes(el?.campaignId)
				);
				if (unusedCampaigns?.length > 0) {
					setCampaingId(unusedCampaigns[0].campaignId);
					let curCookie = cookies?.vodCampaign;
					if (!curCookie) curCookie = [];
					setCookie("vodCampaign", [...curCookie, unusedCampaigns[0].campaignId], {
						path: "/",
						Secure: true,
						SameSite: "none",
					});
				} else {
					// this means all campaign have already been shown
					removeCookie("vodCampaign", {
						path: "/",
						Secure: true,
						SameSite: "none",
					});
					// show the first campaign again
					setCampaingId(allCampaigns?.campaigns[0].campaignId);
					setCookie("vodCampaign", [allCampaigns?.campaigns[0].campaignId], {
						path: "/",
						Secure: true,
						SameSite: "none",
					});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerConfig, organizationId, groupId, allCampaigns?.campaigns, userLoggedIn]);

	useEffect(() => {
		let radiantscript, vodjs, advertjs;

		if (!loading) {
			const createScript = () => {
				radiantscript = document.createElement("script");
				vodjs = document.createElement("script");
				advertjs = document.createElement("script");

				vodjs.id = "vodjs";

				radiantscript.src = "https://cdn.radiantmediatechs.com/rmp/8.4.0/js/rmp.min.js";

				vodjs.src = "https://staging1.icareus.com/lib/js/players/v6/players/vod.js";
				advertjs.src = "https://staging1.icareus.com/lib/js/players/v6/players/adverts.js"; // adverts1 is for beta

				vodjs.async = true;
				advertjs.async = true;
				radiantscript.async = true;

				document.body.appendChild(radiantscript);
				document.body.appendChild(vodjs);
				document.body.appendChild(advertjs);
			};

			createScript();

			let customErrorMessage = <Translations text="players.customErrorMessage" />;
			let noSupportMessage = <Translations text="players.noSupportMessage" />;
			let noSupportDownload = <Translations text="players.noSupportDownload" />;
			let noSupportInstallChrome = <Translations text="players.noSupportInstallChrome" />;
			let noSupportInstallChromeLink = <Translations text="players.noSupportInstallChromeLink" />;
			const labels = {
				error: {
					customErrorMessage,
					noSupportMessage,
					noSupportDownload,
					noSupportInstallChrome,
					noSupportInstallChromeLink,
				},
			};

			window._icareus = {};

			window._icareus.companyId = "10154";

			window._icareus.groupId = groupId;

			window._icareus.organizationId = organizationId;

			window._icareus.itemId = asset?.id;
			window._icareus.userId = user?.userId;

			window._icareus.host = useMY ? AppConfig.BASE_URL_PLAIN_MY : AppConfig.BASE_URL_PLAIN;
			window._icareus.playerId = "rmpPlayer";
			window._icareus.playerType = "radiant";
			window._icareus.playerSetup = "vodJSCallback";
			window._icareus.playerAutoStart = true;

			// disable/enable analytics cookie
			window._icareus.allowAnalyticsCookies = state.allowAnalyticsCookies;
			// disable/enable analytics

			window._icareus.sendAnalytics = state.sendAnalytics;

			window._icareus.applicationTypeId = 1;
			window._icareus.applicationId = 1;

			window._icareus.token = assetToken;

			window._icareus.initializedByReactApps = true;

			// adverts start ->only show when cookies are allowed
			window._icareus.campaignId = campaingId;
			window._icareus.advertVOD = campaingId ? true : false;
			window._icareus.useAdvertsLibrary = campaingId ? true : false;

			// adverts end

			let settings;
			const backButtonHandler = () => {
				history.goBack();
			};

			// Then we define a custom module - in this case close button
			const customModule = [
				{
					hint: t("videoPlayer.close"), // Then name of the module - will show as hint within player UI
					svg: "https://cdn.radiantmediatechs.com/rmp/svg/close.svg", // Then SVG icon for the module
					callback: function () {
						// Our callback function
						backButtonHandler();
					},
				},
			];

			window.vodJSCallback = function () {
				settings = {
					labels: labels,
					detectViewerLanguage: false,
					fixedLabelsLanguage: languageCode === "en" ? "english" : "finnish",

					licenseKey: state.licenseKey,
					src: window._icareus.sources,
					ccFiles: window._icareus?.rmpSubtitles,

					width: state.width,
					height: state.height,
					// if autoHeightMode is true -<width is set to 100% and height adjusted accordingly

					autoHeightMode: true,
					autoHeightModeRatio: state.autoHeightModeRatio,

					// The 4 player skins ('s1', 's2', 'outstream', 'tv') can easily be colorized using the following player settings.
					skin: state.skin,
					// This setting will colorize the background of the skin. Default to ''.

					skinBackgroundColor: state.skinBackgroundColor,
					skinButtonColor: state.skinButtonColor,
					skinAccentColor: state.skinAccentColor,

					speed: state.speed,
					automaticFullscreenOnLandscape: state.automaticFullscreenOnLandscape,
					sharing: state.sharing,
					autoplay: true,

					// logo start

					logo: state.logo,

					logoPosition: state.logoPosition,
					logoLoc: state.logoLoc,

					logoWatermark: state.logoWatermark,
					// logo ends
					// allowLocalStorage: false,

					adTagUrl: window._icareus.videoVastURL,
					ads: campaingId ? true : false,

					contentMetadata: {
						title: asset?.title || asset?.name || "Kotikino",

						// description: asset?.description?.slice(0, 50) || null,

						poster: [
							state.containerbackgroundImage
								? state.containerbackgroundImage
								: window._icareus.thumbnail,
						],
					},

					customModule: customModule,

					// preload auto will load more video chunks
					preload: "auto",

					asyncElementID: rmpPlayer.current.id,
					ccFontSize: dimension?.width > 1620 ? 3 : dimension?.width > 599 ? 1.75 : 1,
				};

				// when ready event fires we append our custom overlay div element
				// so that this element is appended on top of other player elements
				// console.log("settings", settings);
				window._icareus.playerObject.init({ ...settings });
				setRadiantMediaPlayerObject(window._icareus.playerObject);
			};
			return () => {
				// pausing the rmp
				radiantMediaPlayerObject?.pause();
				// muting the rmp
				radiantMediaPlayerObject?.setMute(true);
				// destroying the rmp
				radiantMediaPlayerObject?.destroy();
				document.body.removeChild(radiantscript);
				document.body.removeChild(vodjs);
				document.body.removeChild(advertjs);
				setRadiantMediaPlayerObject(null);
			};
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, languageCode]);

	useEffect(() => {
		if (!userLoggedIn) {
			return history.replace(ROUTES.LOGIN);
		}
	}, [userLoggedIn, history]);

	console.log("loading,userLoggedIn", userLoggedIn);

	return (
		<div
			style={{
				backgroundImage: `url(${state.playerbackgroundImage})`,
				flex: 1,
			}}
			className={classes.VodVideoPlayerContainer}
			ref={rmpPlayer}
			id="rmpPlayer"
		></div>
	);
};

export default VodVideoPlayer;
