import React from "react";
import SideBar from "./SideBar";

const SidebarContainer = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <SideBar />

      {children}
    </div>
  );
};

export default SidebarContainer;
