import { useQuery } from "react-query";

import { useMyContext } from "../../../contexts/StateHolder";
import { createToken } from "../../../scripts/utils";
import { reactQueryGetPromo } from "../../../scripts/dataHandlerReactquery";

import PromoItem from "../PromoItem";
import settings from "../../../configs/config_settings.json";

export const Promos = ({ settings: { groupItemId, routes, assetProperty } }) => {
	const { organizationId, key } = settings.organization;

	const { user, language } = useMyContext();

	const fetchPromo = () => {
		const token = createToken(organizationId, groupItemId, key);
		return reactQueryGetPromo(organizationId, groupItemId, token, language, assetProperty, user);
	};

	const { data, isLoading, isSuccess } = useQuery(`${groupItemId}getPromo`, fetchPromo, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
	});

	if (isLoading || !isSuccess) {
		return null;
	}

	return <PromoItem assets={data?.data?.assets} routes={routes} />;
};
