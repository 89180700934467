import classes from "./AssetItemCategories.module.css";

const AssetItemCategories = ({ asset }) => {
	// Check if asset and asset.folders exist and are arrays

	let allFoldername =
		asset?.folders &&
		Array.isArray(asset.folders) &&
		asset?.folders?.map((el) => el?.name)?.join(", ");
	return <div className={`${classes.container} font-300`}>{allFoldername}</div>;
};

export default AssetItemCategories;
