import { NavLink } from "react-router-dom";
import Translations from "../Translations";

const NavItem = ({ text, href, onClick }) => {
  return onClick ? (
    <button className={`nav__item button-default`} onClick={onClick}>
      <Translations text="navigation.menuList.search" />
    </button>
  ) : (
    <NavLink to={href} className={`nav__item`}>
      {text}
    </NavLink>
  );
};

export default NavItem;
