import { useQuery } from "react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import settings from "../../../configs/config_settings.json";
import { useMyContext } from "../../../contexts/StateHolder";

import { reactQueryfetchCategoryAssets } from "../../../scripts/dataHandlerReactquery";

import AssetsItem from "../AssetsItem/AssetsItem";

import useWindowDimensions from "../../WindowDimension";
import { createToken, manageCarouselResponsiveness } from "../../../scripts/utils";
import Header from "../../Common/Header/Header";
import { getUniqueSerieAndAssets } from "../../../lib/getUniqueSerieAndAssets";
import AssetsCustomCarousel, {
	AssetsCustomCarouselSlickSettings,
} from "../../Common/CustomCarousel/AssetsCustomCarousel";
import { getAssetsViewAllRoute } from "../../../lib/getAssetsViewAllRoute";

const SingleCateoryAssets = ({ category, hidePrice, ...props }) => {
	const windowDimension = useWindowDimensions();

	const { language } = useMyContext();

	const { id, assetProperty } = props.settings;

	const { organizationId, key } = settings.organization;

	const { user } = useMyContext();

	console.log("language", language);

	const fetchAssets = () => {
		const token = createToken(organizationId, category.id, key);

		return reactQueryfetchCategoryAssets(
			organizationId,
			category.id,
			token,
			language,
			assetProperty,
			user
		);
	};

	// need to pass unique key to react use query

	const { data: assets, isLoading } = useQuery(
		[`${id}getAssets${category.id}`, language],
		() => fetchAssets(),

		{
			// by default the cache time is 5 minutes
			cacheTime: 300000,
			staleTime: Infinity,
			select: (res) => {
				return getUniqueSerieAndAssets(res?.data?.assets);
			},
		}
	);

	const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];
	let categoryPushRoute = getAssetsViewAllRoute(category, organizationId, assetProperty);
	let className1 = "";

	const RenderAsset = () => {
		if (assets?.length === 0) return null;
		if (assets) {
			className1 = manageCarouselResponsiveness(
				AssetsCustomCarouselSlickSettings,
				windowDimension,
				assets,
				className1
			);
		}

		return (
			<>
				<div className={`${className1}`}>
					<Header viewAllLink={categoryPushRoute} title={category?.title} />
					<AssetsCustomCarousel>
						{assets?.map((el2, i) => {
							return (
								<div key={i.toString()}>
									<AssetsItem asset={el2} hidePrice={hidePrice} />
								</div>
							);
						})}
					</AssetsCustomCarousel>
				</div>
			</>
		);
	};

	const RenderLoading = () => {
		return (
			<>
				<Header viewAllLink={categoryPushRoute} title={category?.title} viewAll={false} />
				<AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
					{skeletonItem.map((el2) => (
						<SkeletonTheme key={el2}>
							<p>
								<Skeleton
									key={el2}
									width={"100%"}
									height={"325px"}
									style={{
										borderRadius: "10px",
									}}
								/>
							</p>
						</SkeletonTheme>
					))}
				</AssetsCustomCarousel>
			</>
		);
	};
	return !isLoading ? <RenderAsset /> : <RenderLoading />;
};

export default SingleCateoryAssets;
