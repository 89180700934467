import settings from "../../../configs/config_settings.json";

// THIRD PARTY IMPORT
import { useQuery } from "react-query";
import { createAssetIdToken } from "../../../scripts/utils";
import { reactQuerygetTrendingSerie } from "../../../scripts/dataHandlerReactquery";
import Header from "../../Common/Header/Header";
import AssetsCustomCarousel, {
	AssetsCustomCarouselSlickSettings,
} from "../../Common/CustomCarousel/AssetsCustomCarousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AssetsItem from "../../Assets/AssetsItem/AssetsItem";
import { ROUTES } from "../../../constants/route";
import Translations from "../../Translations";

const TrendingSerie = () => {
	const { organizationId, key } = settings.organization;

	const fetchTrendingSerie = () => {
		const token = createAssetIdToken(organizationId, "", null, key);
		return reactQuerygetTrendingSerie(organizationId, token, 30, 10);
	};

	const { data: trendingSerie, isLoading } = useQuery(`trendingSerie`, fetchTrendingSerie, {
		// by default the cache time is 5 minutes
		cacheTime: 300000,
		staleTime: Infinity,
		select: (res) => res?.data?.groupItems?.map((el) => el?.groupItem),
	});
	// console.log(trendingSerie);
	const skeletonItem = [1, 2, 3, 4, 5, 6, 7, 8];

	if (isLoading) {
		return (
			<>
				<Header title={<Translations text="trending.titleSeries" />} viewAll={false} />
				<AssetsCustomCarousel {...AssetsCustomCarouselSlickSettings}>
					{skeletonItem.map((el2) => (
						<SkeletonTheme key={el2}>
							<p>
								<Skeleton
									key={el2}
									width={"100%"}
									height={"325px"}
									style={{
										borderRadius: "10px",
									}}
								/>
							</p>
						</SkeletonTheme>
					))}
				</AssetsCustomCarousel>
			</>
		);
	}

	if (!isLoading && trendingSerie && trendingSerie?.length > 0) {
		return (
			<div>
				<Header
					title={<Translations text="trending.titleSeries" />}
					viewAllLink={`${ROUTES.SERIES}`}
				/>
				<AssetsCustomCarousel>
					{trendingSerie?.map((el) => (
						<AssetsItem
							asset={el}
							key={el?.id}
							pushRoute={`${ROUTES.SERIES_CATEGORIES}/${organizationId}/${el?.id}`}
						/>
					))}
				</AssetsCustomCarousel>
			</div>
		);
	}
};

export default TrendingSerie;
